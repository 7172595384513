/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import moment from 'moment'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import './../../../../../src/_metronic/assets/css/rsuite.css'

import Table from './components/Table'
import {KTCard, KTSVG} from '../../../../_metronic/helpers'
import TableHeader from './components/TableHeader'
import AddModal from './components/AddModal'
import {
  deleteCampaign,
  getCampaigns,
  getCampaignsWithoutTime,
  statusCampaign,
} from '../../../services/campaigns.service'
import {useParams} from 'react-router-dom'
import UpdateModal from './components/UpdateModal'
import offerStatus from '../../../utils/offerStatus'
import {decodeEntities} from '../../../utils/format'
import ReactSelect from 'react-select'

const Campaigns = () => {
  const intl = useIntl()
  let {agency_id} = useParams()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [advertisers, setAdvertisers] = useState([])
  const [agency, setAgency] = useState([])
  const [addModal, setAddModal] = useState(false)
  const [updateData, setUpdateData] = useState(null)
  const [selectedTime, setSelectedTime] = useState({
    label: 'Son 1 Hafta',
    value: {
      part: 1,
      month: 'weeks',
    },
  })

  const changeStatus = async (id, status) => {
    setLoading(true)
    const res = await statusCampaign(agency_id, id, status)
    if (res.success) {
      const index = data.findIndex((x) => x.id === id)
      let temp = [...data]
      temp[index] = res.data
      setData(temp)
    }
    setLoading(false)
  }

  const customColumns = [
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          style={{cursor: 'pointer'}}
          // onClick={sortColumn}
        >
          Kampanya Adı
        </th>
      ),
      accessor: 'name',
      Cell: ({...props}) => <span>{decodeEntities(props.row.original.name)}</span>,
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          style={{cursor: 'pointer'}}
          // onClick={sortColumn}
        >
          Teklifler
        </th>
      ),
      id: 'offers',
      Cell: ({...props}) => {
        let value = props.row.original
        return (
          <div className='d-flex flex-column'>
            {value.offers.map((v, i) => {
              let status = offerStatus(v.id)
              return (
                <div key={i} className={`btn btn-${status.color} btn-sm ${i > 0 && 'mt-1'}`}>
                  {status.messageKey + ' (' + v.count + ')'}
                </div>
              )
            })}
          </div>
        )
      },
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          // onClick={sortColumn}
        >
          Müşteri
        </th>
      ),
      id: 'advertiser.name',
      Cell: ({...props}) => (
        <span>
          {props.row.original.advertiser.superAdvertiser
            ? decodeEntities(props.row.original.advertiser.superAdvertiser.name)
            : decodeEntities(props.row.original.advertiser.name)}
        </span>
      ),
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          // onClick={sortColumn}
        >
          Marka
        </th>
      ),
      id: 'marka',
      Cell: ({...props}) => (
        <span>
          {props.row.original.advertiser.superAdvertiser
            ? decodeEntities(props.row.original.advertiser.name)
            : '-'}
        </span>
      ),
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          // onClick={sortColumn}
        >
          Dönemi
        </th>
      ),
      id: 'donem',
      Cell: ({...props}) => (
        <span>
          {moment(props.row.original.startDate).format('DD.MM.YYYY')}-
          {moment(props.row.original.endDate).format('DD.MM.YYYY')}
        </span>
      ),
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          // onClick={sortColumn}
        >
          Durumu
        </th>
      ),
      id: 'status',
      Cell: ({...props}) =>
        loading ? (
          <div data-kt-indicator='on'>
            <span className='indicator-progress'>
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          </div>
        ) : (
          <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              value=''
              name='notifications'
              defaultChecked={props.row.original.isActive}
              onChange={(e) => {
                changeStatus(props.row.original.id, !props.row.original.isActive)
              }}
            />
          </div>
        ),
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px d-flex justify-content-end'
          // onClick={sortColumn}
        >
          İşlemler
        </th>
      ),
      id: 'actions',
      Cell: ({...props}) => (
        <div className='d-flex justify-content-end flex-shrink-0'>
          <button
            href='#'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => {
              setUpdateData(props.row.original)
            }}
          >
            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
          </button>
          <button
            href='#'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            onClick={() => {
              Swal.fire({
                title: 'Silmek istediğine emin misin?',
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Hayır',
                confirmButtonText: 'Evet!',
                preConfirm: async (login) => {
                  const res = await deleteCampaign(agency_id, props.row.original.id)
                  if (res.success) {
                    Swal.fire('Silindi!', '', 'success')
                    fetchData()
                  } else {
                    Swal.fire('Hata!', res.data.data.errors[0].message)
                  }
                },
                allowOutsideClick: () => !Swal.isLoading(),
              })
            }}
          >
            <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
          </button>
        </div>
      ),
    },
  ]

  async function fetchData() {
    const campaigns = await getCampaigns(
      agency_id,
      selectedTime.value.part,
      selectedTime.value.month
    )
    setData(campaigns.data)
  }

  async function fetchDataWithoutTime() {
    const campaigns = await getCampaignsWithoutTime(agency_id)
    setData(campaigns.data.campaigns)
    setAdvertisers(campaigns.data.advertisers)
    setAgency(campaigns.data.agency)
  }

  useEffect(() => {
    fetchDataWithoutTime()
  }, [])

  useEffect(() => {
    fetchData()
  }, [selectedTime])

  return (
    <>
      <KTCard>
        {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.PERSONA'})}</PageTitle> */}
        <TableHeader showAddModal={setAddModal} title='Kampanyalar' />
        {data && (
          <div className='d-flex flex-row-reverse pe-4'>
            <ReactSelect
              options={[
                {
                  label: 'Son 1 Hafta',
                  value: {
                    part: 1,
                    month: 'weeks',
                  },
                },
                {
                  label: 'Son 1 Ay',
                  value: {
                    part: 1,
                    month: 'months',
                  },
                },
                {
                  label: 'Son 3 Ay',
                  value: {
                    part: 3,
                    month: 'months',
                  },
                },
                {
                  label: 'Son 6 Ay',
                  value: {
                    part: 6,
                    month: 'months',
                  },
                },
                {
                  label: 'Tüm Zamanlar',
                  value: {
                    part: 10,
                    month: 'years',
                  },
                },
              ]}
              styles={{
                control: (base) => {
                  return {
                    ...base,
                    minWidth: 150,
                    maxWidth: 200,
                  }
                },
                valueContainer: (provided, state) => ({
                  ...provided,
                  minWidth: '150px',
                  maxWidth: '200px',
                }),
              }}
              value={selectedTime}
              closeMenuOnSelect={true}
              onChange={(e) => setSelectedTime(e)}
              classNamePrefix='select'
            />
          </div>
        )}
        {data && <Table data={data} columns={customColumns} />}
      </KTCard>
      {addModal && (
        <AddModal showAddModal={setAddModal} advertisers={advertisers} fetchData={fetchData} />
      )}
      {updateData && (
        <UpdateModal
          showUpdateModal={setUpdateData}
          advertisers={advertisers}
          fetchData={fetchData}
          agency={agency}
          data={updateData}
        />
      )}
    </>
  )
}

export {Campaigns}
