import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {KTSVG} from '../../../../../_metronic/helpers'
import Time from '../../../agency/offers/Time/Time'
import {DatePicker} from 'antd'
import {useParams} from 'react-router-dom'
import clsx from 'clsx'
import ReactSelect from 'react-select'
import locale from 'antd/es/date-picker/locale/tr_TR'
import '../../trailers/components/style.css'
import {updateChannelsSchedule} from '../../../../services/publishers.service'
import Swal from 'sweetalert2'
import {useThemeMode} from '../../../../../_metronic/partials'
import {UserContext} from '../../../../contexts/auth/index'

const customStyles = {
  multiValue: (base, state) => {
    return {...base, backgroundColor: '#0e0e15'}
  },
  multiValueLabel: (base, state) => {
    return {...base, fontWeight: 'bold', color: '#92929F', paddingRight: 6}
  },
  option: (base) => {
    return {...base, color: '#92929F', backgroundColor: '#1e1e2d'}
  },
  menu: (base) => {
    return {...base, color: '#92929F', backgroundColor: '#1e1e2d'}
  },
  control: (base) => {
    return {
      ...base,
      color: '#92929F',
      backgroundColor: '#1e1e2d',
      borderColor: '#323248',
      maxHeight: 200,
    }
  },
  valueContainer: (provided, state) => ({
    ...provided,
    maxHeight: '200px',
    overflow: 'scroll',
  }),
}

function UpdateSchedule({showUpdateModal, data, programs, fetchData}) {
  const userDetails = React.useContext(UserContext)
  const [startTime, setStartTime] = useState(data.startHour ?? '12:00')
  const [endTime, setEndTime] = useState(data.endHour ?? '12:00')
  const [selectedProgram, setSelectedProgram] = useState(null)
  const [options, setOptions] = useState([])
  const [datePicker, setDatePicker] = useState(data.startDate)
  const [isReplay, setIsReplay] = useState(data.isReplay)

  useEffect(() => {
    let oArray = []
    programs.forEach((v) => {
      oArray.push({label: v.title, value: v.id})
    })
    setOptions(oArray)
    let findProgram = programs.find((x) => x.id === data.programId)
    setSelectedProgram({label: findProgram.title, value: findProgram.id})
  }, [])

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const disabledDate = (current, start, end) => {
    if (start && end) {
      return current < moment(start).add(-1, 'day') || current >= moment(end)
    } else if (start && !end) {
      return current && current < moment(start)
    } else return false
  }

  const handleSubmit = async () => {
    let a = {
      id: data.id,
      programId: selectedProgram.value,
      recurring: 0,
      isReplay: isReplay ? 1 : 0,
      startDate: moment(datePicker).format('DD-MM-YYYY'),
      endDate: '',
      startTime: startTime,
      endTime: endTime,
      start: moment(datePicker).format('YYYY-MM-DD') + ' ' + startTime,
      end: moment(datePicker).format('YYYY-MM-DD') + ' ' + endTime,
    }

    if (moment(endTime, 'HH:mm').isBefore(moment(startTime, 'HH:mm'))) {
      a.endDate = moment(datePicker).add(1, 'day').format('DD-MM-YYYY')
      a.end = moment(datePicker).add(1, 'day').format('YYYY-MM-DD') + ' ' + endTime
    }

    var form_data = new FormData()

    for (var key in a) {
      form_data.append(key, a[key])
    }

    const res = await updateChannelsSchedule(data.tvStationId, data.id, form_data)
    if (!res.success) {
      Swal.fire('Hata!', res.data.data.errors[0].message ?? 'Daha sonra tekrar deneyiniz.', 'error')
    } else {
      fetchData()
      Swal.fire('Başarılı!', 'Başarıyla güncellendi.', 'success')
      showUpdateModal(false)
    }
  }

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <div className='modal-header'>
              {/* begin::Modal title */}
              <h2 className='fw-bolder'>Yayını Güncelle</h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => showUpdateModal(false)}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
              {/* end::Close */}
            </div>

            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <div
                className='d-flex flex-column scroll-y me-n7 pe-7'
                id='kt_modal_add_user_scroll'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: false, lg: true}'
                data-kt-scroll-max-height='auto'
                data-kt-scroll-dependencies='#kt_modal_add_user_header'
                data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                data-kt-scroll-offset='300px'
              >
                <div className='row'>
                  <div className='col-12 mt-4'>
                    <label className='fw-bold fs-6 mb-2'>Başlangıç - Bitiş</label>
                    <DatePicker
                      locale={locale}
                      className='w-100'
                      disabledDate={(e) => disabledDate(e, moment().add(-1, 'day') ?? moment())}
                      defaultValue={moment(data.startDate)}
                      format={'DD.MM.YYYY'}
                      onChange={(e) => setDatePicker(e)}
                    />
                  </div>
                  <div className='col-12 col-md-6 mt-4'>
                    <label className='fw-bold fs-6 mb-2'>Başlangıç Saati</label>
                    <div className='w-100'>
                      <Time
                        time={startTime}
                        onChange={(e) => {
                          setStartTime(e)
                        }}
                      />
                    </div>
                  </div>
                  <div className='col-12 col-md-6 mt-4'>
                    <label className='fw-bold fs-6 mb-2'>Bitiş Saati</label>
                    <div className='w-100'>
                      <Time
                        time={endTime}
                        onChange={(e) => {
                          setEndTime(e)
                        }}
                      />
                    </div>
                  </div>
                  <div className='col-12 col-md-6 mt-4'>
                    <label className='fw-bold fs-6 mb-2'>Program</label>
                    <ReactSelect
                      options={options}
                      value={selectedProgram}
                      onChange={(e) => setSelectedProgram(e)}
                    />
                  </div>
                  <div className='col-12 col-md-6 mt-4'>
                    <label className='fw-bold fs-6 mb-5'>Yayın Tipi</label>
                    <div className='d-flex flex-row'>
                      <div className=''>
                        <input
                          className='form-check-input'
                          type='radio'
                          checked={!isReplay}
                          value={false}
                          name='logo'
                          id='flexRadioSm'
                          onChange={(e) => {
                            setIsReplay(e.target.value === 'true' ? true : false)
                          }}
                        />
                        <label className='form-check-label ms-2' htmlFor='flexRadioSm'>
                          Canlı
                        </label>
                      </div>

                      <div className='ms-4'>
                        <input
                          className='form-check-input'
                          type='radio'
                          checked={isReplay}
                          value={true}
                          name='logo'
                          id='flexRadioSm'
                          onChange={(e) => {
                            setIsReplay(e.target.value === 'true' ? true : false)
                          }}
                        />
                        <label className='form-check-label ms-2' htmlFor='flexRadioSm'>
                          Tekrar
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* <div className='row mt-4'>
                    <div className='col-md-12 mb-4'>
                      <h5>Tür </h5>
                      <span></span>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-12 mb-4'>
                      <h5>Etiketler </h5>
                      {data.program.tags.map((tag) => {
                        return (
                          <span className='tag' key={tag.id}>
                            {tag.value}
                          </span>
                        )
                      })}
                    </div>
                  </div> */}

                  <div className='col-12 mt-4'>
                    <button className='btn btn-success' onClick={() => handleSubmit()}>
                      Güncelle
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export default UpdateSchedule
