/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import moment from 'moment'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import './../../../../../src/_metronic/assets/css/rsuite.css'

import Table from './components/Table'
import { KTCard, KTSVG } from '../../../../_metronic/helpers'
import { useParams } from 'react-router-dom'
import {
  broadcastTrailer,
  deleteTrailer,
  getTrailers,
  getTrailersWithoutTime,
  statusTrailer,
} from '../../../services/publishers.service'
import { Button } from '@mui/material'
import PlayerModal from './components/PlayerModal'
import UpdateModal from './components/UpdateModal'
import TableHeader from './components/TableHeader'
import AddModal from './components/AddModal'
import PriorityModal from './components/PriorityModal'
import { decodeEntities } from '../../../utils/format'
import ReactSelect from 'react-select'

const Trailers = () => {
  let { publisher_id } = useParams()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [channels, setChannels] = useState([])
  const [selectedCreative, setSelectedCreative] = useState(null)
  const [updateData, setUpdateData] = useState(null)
  const [addModal, setAddModal] = useState(false)
  const [priorityModal, setPriorityModal] = useState(false)
  const [selectedTime, setSelectedTime] = useState({
    label: 'Son 1 Hafta',
    value: {
      part: 1,
      month: 'weeks',
    },
  })

  const customColumns = [
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          style={{ cursor: 'pointer' }}
        // onClick={sortColumn}
        >
          Öncelik
        </th>
      ),
      accessor: 'priority',
      Cell: ({ ...props }) => {
        const value = props.row.original
        return <span>{value.isLiveAndWillLive ? value.priority : '-'}</span>
      },
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          style={{ cursor: 'pointer' }}
        // onClick={sortColumn}
        >
          Tanıtım Adı
        </th>
      ),
      accessor: 'creative.name',
      Cell: ({ ...props }) => {
        return decodeEntities(props.row.original.creative.name)
      },
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          style={{ cursor: 'pointer' }}
        // onClick={sortColumn}
        >
          Başlangıç
        </th>
      ),
      accessor: 'start',
      Cell: ({ ...props }) => {
        const value = props.row.original
        return <span>{value.startDate ? moment(value.startDate).format('DD MMM YYYY') : ''}</span>
      },
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          style={{ cursor: 'pointer' }}
        // onClick={sortColumn}
        >
          Bitiş
        </th>
      ),
      accessor: 'end',
      Cell: ({ ...props }) => {
        const value = props.row.original
        return <span>{value.endDate ? moment(value.endDate).format('DD MMM YYYY') : ''}</span>
      },
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          style={{ cursor: 'pointer' }}
        // onClick={sortColumn}
        >
          Saat
        </th>
      ),
      accessor: 'endHour',
      Cell: ({ ...props }) => {
        const value = props.row.original
        return <span>{value.endHour ? value.endHour : ''}</span>
      },
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          style={{ cursor: 'pointer' }}
        // onClick={sortColumn}
        >
          Kanallar
        </th>
      ),
      accessor: 'channels',
      Cell: ({ ...props }) => {
        const value = props.row.original
        let channels = ''
        value.channels.forEach((v, i) => {
          if (i === 0) {
            channels += v.name
          } else {
            channels += ', ' + v.name
          }
        })
        return <span>{channels}</span>
      },
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          style={{ cursor: 'pointer' }}
        // onClick={sortColumn}
        >
          Logo
        </th>
      ),
      accessor: 'hasLogo',
      Cell: ({ ...props }) => {
        const value = props.row.original

        return (
          <span>
            {value.hasLogo ? (
              <KTSVG path='/media/icons/duotune/general/gen043.svg' className='svg-icon-3' />
            ) : (
                <KTSVG path='/media/icons/duotune/general/gen040.svg' className='svg-icon-3' />
              )}
          </span>
        )
      },
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          style={{ cursor: 'pointer' }}
        // onClick={sortColumn}
        >
          Görüntülenme
        </th>
      ),
      accessor: 'viewCount',
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          style={{ cursor: 'pointer' }}
        // onClick={sortColumn}
        >
          Durumu
        </th>
      ),
      accessor: 'isActive',
      Cell: ({ ...props }) => {
        const value = props.row.original

        return (
          <span>
            {value.creative.isActive ? (
              <button className='btn btn-primary btn-sm'>AKTİF</button>
            ) : (
                <button className='btn btn-danger btn-sm'>PASİF</button>
              )}
          </span>
        )
      },
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          style={{ cursor: 'pointer' }}
        // onClick={sortColumn}
        >
          Yayın Statüsü
        </th>
      ),
      accessor: 'streamStatus',
      Cell: ({ ...props }) => {
        const value = props.row.original
        const data = value.creative.status
        let label = ''
        let message = data.messageKey
        switch (data.name) {
          case 'LOADING':
            label = 'info'
            break
          case 'READY':
            label = 'primary'
            if (value.isLive && moment(value.start) < moment() && moment(value.end) > moment()) {
              message = 'Yayında'
            } else if (!value.start || moment(value.start) > moment()) {
              message = 'Yayına Hazır'
            } else if (moment(value.end) < moment()) {
              message = 'Tamamlandı'
            } else {
              label = 'danger'
              message = 'Durduruldu'
            }
            break
          case 'PREPARING':
            label = 'info'
            break
          case 'ERROR':
            label = 'danger'
            break
          default:
            label = 'danger'
            message = 'HATA'
            break
        }
        return <button className={`btn btn-${label} btn-sm w-100`}>{message}</button>
      },
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          style={{ cursor: 'pointer' }}
        // onClick={sortColumn}
        >
          İşlemler
        </th>
      ),
      accessor: 'actions',
      Cell: ({ ...props }) => {
        const value = props.row.original
        let statusMessage = ''
        let color = 'danger'
        let activation = true

        if (value.creative.isActive && value.isLive) {
          statusMessage = 'Durdur'
          activation = false
        } else if (
          value.creative.isActive &&
          moment() > moment(value.start) &&
          moment() < moment(value.end)
        ) {
          color = 'success'
          statusMessage = 'Yayınla'
          activation = false
        } else if (value.creative.isActive) {
          statusMessage = 'Deaktive et'
        } else {
          statusMessage = 'Aktive et'
          color = 'success'
        }
        return (
          <div className='d-flex flex-row'>
            {value.creative.status.name === 'READY' && (
              <div class='mx-1'>
                <button
                  className='btn btn-secondary btn-sm'
                  onClick={() => {
                    setSelectedCreative(value.creative)
                  }}
                >
                  <KTSVG
                    path='/media/icons/duotune/general/gen004.svg'
                    className='svg-icon-3 me-0'
                  />
                </button>
              </div>
            )}
            {value.creative.status.name === 'READY' && value.creative.isActive && (
              <div class='mx-1'>
                <button className='btn btn-info btn-sm' onClick={() => setUpdateData(value)}>
                  <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3 me-0' />
                </button>
              </div>
            )}
            {!value.creative.isActive && (
              <div class='mx-1'>
                <button
                  className='btn btn-warning btn-sm'
                  onClick={() => {
                    Swal.fire({
                      title: 'Silmek istiyor musun?',
                      text: '',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      cancelButtonText: 'Hayır',
                      confirmButtonText: 'Evet!',
                      preConfirm: async () => {
                        const res = await deleteTrailer(publisher_id, value.id)
                        if (res.success) {
                          Swal.fire('Silindi!', '', 'success')
                          fetchData()
                        } else {
                          Swal.fire('Hata!', res.data.data.errors[0].message)
                        }
                      },
                      allowOutsideClick: () => !Swal.isLoading(),
                    })
                  }}
                >
                  <KTSVG
                    path='/media/icons/duotune/general/gen027.svg'
                    className='svg-icon-3 me-0'
                  />
                </button>
              </div>
            )}
            <div class='mx-1'>
              <button
                className={`btn btn-${color} btn-sm text-nowrap`}
                onClick={() => {
                  Swal.fire({
                    title: 'Statüyü değiştirmek istiyor musun?',
                    text: '',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Hayır',
                    confirmButtonText: 'Evet!',
                    preConfirm: async () => {
                      let res = { success: false }
                      if (statusMessage === 'Deaktive et' || statusMessage === 'Aktive et') {
                        res = await statusTrailer(
                          publisher_id,
                          value.id,
                          statusMessage === 'Deaktive et' ? false : true
                        )
                      } else if (statusMessage === 'Durdur' || statusMessage === 'Yayınla') {
                        res = await broadcastTrailer(
                          publisher_id,
                          value.id,
                          statusMessage === 'Durdur' ? false : true
                        )
                      }
                      if (res.success) {
                        Swal.fire('Başarılı!', 'Statü başarıyla değiştirildi!', 'success')
                        fetchData()
                      } else {
                        Swal.fire('Error!', res.data.data.errors[0].message)
                      }
                    },
                    allowOutsideClick: () => !Swal.isLoading(),
                  })
                }}
              >
                {statusMessage}
              </button>
            </div>
          </div>
        )
      },
    },
  ]

  async function fetchData() {
    const trailers = await getTrailers(
      publisher_id,
      selectedTime.value.part,
      selectedTime.value.month
    )
    setData(trailers.data)
  }

  async function fetchDataWithoutTime() {
    const trailers = await getTrailersWithoutTime(publisher_id)
    setData(trailers.data.trailers)
    setChannels(trailers.data.channels)
  }

  useEffect(() => {
    fetchDataWithoutTime()
  }, [])

  useEffect(() => {
    fetchData()
  }, [selectedTime])

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     fetchData()
  //   }, 60000)
  //   return () => clearInterval(interval)
  // }, [])

  return (
    <>
      <KTCard>
        <TableHeader
          showAddModal={setAddModal}
          showPriorityModal={setPriorityModal}
          title='Tanıtımlar'
        />
        {data && (
          <div className='d-flex flex-row-reverse pe-4'>
            <ReactSelect
              options={[
                {
                  label: 'Son 1 Hafta',
                  value: {
                    part: 1,
                    month: 'weeks',
                  },
                },
                {
                  label: 'Son 1 Ay',
                  value: {
                    part: 1,
                    month: 'months',
                  },
                },
                {
                  label: 'Son 3 Ay',
                  value: {
                    part: 3,
                    month: 'months',
                  },
                },
                {
                  label: 'Son 6 Ay',
                  value: {
                    part: 6,
                    month: 'months',
                  },
                },
                {
                  label: 'Tüm Zamanlar',
                  value: {
                    part: 10,
                    month: 'years',
                  },
                },
              ]}
              styles={{
                control: (base) => {
                  return {
                    ...base,
                    minWidth: 150,
                    maxWidth: 200,
                  }
                },
                valueContainer: (provided, state) => ({
                  ...provided,
                  minWidth: '150px',
                  maxWidth: '200px',
                }),
              }}
              value={selectedTime}
              closeMenuOnSelect={true}
              onChange={(e) => setSelectedTime(e)}
              classNamePrefix='select'
            />
          </div>
        )}
        {data && <Table data={data} columns={customColumns} />}
      </KTCard>
      {selectedCreative && (
        <PlayerModal
          name={decodeEntities(selectedCreative.name)}
          url={selectedCreative.url}
          showModal={() => setSelectedCreative(null)}
        />
      )}
      {priorityModal && (
        <PriorityModal showModal={setPriorityModal} fetchData={fetchData} data={data} />
      )}
      {addModal && <AddModal showAddModal={setAddModal} fetchData={fetchData} />}
      {updateData && (
        <UpdateModal
          showUpdateModal={() => setUpdateData(null)}
          channels={channels}
          fetchData={fetchData}
          data={updateData}
        />
      )}
    </>
  )
}

export { Trailers }
