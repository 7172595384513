import React from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import Player from '../../../publisher/channelOffers/components/Player'

function CreativeModal({type, url, name, showModal}) {
  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content' style={{minHeight: '80vh'}}>
            <div className='modal-header'>
              <h2 className='fw-bolder'>{name}</h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => showModal(false)}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              {type === 1 ? <Player url={url} /> : <img src={url} width={'100%'} />}
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default CreativeModal
