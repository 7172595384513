import clsx from 'clsx'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useLayout} from '../../../../../_metronic/layout/core'

function TableHeader({showAddModal, title}) {
  const {classes} = useLayout()

  return (
    <div className='card-header border-0 pt-6'>
      <div
        id='kt_page_title'
        data-kt-swapper='true'
        data-kt-swapper-mode='prepend'
        data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
        className={clsx('page-title d-flex', classes.pageTitle.join(' '))}
      >
        <h1>{title}</h1>
      </div>
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
          {/* <UsersListFilter /> */}

          {/* begin::Export */}
          {/* <button type='button' className='btn btn-light-primary me-3'>
            <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
            Export
          </button> */}
          {/* end::Export */}

          {/* begin::Add user */}
          <button type='button' className='btn btn-primary' onClick={() => showAddModal(true)}>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Ekle
          </button>
          {/* end::Add user */}
        </div>
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export default TableHeader
