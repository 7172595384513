import axios from 'axios'
import moment from 'moment'
import {decodeToken} from 'react-jwt'
import {refresh} from './auth'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
})

instance.interceptors.request.use(
  async (config) => {
    const bearer = await refreshToken(localStorage.getItem('token'))
    if (bearer) {
      config.headers['Authorization'] = `Bearer ${bearer}`
      return config
    } else {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      document.location.reload()
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

const refreshToken = async (token) => {
  const decoded = await decodeToken(token)

  if (moment().add(5, 'minutes').isAfter(moment.unix(decoded.exp))) {
    let header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const res = await refresh(token, header)
    return res
  } else {
    return token
  }
}

export {instance}
