import {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useParams} from 'react-router-dom'
import {KTSVG} from '../../../../../_metronic/helpers'

import {addCallToAction} from '../../../../services/creatives.service'

const AddCallToActionModal = ({setCallToActionModal, creativeObj, fetchData}) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  let {agency_id} = useParams()

  const [callToActionData] = useState({
    heading: creativeObj.row.original.heading !== '' ? creativeObj.row.original.heading : '',
    buttonText: creativeObj.row.original.button !== '' ? creativeObj.row.original.button : '',
    displayUrl:
      creativeObj.row.original.displayUrl !== '' ? creativeObj.row.original.displayUrl : '',
  })

  const formik = useFormik({
    initialValues: callToActionData,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const response = await addCallToAction(agency_id, creativeObj.row.original.id, values)
        if (response.success) {
          fetchData()
          setCallToActionModal(false)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })

  const headingPreviewStyle = {
    fontSize: '12px',
    color: 'black',
    fontWeight: 'bold',
    display: 'block',
    textAlign: 'left',
    marginTop: '5px',
  }

  const displayUrlPreviewStyle = {
    fontSize: '10px',
    color: 'black',
    display: 'block',
    textAlign: 'left',
    marginTop: '5px',
  }

  const ctaBottomStyle = {
    position: 'absolute',
    bottom: '18px',
    left: '32px',
    color: '#fff',
    backgroundColor: '#fff',
    borderColor: '#46b8da',
    width: '50%',
    height: '20%',
  }

  const imagePreviewStyle = {
    marginTop: '5px',
    width: '100%',
    maxHeight: '45px',
    maxWidth: '45px',
    minHeight: '30px',
    minWidth: '30px',
  }

  const shortenString = (input, len) => {
    if (input.length > len) {
      return input.substring(0, len) + '...'
    }
    return input
  }

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <div className='modal-header'>
              {/* begin::Modal title */}
              <h2 className='fw-bolder'>
                Call To Action Oluştur - {creativeObj.row.original.advertiser.name} -{' '}
                {creativeObj.row.original.name}
              </h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => setCallToActionModal(false)}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <form
                id='kt_modal_add_user_form'
                className='form'
                onSubmit={formik.handleSubmit}
                noValidate
              >
                {/* begin::Scroll */}
                <div
                  className='d-flex flex-column scroll-y me-n7 pe-7'
                  id='kt_modal_add_user_scroll'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  data-kt-scroll-max-height='auto'
                  data-kt-scroll-dependencies='#kt_modal_add_user_header'
                  data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                  data-kt-scroll-offset='300px'
                >
                  {/* begin::Input group */}
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Başlık</label>
                    <input
                      placeholder='Başlık'
                      {...formik.getFieldProps('heading')}
                      type='text'
                      name='heading'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.name && formik.errors.name},
                        {
                          'is-valid': formik.touched.name && !formik.errors.name,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.name}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* end::Input group */}

                  {/* begin::Input group */}
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Buton Yazısı</label>
                    <input
                      placeholder='Buton Yazısı'
                      {...formik.getFieldProps('buttonText')}
                      type='text'
                      name='buttonText'
                      maxlength='10'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.name && formik.errors.name},
                        {
                          'is-valid': formik.touched.name && !formik.errors.name,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.name}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* end::Input group */}

                  {/* begin::Input group */}
                  <div className='mb-7'>
                    <label className='required fw-bold fs-6 mb-5'>Gösterilecek Link</label>
                    <input
                      placeholder='Gösterilecek Link'
                      {...formik.getFieldProps('displayUrl')}
                      type='text'
                      name='displayUrl'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.name && formik.errors.name},
                        {
                          'is-valid': formik.touched.name && !formik.errors.name,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.name}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='row'>
                    <label className='control-label' htmlFor='name'>
                      Önizleme
                    </label>
                    <div className='cta-container'>
                      <img className='mw-100' src='/media/cta/cta-image.jpeg' alt='' />
                      <div
                        className='cta-bottom-left row align-items-center'
                        style={ctaBottomStyle}
                      >
                        <div className='col-xs-2 col-sm-2 col-md-2'>
                          <img
                            id='imagePreview'
                            style={imagePreviewStyle}
                            src={
                              creativeObj.row.original.advertiser.icon
                                ? creativeObj.row.original.advertiser.icon
                                : ''
                            }
                            alt=''
                          />
                        </div>
                        <div className='col-xs-5 col-sm-5 col-md-4'>
                          <span id='headingPreview' style={headingPreviewStyle}>
                            {formik.values.heading !== ''
                              ? shortenString(formik.values.heading, 15)
                              : `Başlık`}
                          </span>
                          <span id='displayUrlPreview' style={displayUrlPreviewStyle}>
                            {formik.values.displayUrl !== ''
                              ? shortenString(formik.values.displayUrl, 10)
                              : `Link`}
                          </span>
                        </div>
                        <div className='col-xs-5 col-md-4 text-nowrap'>
                          <button
                            className='btn btn-sm btn-block btn-info cta-button'
                            id='buttonTextPreview'
                            type='button'
                            onClick={(e) => e.preventDefault}
                          >
                            {formik.values.buttonText !== '' ? formik.values.buttonText : `Buton`}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end::Scroll */}

                {/* begin::Actions */}
                <div className='text-center pt-15'>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                  >
                    <span className='indicator-label'>Kaydet</span>
                    {formik.isSubmitting && (
                      <span className='indicator-progress'>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
                {/* end::Actions */}
              </form>
              {/* <UserEditModalFormWrapper /> */}
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export default AddCallToActionModal
