/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import Select from 'react-select'
import moment from 'moment'
import IonRangeSlider from 'react-ion-slider'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import './style.css'
import { KTCard, KTSVG } from '../../../../_metronic/helpers'
import { useParams } from 'react-router-dom'
import { DatePicker } from 'antd'
import locale from 'antd/es/date-picker/locale/tr_TR'
import {
  getOffers,
  getEvents,
  createOffer,
  getOffer,
  updateOffer,
} from '../../../services/agencies.service'
import Swal from 'sweetalert2'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4maps from '@amcharts/amcharts4/maps'
import ReactSelect from 'react-select'
import am4geodata_turkeyLow from '@amcharts/amcharts4-geodata/turkeyLow'
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import SummaryModal from './SummaryModal'
import Time from './Time/Time'
import { useThemeMode } from '../../../../_metronic/partials'
import { decodeEntities } from '../../../utils/format'

const { RangePicker } = DatePicker
am4core.useTheme(am4themes_animated)

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
}
const customStyles2 = {
  control: (base) => {
    return {
      ...base,
      maxHeight: 150,
    }
  },
  valueContainer: (provided, state) => ({
    ...provided,
    maxHeight: '150px',
    overflow: 'scroll',
  }),
}
const customStyles = {
  multiValue: (base, state) => {
    return { ...base, backgroundColor: '#0e0e15' }
  },
  multiValueLabel: (base, state) => {
    return { ...base, fontWeight: 'bold', color: '#92929F', paddingRight: 6 }
  },
  option: (base) => {
    return { ...base, color: '#92929F', backgroundColor: '#1e1e2d' }
  },
  menu: (base) => {
    return { ...base, color: '#92929F', backgroundColor: '#1e1e2d' }
  },
  control: (base) => {
    return { ...base, color: '#92929F', backgroundColor: '#1e1e2d', borderColor: '#323248' }
  },
}

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
)

const OfferEdit = () => {
  const { mode } = useThemeMode()
  let { agency_id, offer_id } = useParams()

  const [polygonSeries, setPolygonSeries] = useState()
  const [polygonSeries2, setPolygonSeries2] = useState()
  const polygonSeriesRef = useRef()
  polygonSeriesRef.current = polygonSeries
  const polygonSeries2Ref = useRef()
  polygonSeries2Ref.current = polygonSeries2

  const chart = useRef(null)
  const chartWorld = useRef(null)

  const [summary, setSummary] = useState(false)

  const [data, setData] = useState(null)
  const [editingData, setEditingData] = useState(null)
  const [time, setTime] = useState({
    start: '06:00',
    end: '23:59',
  })
  const [pssActive, setPssActive] = useState(true)
  let df = null
  let tempChannels = []

  const getEditingOffer = async (allOffersData) => {
    const res = await getOffer(agency_id, offer_id)
    if (!res.success) {
      Swal.fire('Hata', 'Bir hata oluştu.', 'error')
    } else {
      let data = res.data
      setEditingData(data)
      df = res.data.offer

      let videoIds = []
      let tempSelectedVideoIds = []
      let overlayIds = []
      let tempSelectedOverlayIds = []
      df.creatives.forEach((v) => {
        if (v.creativeTypeId === 1) {
          videoIds.push(v.id)
          tempSelectedVideoIds.push({ label: decodeEntities(v.name), value: v.id })
        } else {
          overlayIds.push(v.id)
          tempSelectedOverlayIds.push({ label: decodeEntities(v.name), value: v.id })
        }
      })
      setSelectedVideoIds(tempSelectedVideoIds)
      setSelectedOverlayIds(tempSelectedOverlayIds)

      setSelectedCampaign(df.campaign)

      let advertiser = allOffersData.advertisers.find((x) => x.id === parseInt(data.advertiser.id))
      setSelectedAdver(advertiser)

      let adLong = false
      if (videoIds.length > 0) {
        for (let i = 0; i < advertiser.ads.length; i++) {
          let ad = advertiser.ads[i]
          if (videoIds.includes(ad.id) && ad.duration > allOffersData.PSS_LIMIT_AD_LENGTH) {
            adLong = true
          }
        }
      }

      setTime({
        start: moment(df.startDate).format('HH:mm'),
        end: moment(df.endDate).format('HH:mm'),
      })

      setDatePicker({
        start: moment().isAfter(moment(df.startDate))
          ? moment().format('DD.MM.YYYY')
          : moment(df.startDate).format('DD.MM.YYYY'),
        end: moment(df.endDate).format('DD.MM.YYYY'),
      })

      setOffer({
        ...offer,
        campaignId: df.campaignId,
        advertiserId: df.advertiserId,
        start: df.start,
        end: df.end,
        name: decodeEntities(df.name),
        age: df.age,
        partOfDay: df.partOfDay,
        partOfWeek: df.partOfWeek,
        gender: df.gender,
        device: df.device,
        operator: df.operator,
        location: df.location,
        freqVideoPeriod: videoIds.length > 0 ? df.frequencyPeriod : '',
        freqVideoValue: videoIds.length > 0 ? df.frequencyValue : '',
        freqOverlayPeriod: overlayIds.length > 0 ? df.frequencyPeriod : '',
        freqOverlayValue: overlayIds.length > 0 ? df.frequencyValue : '',
        videoEnabled: videoIds.length > 0 ? true : false,
        videoAdIds: tempSelectedVideoIds,
        overlayEnabled: overlayIds.length > 0 ? true : false,
        overlayAdIds: tempSelectedOverlayIds,
        overlayBudgetDistribution: overlayIds.length > 0 ? df.budgetDistribution : '',
        videoBudgetDistribution: videoIds.length > 0 ? df.budgetDistribution : '',
        videoBudgetDaily:
          videoIds.length > 0 && df.budgetDistribution === 'CUSTOM' ? df.dailyBudget : 0,
        overlayBudgetDaily:
          overlayIds.length > 0 && df.budgetDistribution === 'CUSTOM' ? df.dailyBudget : 0,
        videoBudget: videoIds.length > 0 ? df.rawBudget : 0,
        overlayBudget: overlayIds.length > 0 ? df.rawBudget : 0,
        videoOverload: videoIds.length > 0 ? df.overload : '',
        overlayOverload: overlayIds.length > 0 ? df.overload : '',
        isAdLong: adLong,
        pssEnabled: df.pssPrice > -1 ? true : false,
        pssBudget: df.pssPrice > -1 ? df.pssBudget : 0,
        pssPrice: df.pssPrice > -1 ? df.pssPrice : 0,
        nsEnabled: df.nsPrice > -1 ? true : false,
        nsBudget: df.nsPrice > -1 ? df.nsBudget : 0,
        nsPrice: df.nsPrice > -1 ? df.nsPrice : 0,
        cpm: overlayIds.length > 0 ? df.cpm : 0,
        videoTargetedImpression: videoIds.length > 0 ? df.targetedImpression : 0,
        overlayTargetedImpression: overlayIds.length > 0 ? df.targetedImpression : 0,
      })

      setNsPss({
        pss: df.pssPrice > -1 ? true : false,
        pssBudget: df.pssPrice > -1 ? df.pssBudget : 0,
        pssPrice: df.pssPrice > -1 ? df.pssPrice : 0,
        ns: df.nsPrice > -1 ? true : false,
        nsBudget: df.nsPrice > -1 ? df.nsBudget : 0,
        nsPrice: df.nsPrice > -1 ? df.nsPrice : 0,
      })

      setSelectedGenders(df.gender.split(';'))
      setSelectedAge(df.age)

      let tempSelectedDevice = []
      if (df.device === '-1') {
        Object.keys(allOffersData.deviceParams).forEach((v) => {
          tempSelectedDevice.push({ label: allOffersData.deviceParams[v], value: v })
        })
      } else {
        df.device.split(';').forEach((v) => {
          let a = Object.keys(allOffersData.deviceParams).find((x) => x === v)
          if (a) {
            tempSelectedDevice.push({ label: allOffersData.deviceParams[a], value: v })
          }
        })
      }
      setSelectedDevices(tempSelectedDevice)

      let tempSelectedOperators = []
      if (df.operator === '-1') {
        Object.keys(allOffersData.operatorParams).forEach((v) => {
          tempSelectedOperators.push({ label: allOffersData.operatorParams[v], value: v })
        })
      } else {
        df.operator.split(';').forEach((v) => {
          let a = Object.keys(allOffersData.operatorParams).find((x) => x === v)
          if (a) {
            tempSelectedOperators.push({ label: allOffersData.operatorParams[a], value: v })
          }
        })
      }
      setSelectedOperators(tempSelectedOperators)

      let tempFreq = []
      let frequencyPeriod = df.frequencyPeriod ? df.frequencyPeriod.split(',') : []
      let frequencyValue = df.frequencyValue ?  df.frequencyValue.split(',') : []
      for (let i = 0; i < frequencyPeriod.length; i++) {
        tempFreq.push({ label: frequencyPeriod[i], value: frequencyValue[i] })
      }
      if (videoIds.length > 0) setVideoFreq(tempFreq)
      else setOverlayFreq(tempFreq)

      let trLocations = []
      allOffersData.groupedCities.forEach((element) => {
        let obj = { label: element.id }
        let arr = []
        element.value.forEach((v) => {
          arr.push({ label: v.il, value: v.plaka })
        })
        arr.sort((a, b) => a.value - b.value)
        obj.options = arr
        trLocations = trLocations.concat(arr)
      })

      let options = []
      data.groupedCountries.forEach((element) => {
        let obj = { label: element.id }
        let arr = []
        element.value.forEach((v) => {
          arr.push({ label: v.tr, value: v.a2, en: v.en })
        })
        arr.sort((a, b) => a.value - b.value)
        obj.options = arr
        options = options.concat(arr)
      })

      let tempTrLocations = []
      if (df.location.tr[0] && df.location.tr[0] === 'ALL') {
        tempTrLocations = trLocations
      } else {
        df.location.tr.forEach((v) => {
          let a = trLocations.find((x) => x.value === parseInt(v))
          if (a) {
            tempTrLocations.push(a)
          }
        })
      }

      let tempWorldLocations = []
      if (df.location.world[0] && df.location.world[0] === 'ALL') {
        tempWorldLocations = options
      } else {
        df.location.world.forEach((v) => {
          let a = options.find((x) => x.value === v)
          if (a) {
            tempWorldLocations.push(a)
          }
        })
      }
      setSelectedLocations({ ...selectedLocations, tr: tempTrLocations, world: tempWorldLocations })

      let tempEventsQuery = {
        partOfDay1: false,
        partOfDay2: false,
        partOfDay3: false,
        partOfDay4: false,
        partOfDay5: false,
        partOfDay6: false,
        partOfWeek1: false,
        partOfWeek2: false,
      }
      df.partOfDay.split(';').forEach((v) => {
        if (v === '1') {
          tempEventsQuery.partOfDay1 = true
        }

        if (v === '2') {
          tempEventsQuery.partOfDay2 = true
        }

        if (v === '3') {
          tempEventsQuery.partOfDay3 = true
        }

        if (v === '4') {
          tempEventsQuery.partOfDay4 = true
        }

        if (v === '5') {
          tempEventsQuery.partOfDay5 = true
        }

        if (v === '6') {
          tempEventsQuery.partOfDay6 = true
        }
      })

      df.partOfWeek.split(';').forEach((v) => {
        if (v === '1') {
          tempEventsQuery.partOfWeek1 = true
        }

        if (v === '2') {
          tempEventsQuery.partOfWeek2 = true
        }
      })
      setEventQuery(tempEventsQuery)
      loadAgencyEvents(tempEventsQuery, {
        start: moment().format('DD.MM.YYYY'),
        end: moment(df.endDate).format('DD.MM.YYYY'),
      })

      tempChannels = []
      df.offerChannels.forEach((v) => {
        tempChannels.push({
          id: v.tvStationId,
          videoEnabled: videoIds.length > 0 ? true : false,
          overlayEnabled: overlayIds.length > 0 ? true : false,
          videoBudget: videoIds.length > 0 && v.rawBudget > -1 ? v.rawBudget : '',
          overlayBudget: overlayIds.length > 0 && v.rawBudget > -1 ? v.rawBudget : '',
          pssEnabled: df.pssPrice > -1 ? true : false,
          nsEnabled: df.nsPrice > -1 ? true : false,
          programs: v.programs.map((x) => x.id),
          allProgramsSelected: v.isAllProgramsSelected,
          addedPrograms: [],
          removedPrograms: [],
        })
      })
      setSelectedEvents(tempChannels)
    }
  }

  const [offer, setOffer] = useState({
    campaignId: null,
    advertiserId: null,
    start: null,
    end: null,
    name: null,
    age: null,
    partOfDay: null,
    partOfWeek: null,
    gender: null,
    device: null,
    operator: null,
    location: {
      tr: [],
      world: [],
    },
    videoEnabled: true,
    overlayEnabled: false,
    videoAdIds: [],
    overlayAdIds: [],
    overlayBudgetDistribution: 'FRONTLOADED',
    videoBudgetDistribution: 'FRONTLOADED',
    videoBudgetDaily: 1,
    overlayBudgetDaily: 1,
    videoBudget: 0,
    overlayBudget: '0',
    isAdLong: true,
    nsEnabled: true,
    pssEnabled: false,
    pssPrice: 1,
    nsPrice: 1,
    pssBudget: 0,
    nsBudget: 0,
    cpm: 1,
    overlayOverload: '0',
    videoOverload: '5',
    overlayTargetedImpression: 0,
    videoTargetedImpression: 0,

    freqVideoPeriod: '',
    freqVideoValue: '',
    freqOverlayPeriod: '',
    freqOverlayValue: '',

  })
  const [events, setEvents] = useState([])
  const [eventsQuery, setEventQuery] = useState({
    partOfDay1: true,
    partOfDay2: true,
    partOfDay3: true,
    partOfDay4: true,
    partOfDay5: true,
    partOfDay6: true,
    partOfWeek1: true,
    partOfWeek2: true,
  })
  const [selectedEvents, setSelectedEvents] = useState([])
  const sEventsRef = useRef()
  sEventsRef.current = selectedEvents

  const [selectedEvent, setSelectedEvent] = useState(null)

  const [selectedAdver, setSelectedAdver] = useState(null)
  const [selectedVideoIds, setSelectedVideoIds] = useState([])
  const [selectedOverlayIds, setSelectedOverlayIds] = useState([])

  const [selectedAge, setSelectedAge] = useState('')

  const [datePicker, setDatePicker] = useState(null)
  const [selectedCampaign, setSelectedCampaign] = useState(null)

  const [nsPss, setNsPss] = useState({
    ns: true,
    nsBudget: 0,
    nsPrice: 0,
    pss: true,
    pssBudget: 0,
    pssPrice: 0,
  })

  const [videoFreq, setVideoFreq] = useState([])
  const [overlayFreq, setOverlayFreq] = useState([])

  const [selectedGenders, setSelectedGenders] = useState([])
  const [selectedOperators, setSelectedOperators] = useState([])
  const [selectedDevices, setSelectedDevices] = useState([])

  const [selectedLocations, setSelectedLocations] = useState({
    tr: [],
    world: [],
  })
  const locationsRef = useRef(null)
  locationsRef.current = selectedLocations

  const [groupCities, setGroupCities] = useState([])
  const citiesRef = useRef(null)
  citiesRef.current = groupCities
  const [groupCountries, setGroupCountries] = useState([])
  const countriesRef = useRef(null)
  countriesRef.current = groupCountries

  async function fetchData() {
    const response = await getOffers(agency_id)
    if (!response.success) {
      Swal.fire(
        'Bir sorunla karşılaştık.',
        response.data.data.errors
          ? response.data.data.errors[0].message
          : 'Lütfen tekrar deneyiniz.',
        'error'
      )
      return
    }

    setData(response.data)

    getEditingOffer(response.data)
  }

  const getAgencyEvents = async () => {
    let query = ''
    Object.keys(eventsQuery).forEach((key, i) => {
      if (eventsQuery[key] && i === 0) {
        query += key.substring(0, key.length - 1) + '=' + key[key.length - 1]
      } else if (eventsQuery[key]) {
        query += '&' + key.substring(0, key.length - 1) + '=' + key[key.length - 1]
      }
    })
    query +=
      '&start=' +
      moment(datePicker.start, 'DD.MM.YYYY').format('YYYY-MM-DD') +
      '&end=' +
      moment(datePicker.end, 'DD.MM.YYYY').format('YYYY-MM-DD')

    const response = await getEvents(agency_id, query)
    if (!response.success) {
      Swal.fire(
        'Bir sorunla karşılaştık.',
        response.data.data.errors
          ? response.data.data.errors[0].message
          : 'Lütfen tekrar deneyiniz.',
        'error'
      )
    } else {
      setEvents(response.data)
    }
  }

  const loadAgencyEvents = async (value, date) => {
    let query = ''
    Object.keys(value).forEach((key, i) => {
      if (value[key] && i === 0) {
        query += key.substring(0, key.length - 1) + '=' + key[key.length - 1]
      } else if (value[key]) {
        query += '&' + key.substring(0, key.length - 1) + '=' + key[key.length - 1]
      }
    })
    query +=
      '&start=' +
      moment(date.start, 'DD.MM.YYYY').format('YYYY.MM.DD') +
      '&end=' +
      moment(date.end, 'DD.MM.YYYY').format('YYYY.MM.DD')

    const response = await getEvents(agency_id, query)
    if (!response.success) {
      Swal.fire(
        'Bir sorunla karşılaştık.',
        response.data.data.errors
          ? response.data.data.errors[0].message
          : 'Lütfen tekrar deneyiniz.',
        'error'
      )
    } else {
      setEvents(response.data)
    }
  }

  const eventsQueryOnChange = (e) => {
    let temp = { ...eventsQuery }
    temp[e.target.name] = e.target.checked
    setEventQuery(temp)
  }

  const selectEvent = (e) => {
    let temp = sEventsRef.current
    if (e.target.checked) {
      temp.push({
        id: parseInt(e.target.name),
        videoEnabled: offer.videoEnabled,
        overlayEnabled: offer.overlayEnabled,
        videoBudget: '',
        overlayBudget: '',
        pssEnabled: nsPss.pss,
        nsEnabled: nsPss.ns,
        programs: [],
        allProgramsSelected: true,
      })
    } else {
      temp = temp.filter((x) => x.id !== parseInt(e.target.name))
    }
    setSelectedEvents(temp)
  }

  useEffect(() => {
    fetchData()
  }, [])

  useLayoutEffect(() => {
    let x = am4core.create('chartdiv', am4maps.MapChart)
    x.geodata = am4geodata_turkeyLow

    x.projection = new am4maps.projections.Miller()
    x.seriesContainer.draggable = false
    x.seriesContainer.resizable = false
    x.maxZoomLevel = 1

    let aa = createPolygonSeries(x)
    setPolygonSeries(aa)

    const polygonTemplate = createPolygonTemplate(aa)

    const hs = createActiveState(polygonTemplate)

    x.events.on('ready', function (ev) {
      let temp = { ...selectedLocations }

      const results2 = citiesRef.current.filter(
        ({ value: id1 }) => !temp.tr.some(({ value: id2 }) => id2 === id1)
      )
      results2.forEach((va) => {
        let a = aa.data.find(
          (x) => x.name.toLocaleLowerCase('TR') === va.label.toLocaleLowerCase('TR')
        )
        if (a) {
          let poly = aa.getPolygonById(`${a.id}`)
          poly.isActive = true
        }
      })
    })

    polygonTemplate.events.on('hit', function (ev) {
      if (ev.target.isActive) {
        let tempArr = { ...locationsRef.current }
        tempArr.tr.push({
          label: ev.target.dataItem.dataContext.name.toLocaleUpperCase('TR'),
          value: parseInt(ev.target.dataItem.dataContext.id.slice(-2)),
        })
        setSelectedLocations(tempArr)
      } else {
        let tempArr = { ...locationsRef.current }
        tempArr.tr = tempArr.tr.filter(
          (x) => x.label !== ev.target.dataItem.dataContext.name.toLocaleUpperCase('TR')
        )
        setSelectedLocations(tempArr)
      }
      ev.target.isActive = !ev.target.isActive
    })

    chart.current = x

    return () => {
      x.dispose()
    }
  }, [editingData])

  useLayoutEffect(() => {
    let x = am4core.create('chartdiv2', am4maps.MapChart)
    x.geodata = am4geodata_worldLow

    x.projection = new am4maps.projections.Miller()
    x.seriesContainer.draggable = false
    x.seriesContainer.resizable = false
    x.maxZoomLevel = 3

    let aa = createPolygonSeries(x)
    setPolygonSeries2(aa)

    const polygonTemplate = createPolygonTemplate(aa, true)

    const hs = createActiveState(polygonTemplate, true)

    x.events.on('ready', function (ev) {
      let temp = { ...selectedLocations }

      let countries = []
      groupCountries.forEach((v) => {
        countries.push(v.options)
      })
      countries = countries.flat(1)

      const results2 = countries.filter(
        ({ value: id1 }) => !temp.world.some(({ value: id2 }) => id2 === id1)
      )

      const results1 = temp.world.filter(
        ({ value: id1 }) => !results2.some(({ value: id2 }) => id2 === id1)
      )

      results1.forEach((va) => {
        let a = aa.data.find((x) => x.id === va.value)
        if (a) {
          let poly = aa.getPolygonById(`${a.id}`)
          poly.isActive = true
        }
      })
    })

    polygonTemplate.events.on('hit', function (ev) {
      let b = []
      countriesRef.current.forEach((v) => {
        b = b.concat(v.options)
      })
      if (!ev.target.isActive) {
        let tempArr = { ...locationsRef.current }
        let obj = b.find((x) => x.value === ev.target.dataItem.dataContext.id)
        tempArr.world.push({
          label: obj.label,
          value: obj.value,
        })
        setSelectedLocations(tempArr)
      } else {
        let tempArr = { ...locationsRef.current }
        tempArr.world = tempArr.world.filter((x) => x.value !== ev.target.dataItem.dataContext.id)
        setSelectedLocations(tempArr)
      }
      ev.target.isActive = !ev.target.isActive
    })

    chartWorld.current = x

    return () => {
      x.dispose()
    }
  }, [editingData])

  function createPolygonSeries(chart) {
    const polygonSeries = chart.series.push(new am4maps.MapPolygonSeries())
    polygonSeries.useGeodata = true
    return polygonSeries
  }

  function createPolygonTemplate(polygonSeries, t) {
    const polygonTemplate = polygonSeries.mapPolygons.template
    polygonTemplate.tooltipText = '{name}'
    polygonTemplate.fill = am4core.color(t ? '#D7D7D7' : '#7FC5E4')
    return polygonTemplate
  }

  function createActiveState(polygonTemplate, t) {
    const activeState = polygonTemplate.states.create('active')
    activeState.properties.fill = am4core.color(t ? '#7FC5E4' : '#D7D7D7')
    return activeState
  }

  useEffect(() => {
    if (selectedAdver) {
      let videos = []
      let pssActive = true
      let isAdLong = false
      let tempNsPss = nsPss
      if (offer.videoAdIds.length > 0) {
        offer.videoAdIds.forEach((v) => {
          videos.push(v.value)
        })
      }
      for (let i = 0; i < selectedAdver.ads.length; i++) {
        let ad = selectedAdver.ads[i]
        if (videos.includes(ad.id) && ad.duration > data.PSS_LIMIT_AD_LENGTH) {
          pssActive = false
          isAdLong = true
          tempNsPss.nsBudget = offer.videoBudget
          tempNsPss.pssBudget = 0
          i = selectedAdver.ads.length
        } else {
          pssActive = true
          isAdLong = false
        }
      }
      setPssActive(pssActive)
      if (!pssActive) {
        setNsPss({ ...tempNsPss, pss: pssActive })
        setOffer({ ...offer, pssEnabled: pssActive, isAdLong })
      } else {
        setOffer({ ...offer, isAdLong })
      }
    }
  }, [offer.videoAdIds])

  useEffect(() => {
    let nsImp = nsPss.nsPrice === 0 ? 0 : Math.floor(nsPss.nsBudget / nsPss.nsPrice)
    let pssImp = nsPss.pssPrice === 0 ? 0 : Math.floor(nsPss.pssBudget / nsPss.pssPrice)

    setOffer({
      ...offer,
      videoTargetedImpression: nsImp + pssImp,
      nsBudget: nsPss.nsBudget,
      nsPrice: nsPss.nsPrice,
      pssBudget: nsPss.pssBudget,
      pssPrice: nsPss.pssPrice,
    })
  }, [nsPss.nsPrice, nsPss.pssPrice, nsPss.nsBudget, nsPss.pssBudget])

  const nsPssFocus = (type) => {
    if (type !== 'pss' && !offer.pssEnabled) {
      setNsPss({ ...nsPss, nsBudget: offer.videoBudget, pssBudget: 0 })
    } else if (nsPss.nsBudget === 0 && nsPss.pssBudget === 0) {
      setNsPss({ ...nsPss, nsBudget: offer.videoBudget / 2, pssBudget: offer.videoBudget / 2 })
    }
  }

  const nsPssChange = (type, value) => {
    let nsBudget = 0
    let pssBudget = 0
    if (value === '') {
      value = 0
    } else {
      value = parseInt(value, 10)
    }

    if (value > offer.videoBudget) {
      return
    }
    if (type === 'pss') {
      if (!nsPss.ns) {
        pssBudget = offer.videoBudget
      } else {
        nsBudget = offer.videoBudget - value
        pssBudget = value
      }
    } else {
      if (!nsPss.pss) {
        nsBudget = offer.videoBudget
      } else {
        pssBudget = offer.videoBudget - value
        nsBudget = value
      }
    }
    setNsPss({ ...nsPss, pssBudget, nsBudget })
  }

  const handleSubmit = async () => {
    let sendData = { ...offer }
    sendData.overlayAdIds = offer.overlayAdIds.map((x) => x.value)
    sendData.videoAdIds = offer.videoAdIds.map((x) => x.value)
    sendData.device =
      selectedDevices.length === 3 ? '-1' : selectedDevices.map((x) => x.value).join(';')
    sendData.gender = selectedGenders.join(';')
    sendData.operator =
      selectedOperators.length === 6 ? '-1' : selectedOperators.map((x) => x.value).join(';')

    sendData.start = moment(datePicker.start, 'DD.MM.YYYY').format('YYYY-MM-DD') + ' ' + time.start
    sendData.end = moment(datePicker.end, 'DD.MM.YYYY').format('YYYY-MM-DD') + ' ' + time.end
    sendData.age =
      selectedAge !== ''
        ? selectedAge
        : data.ageParams[0] + '-' + data.ageParams[data.ageParams.length - 1]
    sendData.partOfDay = offer.partOfDay.join(';')
    sendData.partOfWeek = offer.partOfWeek.join(';')
    sendData.channels = selectedEvents

    if (polygonSeries.data.length === sendData.location.tr.length) {
      sendData.location.tr = ['ALL']
    } else if (!(sendData.location.tr.length === 1 && sendData.location.tr[0] === 'ALL')) {
      sendData.location.tr = sendData.location.tr.map((rec) => `0${rec}`.slice(-2))
    }
    if (polygonSeries2.data.length === sendData.location.world.length) {
      sendData.location.world = ['ALL']
    }

    let videoTotal = 0
    let overlayTotal = 0
    let videoWithMutualBudget = 0
    let overlayWithMutualBudget = 0
    sendData.channels.forEach((x) => {
      videoTotal += +x.videoBudget || 0
      overlayTotal += +x.overlayBudget || 0
      if (!x.videoBudget) {
        videoWithMutualBudget++
      }
      if (!x.overlayBudget) {
        overlayWithMutualBudget++
      }
      x.pssEnabled = sendData.pssEnabled
      x.nsEnabled = sendData.nsEnabled
      x.overlayEnabled = sendData.overlayEnabled
    })

    if (
      sendData.videoAdIds.length > 0 &&
      ((videoWithMutualBudget > 0 && videoTotal === sendData.videoBudget) || videoTotal > sendData.videoBudget) &&
      videoTotal > 0
    ) {
      Swal.fire({
        title: 'Bütçe hatası.',
        text: 'Kanalların video bütçe toplamı teklif bütçesinden az/çok olamaz!',
        icon: 'error',
      })
      return
    } else if (
      sendData.overlayAdIds.length > 0 &&
      ((overlayWithMutualBudget > 0 && overlayTotal === sendData.overlayBudget) || overlayTotal > sendData.overlayBudget) &&
      overlayTotal > 0
    ) {
      Swal.fire({
        title: 'Bütçe hatası.',
        text: 'Kanalların overlay bütçe toplamı teklif bütçesinden az/çok olamaz!',
        icon: 'error',
      })
      return
    }

    if (!sendData.videoAdIds.length && !sendData.overlayAdIds.length) {
      Swal.fire({
        title: 'Kreatif hatası.',
        text: 'Teklif için en az bir kreatif seçilmelidir!',
        icon: 'error',
      })
      return
    }

    Swal.fire({
      title: 'Teklifi göndermek için emin misiniz?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Evet!',
      cancelButtonText: 'İptal',
      preConfirm: async (login) => {
        const res = await updateOffer(agency_id, offer_id, sendData)
        if (res.success) {
          Swal.fire('Başarılı!', 'Teklifiniz başarıyla gönderildi.', 'success')
        } else {
          Swal.fire('Error!', res.data.data.errors[0].message, 'error')
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    })
  }

  useEffect(() => {
    setOffer({ ...offer, channels: selectedEvents })
  }, [selectedEvents])

  useEffect(() => {
    setOffer({
      ...offer,
      freqVideoPeriod: videoFreq.map((x) => x.label).join(','),
      freqVideoValue: videoFreq.map((x) => x.value).join(','),
    })
  }, [videoFreq])

  useEffect(() => {
    setOffer({
      ...offer,
      freqOverlayPeriod: overlayFreq.map((x) => x.label).join(','),
      freqOverlayValue: overlayFreq.map((x) => x.value).join(','),
    })
  }, [overlayFreq])

  useEffect(() => {
    let loc = {
      tr: [],
      world: [],
    }
    loc.tr = selectedLocations.tr.map((x) => x.value)
    loc.world = selectedLocations.world.map((x) => x.value)
    setOffer({ ...offer, location: loc })
  }, [selectedLocations])

  useEffect(() => {
    let partOfDay = []
    let partOfWeek = []
    Object.keys(eventsQuery).forEach((v, i) => {
      if (v.includes('partOfDay') && eventsQuery[v] === true) {
        partOfDay.push(v[v.length - 1])
      }
      if (v.includes('partOfWeek') && eventsQuery[v] === true) {
        partOfWeek.push(v[v.length - 1])
      }
    })

    setOffer({ ...offer, partOfDay, partOfWeek })
  }, [eventsQuery])

  useEffect(() => {
    if (selectedCampaign) {
      let groups = []
      let options = []
      data.groupedCities.forEach((element) => {
        let obj = { label: element.id }
        let arr = []
        element.value.forEach((v) => {
          arr.push({ label: v.il, value: v.plaka })
        })
        // arr.sort((a, b) => a.value - b.value)
        obj.options = arr
        options = options.concat(arr)
        groups.push(obj)
      })
      setGroupCities(options)
      // setSelectedLocations({...selectedLocations, tr: options})
    }
  }, [selectedCampaign])

  useEffect(() => {
    if (selectedCampaign) {
      let groups = []
      let options = []
      data.groupedCountries.forEach((element) => {
        let obj = { label: element.id }
        let arr = []
        element.value.forEach((v) => {
          arr.push({ label: v.tr, value: v.a2, en: v.en })
        })
        arr.sort((a, b) => a.value - b.value)
        obj.options = arr
        options = options.concat(arr)
        groups.push(obj)
      })
      setGroupCountries(groups)
      // setGroupCountryOptions(options)
    }
  }, [selectedCampaign])

  const disabledDate = (current, start, end) => {
    return current < moment(start).add(-1, 'day') || current >= moment(end)
  }

  return (
    <>
      {data && (
        <>
          <div>
            <KTCard className='p-4'>
              <label className='fw-bold fs-3 mb-2'>Teklif Detayları Oluşturma</label>
              <div className='separator my-4'></div>
              <div className='fv-row mb-7 row'>
                <div className='row'>
                  <div className='col-12'>
                    <label className='required fw-bold fs-6 mb-2'>Reklam Teklifi Adı</label>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      value={decodeEntities(offer.name)}
                      onChange={(e) => setOffer({ ...offer, name: e.target.value })}
                    ></input>
                  </div>

                  <div className='col-12 mt-4'>
                    <label className='required fw-bold fs-6 mb-2'>Müşteri</label>
                    <select
                      className='form-select'
                      aria-label='Select example'
                      onChange={(e) => {
                        setOffer({ ...offer, advertiserId: e.target.value })
                      }}
                      value={offer.advertiserId}
                      disabled
                    >
                      <option value={''}>Müşteri Seç</option>
                      {data.advertisers.length > 0 &&
                        data.advertisers.map((v, i) => {
                          return (
                            <option key={i} value={v.id}>
                              {decodeEntities(v.name)}
                            </option>
                          )
                        })}
                    </select>
                  </div>

                  <div className='col-12 mt-4'>
                    <label className='required fw-bold fs-6 mb-2'>Kampanya</label>
                    <select
                      className='form-select'
                      aria-label='Select example'
                      value={offer.campaignId}
                      onChange={(e) => {
                        let findCampaign = selectedAdver.campaigns.find(
                          (x) => x.id === parseInt(e.target.value)
                        )
                        setSelectedCampaign(findCampaign)
                        setDatePicker({
                          start: moment(),
                          end: moment(findCampaign.endDate),
                        })
                        setOffer({
                          ...offer,
                          campaignId: e.target.value,
                        })
                      }}
                      disabled
                    >
                      <option value={''}>Kampanya Seç</option>
                      {selectedAdver &&
                        selectedAdver.campaigns?.map((v, i) => {
                          return (
                            <option key={i} value={v.id}>
                              {decodeEntities(v.name)}
                            </option>
                          )
                        })}
                    </select>
                  </div>
                </div>
              </div>
            </KTCard>

            {selectedCampaign && (
              <KTCard className='p-4 mt-4'>
                <label className='fw-bold fs-3 mb-2'>Kreatif Seçimi</label>
                <div className='separator my-4'></div>
                <div className='fv-row mb-7 row'>
                  <div className='col-12'>
                    {selectedAdver && offer.videoEnabled && (
                      <>
                        <label className='required fw-bold fs-6 mb-2'>Video Kreatif</label>
                        <Select
                          isMulti
                          defaultValue={selectedVideoIds}
                          options={selectedAdver.ads?.filter((x) => x.creativeTypeId === 1)
                              .map((v, i) => {
                                return { label: decodeEntities(v.name), value: v.id }
                              })}
                          // styles={mode === 'light' ? {} : customStyles}
                          onChange={(e) => setOffer({ ...offer, videoAdIds: e })}
                          classNamePrefix='select'
                          isDisabled={offer.overlayAdIds.length > 0}
                        />
                      </>
                    )}
                  </div>

                  <div className='col-12 mt-4'>
                    {selectedAdver && offer.overlayEnabled && (
                      <>
                        <label className='required fw-bold fs-6 mb-2'>Overlay Kreatif</label>
                        <Select
                          isMulti
                          defaultValue={selectedOverlayIds}
                          options={selectedAdver.ads?.filter((x) => x.creativeTypeId !== 1)
                              .map((v, i) => {
                                return { label: decodeEntities(v.name), value: v.id }
                              })}
                          // styles={mode === 'light' ? {} : customStyles}
                          onChange={(e) => setOffer({ ...offer, overlayAdIds: e })}
                          classNamePrefix='select'
                          isDisabled={offer.videoAdIds.length > 0}
                        />
                      </>
                    )}
                  </div>
                </div>
              </KTCard>
            )}
          </div>

          {selectedCampaign && (
            <>
              <div className='mt-4'>
                <KTCard className='p-4'>
                  <label className='fw-bold fs-3 mb-2'>Bütçe Detayları</label>
                  <div className='separator my-4'></div>
                  <div className='row'>
                    {offer.videoEnabled && (
                      <>
                        <div
                          className={`col-${
                            offer.videoBudgetDistribution === 'CUSTOM' ? '6' : '12'
                            }`}
                        >
                          <label className='fw-bold'>Bütçe Dağılımı</label>
                          <select
                            className='form-select mt-4'
                            aria-label='Select example'
                            onChange={(e) => {
                              setOffer({
                                ...offer,
                                videoBudgetDistribution:
                                  e.target.value === '' ? null : e.target.value,
                              })
                            }}
                            value={offer.videoBudgetDistribution}
                          >
                            {Object.keys(data.distParams).map((v, i) => {
                              return (
                                <option value={v} key={i}>
                                  {data.distParams[v]}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                        {offer.videoBudgetDistribution === 'CUSTOM' && (
                          <div className='col-6'>
                            <label>Günlük Bütçe</label>
                            <div className='input-group mt-4'>
                              <input
                                type='text'
                                className='form-control'
                                placeholder='1000'
                                aria-label='gunluk butce'
                                aria-describedby='basic-addon2'
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault()
                                  }
                                }}
                                onChange={(e) =>
                                  setOffer({ ...offer, videoBudgetDaily: parseInt(e.target.value) })
                                }
                                value={offer.videoBudgetDaily}
                              />
                              <span className='input-group-text' id='basic-addon2'>
                                ₺
                              </span>
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {offer.overlayEnabled && (
                      <>
                        <div
                          className={`col-${
                            offer.overlayBudgetDistribution === 'CUSTOM' ? '6' : '12'
                            }`}
                        >
                          <label>Bütçe Dağılımı</label>
                          <select
                            className='form-select mt-4'
                            aria-label='Select example'
                            onChange={(e) => {
                              setOffer({
                                ...offer,
                                overlayBudgetDistribution:
                                  e.target.value === '' ? null : e.target.value,
                              })
                            }}
                            value={offer.overlayBudgetDistribution}
                          >
                            {Object.keys(data.distParams).map((v, i) => {
                              return (
                                <option value={v} key={i}>
                                  {data.distParams[v]}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                        {offer.overlayBudgetDistribution === 'CUSTOM' && (
                          <div className='col-6'>
                            <label>Günlük Bütçe</label>
                            <div className='input-group mt-4'>
                              <input
                                type='text'
                                className='form-control'
                                placeholder='1000'
                                aria-label='gunluk butce'
                                aria-describedby='basic-addon2'
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault()
                                  }
                                }}
                                onChange={(e) =>
                                  setOffer({
                                    ...offer,
                                    overlayBudgetDaily: parseInt(e.target.value),
                                  })
                                }
                                value={offer.overlayBudgetDaily}
                              />
                              <span className='input-group-text' id='basic-addon2'>
                                ₺
                              </span>
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    <div className='mt-4'>
                      <label className='fw-bold'>Teklif Dönemi</label>
                      {datePicker && (
                        <RangePicker
                          locale={locale}
                          className='w-100 mt-4'
                          disabledDate={(e) => disabledDate(e, moment(), selectedCampaign.end)}
                          defaultValue={[
                            moment(datePicker.start, 'DD.MM.YYYY'),
                            moment(datePicker.end, 'DD.MM.YYYY'),
                          ]}
                          format={'DD.MM.YYYY'}
                          onChange={(e) => setDatePicker({ start: e[0], end: e[1] })}
                        />
                      )}
                    </div>

                    <div className='separator my-10'></div>
                    <div className='row mt-4'>
                      <div className='col-6 d-flex flex-column'>
                        <label className='mb-4'>Başlangıç Saati</label>
                        <Time
                          time={time.start}
                          onChange={(e) => {
                            setTime({ ...time, start: e })
                          }}
                        />
                      </div>
                      <div className='col-6 d-flex flex-column'>
                        <label className='mb-4'>Bitiş Saati</label>
                        <Time
                          time={time.end}
                          onChange={(e) => {
                            setTime({ ...time, end: e })
                          }}
                        />
                      </div>
                    </div>

                    <div className='separator my-10'></div>

                    {/* Sadece video seçiliyse */}
                    {offer.videoAdIds.length > 0 && (
                      <div className='row mt-4'>
                        <div className='col-6'>
                          <label>Toplam Video Bütçesi</label>
                          <div className='input-group mt-4'>
                            <input
                              type='text'
                              className='form-control'
                              placeholder='1000'
                              aria-label='toplam reklam butcesi'
                              aria-describedby='basic-addon2'
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault()
                                }
                              }}
                              onChange={(e) =>
                                setOffer({ ...offer, videoBudget: parseInt(e.target.value) })
                              }
                              value={offer.videoBudget}
                            />
                            <span className='input-group-text' id='basic-addon2'>
                              ₺
                            </span>
                          </div>
                        </div>
                        <div className='col-6'>
                          <label>Aşım Payı</label>
                          <select
                            className='form-select mt-4'
                            aria-label='Select example'
                            onChange={(e) => {
                              setOffer({
                                ...offer,
                                videoOverload: e.target.value === '' ? null : e.target.value,
                              })
                            }}
                            value={offer.videoOverload}
                          >
                            <option value={''}>Yok</option>
                            <option value={5}>%5</option>
                            <option value={10}>%10</option>
                            <option value={15}>%15</option>
                            <option value={17}>%17</option>
                            <option value={20}>%20</option>
                            <option value={17}>%22</option>
                            <option value={25}>%25</option>
                            <option value={27}>%27</option>
                            <option value={30}>%30</option>
                          </select>
                        </div>
                        <div className='col-12 mt-4'>
                          <div className='row ms-0 me-0'>
                            <div className='col-8'>Gösterim Ücreti</div>
                            <div className='col-2 text-center'>Birim Maliyet</div>
                            <div className='col-2 text-center'>Bütçe</div>
                          </div>

                          <div className='w-100 d-flex flex-column mt-4'>
                            {/* PSS */}
                            <div className='w-100 d-flex align-items-center row'>
                              <div className='d-flex col-8'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={nsPss.pss}
                                  onChange={(e) => {
                                    setOffer({ ...offer, pssEnabled: e.target.checked })
                                    if (!e.target.checked) {
                                      setNsPss({
                                        ...nsPss,
                                        pss: e.target.checked,
                                        pssBudget: 0,
                                        nsBudget: nsPss.ns ? offer.videoBudget : 0,
                                      })
                                      return
                                    }
                                    setNsPss({ ...nsPss, pss: e.target.checked })
                                  }}
                                  id='flexCheckDefault'
                                  disabled={!pssActive}
                                />
                                <label className='form-check-label ms-1' htmlFor='flexCheckDefault'>
                                  PSS
                                </label>
                              </div>
                              <div className=' col-2'>
                                <input
                                  type='number'
                                  className='form-control form-control-solid'
                                  placeholder='Maliyet'
                                  step={0.001}
                                  disabled={!nsPss.pss || !pssActive}
                                  value={nsPss.pssPrice}
                                  onBlur={() => nsPssFocus('pss')}
                                  onChange={(e) => {
                                    if (e.target.validity !== '' && parseFloat(e.target.value) <= 1)
                                      setNsPss({ ...nsPss, pssPrice: parseFloat(e.target.value) })
                                    else setNsPss({ ...nsPss, pssPrice: 0 })
                                  }}
                                ></input>
                              </div>
                              <div className=' col-2'>
                                <input
                                  type='text'
                                  className='form-control  form-control-solid'
                                  placeholder='0'
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault()
                                    }
                                  }}
                                  value={nsPss.pssBudget}
                                  disabled={!nsPss.pss || !pssActive}
                                  onBlur={() => nsPssFocus('pss')}
                                  onChange={(e) => nsPssChange('pss', e.target.value)}
                                ></input>
                              </div>
                            </div>

                            {/* NS */}
                            <div className='w-100 d-flex align-items-center mt-2 row'>
                              <div className='d-flex col-8'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  onChange={(e) => {
                                    setOffer({ ...offer, nsEnabled: e.target.checked })
                                    if (!e.target.checked) {
                                      setNsPss({
                                        ...nsPss,
                                        ns: e.target.checked,
                                        nsBudget: 0,
                                        pssBudget: nsPss.pss ? offer.videoBudget : 0,
                                      })
                                      return
                                    }
                                    setNsPss({ ...nsPss, ns: e.target.checked })
                                  }}
                                  checked={nsPss.ns}
                                  id='nsCheck'
                                />
                                <label className='form-check-label ms-1' htmlFor='flexCheckDefault'>
                                  NS
                                </label>
                              </div>
                              <div className='col-2'>
                                <input
                                  type='number'
                                  className='form-control form-control-solid'
                                  placeholder='Maliyet'
                                  step={0.001}
                                  // onKeyPress={(event) => {
                                  //   if (!/[0-9]/.test(event.key)) {
                                  //     event.preventDefault()
                                  //   }
                                  // }}
                                  value={nsPss.nsPrice}
                                  disabled={!offer.nsEnabled}
                                  onBlur={() => nsPssFocus('ns')}
                                  onChange={(e) => {
                                    if (e.target.value !== '' && parseFloat(e.target.value) <= 1)
                                      setNsPss({ ...nsPss, nsPrice: parseFloat(e.target.value) })
                                    else setNsPss({ ...nsPss, nsPrice: 0 })
                                  }}
                                ></input>
                              </div>
                              <div className='col-2'>
                                <input
                                  type='text'
                                  className='form-control  form-control-solid'
                                  placeholder='0'
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault()
                                    }
                                  }}
                                  value={nsPss.nsBudget}
                                  onBlur={() => nsPssFocus('ns')}
                                  disabled={!nsPss.ns}
                                  onChange={(e) => nsPssChange('ns', e.target.value)}
                                ></input>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='col-12 mt-4'>
                          <label>Planlanan İzlenme</label>
                          <input
                            type='text'
                            className='form-control form-control-lg mt-4 form-control-solid'
                            // disabled
                            value={offer.videoTargetedImpression}
                          ></input>
                        </div>
                      </div>
                    )}

                    {/* Sadece overlay seçiliyse */}
                    {offer.overlayAdIds.length > 0 && (
                      <div className='row mt-4'>
                        <div className='col-6'>
                          <label>Toplam Ov. Bütçesi</label>
                          <div className='input-group mt-4'>
                            <input
                              type='text'
                              className='form-control'
                              placeholder='1000'
                              aria-label='toplam reklam butcesi'
                              aria-describedby='basic-addon2'
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault()
                                }
                              }}
                              onChange={(e) =>
                                setOffer({ ...offer, overlayBudget: parseInt(e.target.value) })
                              }
                              value={offer.overlayBudget}
                            />
                            <span className='input-group-text' id='basic-addon2'>
                              ₺
                            </span>
                          </div>
                        </div>
                        <div className='col-6'>
                          <label>Aşım Payı</label>
                          <select
                            className='form-select mt-4'
                            aria-label='Select example'
                            onChange={(e) => {
                              setOffer({
                                ...offer,
                                overlayOverload: e.target.value === '' ? null : e.target.value,
                              })
                            }}
                          >
                            <option value={''}>Yok</option>
                            <option value={5}>%5</option>
                            <option value={10}>%10</option>
                            <option value={15}>%15</option>
                            <option value={17}>%17</option>
                            <option value={20}>%20</option>
                            <option value={17}>%22</option>
                            <option value={25}>%25</option>
                            <option value={27}>%27</option>
                            <option value={30}>%30</option>
                          </select>
                        </div>

                        <div className='col-12 mt-4'>
                          <label className='form-check-label ms-1' htmlFor='flexCheckDefault'>
                            CPM (Overlay Gösterim Ücreti)
                          </label>
                          <div className='input-group mt-2'>
                            <input
                              type='text'
                              className='form-control'
                              placeholder='1000'
                              aria-label='gunluk butce'
                              aria-describedby='basic-addon2'
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault()
                                }
                              }}
                              onChange={(e) => {
                                if (offer.overlayBudget > 0 && parseInt(e.target.value) > 0) {
                                  setOffer({
                                    ...offer,
                                    overlayTargetedImpression: Math.floor(
                                      (offer.overlayBudget / parseInt(e.target.value)) * 1000
                                    ),
                                    cpm: parseInt(e.target.value),
                                  })
                                } else {
                                  setOffer({
                                    ...offer,
                                    overlayTargetedImpression: 0,
                                    cpm: parseInt(e.target.value),
                                  })
                                }
                              }}
                              value={offer.cpm}
                            />
                            <span className='input-group-text' id='basic-addon2'>
                              ₺
                            </span>
                          </div>
                        </div>

                        <div className='col-12 mt-4'>
                          <label>Planlanan İzlenme</label>
                          <input
                            type='text'
                            className='form-control form-control-lg mt-2 form-control-solid'
                            disabled
                            value={offer.overlayTargetedImpression}
                          ></input>
                        </div>
                      </div>
                    )}
                  </div>
                </KTCard>
              </div>

              <div className='mt-4'>
                <KTCard className='p-4'>
                  <label className='fw-bold fs-3 mb-2'>Hedef Kitle Detayları</label>
                  <div className='separator my-4'></div>
                  <div className='row'>
                    {/* Cinsiyet */}
                    {/* <div className='row d-flex flex-row mt-4'>
                      <label className='mb-4'>Cinsiyet</label>
                      {Object.keys(data.genderParams).map((v, i) => {
                        return (
                          <div className='col' key={i}>
                            <input
                              type='checkbox'
                              className='form-check-input'
                              name={`gender-${data.genderParams[v]}`}
                              value={v}
                              checked={selectedGenders.includes(v)}
                              onChange={(e) => {
                                let genders = [...selectedGenders]
                                if (e.target.checked) {
                                  genders.push(e.target.value)
                                } else {
                                  genders = genders.filter((x) => x !== e.target.value)
                                }
                                setSelectedGenders(genders)
                              }}
                            />
                            <label className='form-check-label ms-1' htmlFor='flexCheckDefault'>
                              {data.genderParams[v]}
                            </label>
                          </div>
                        )
                      })}
                    </div>

                    <div className='separator my-5'></div> */}
                    {/* Yaş */}
                    {/* <div className='row mt-4'>
                      <IonRangeSlider
                        values={data.ageParams}
                        type='double'
                        grid
                        onChange={(e) => {
                          setSelectedAge(`${e.from_value}-${e.to_value}`)
                        }}
                        from={data.ageParams.findIndex(
                          (x) => x === parseInt(selectedAge.split('-')[0])
                        )}
                        to={data.ageParams.findIndex(
                          (x) => x === parseInt(selectedAge.split('-')[1])
                        )}
                      />
                    </div>

                    <div className='separator my-5'></div> */}
                    {/* Cihaz */}
                    <div className='row mt-4'>
                      <label className='mb-4'>Cihaz</label>
                      <Select
                        isMulti
                        closeMenuOnSelect={false}
                        options={Object.keys(data.deviceParams).map((v) => {
                          return { label: data.deviceParams[v], value: v }
                        })}
                        defaultValue={selectedDevices}
                        // styles={mode === 'light' ? {} : customStyles}
                        onChange={setSelectedDevices}
                        classNamePrefix='select'
                      />
                    </div>

                    <div className='separator my-5'></div>
                    {/* Operatör */}
                    <div className='row mt-4'>
                      <label className='mb-4'>Operatör</label>
                      <Select
                        isMulti
                        closeMenuOnSelect={false}
                        options={Object.keys(data.operatorParams).map((v) => {
                          return { label: data.operatorParams[v], value: v }
                        })}
                        defaultValue={selectedOperators}
                        // styles={mode === 'light' ? {} : customStyles}
                        onChange={setSelectedOperators}
                        classNamePrefix='select'
                      />
                    </div>

                    <div className='separator my-5'></div>
                    {/* Video Frekans */}
                    {offer.videoAdIds.length > 0 && (
                      <div className='row mt-4'>
                        <label>Frekans Limitleme (Video)</label>
                        <div className='mt-2'>
                          {videoFreq.map((v, i) => {
                            return (
                              <div
                                key={i}
                                className='d-flex  ps-4 pt-2 pe-4 pb-2  align-items-center justify-content-between'
                              >
                                <span>
                                  {data.freqParams.find((x) => x.id === v.label).name} - {v.value}
                                </span>
                                <button
                                  className='btn btn-danger'
                                  onClick={(e) => {
                                    let tempFreq = [...videoFreq]
                                    tempFreq = tempFreq.filter((x) => x.label !== v.label)
                                    setVideoFreq(tempFreq)
                                  }}
                                >
                                  sil
                                </button>
                              </div>
                            )
                          })}
                        </div>
                        <div className='w-100 mt-4'>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault()
                              let tempFreq = [...videoFreq]
                              if (
                                !tempFreq.find((x) => x.label === e.target[0].value) &&
                                e.target[1].value !== ''
                              ) {
                                tempFreq.push({ label: e.target[0].value, value: e.target[1].value })
                              }
                              setVideoFreq(tempFreq)
                            }}
                          >
                            <div className='row ms-0 me-0'>
                              <select
                                className='form-select col-6'
                                style={{ width: '50%' }}
                                aria-label='Select example'
                              >
                                {data.freqParams.map((v, i) => {
                                  return (
                                    <option key={i} value={v.id}>
                                      {v.name}
                                    </option>
                                  )
                                })}
                              </select>
                              <div className='col-3'>
                                <input
                                  type='text'
                                  className='w-100 form-control form-control-solid '
                                  placeholder='5'
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault()
                                    }
                                  }}
                                ></input>
                              </div>
                              <button className='col-3 btn btn-primary' type='submit'>
                                ekle
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}

                    {/* Overlay Frekans */}
                    {offer.overlayAdIds.length > 0 && (
                      <div className='row mt-4'>
                        <label>Frekans Limitleme (Overlay)</label>
                        <div className='mt-2'>
                          {overlayFreq.map((v, i) => {
                            return (
                              <div
                                key={i}
                                className='d-flex ps-4 pt-2 pe-4 pb-2 border rounded-pill align-items-center justify-content-between'
                              >
                                <span>
                                  {data.freqParams.find((x) => x.id === v.label).name} - {v.value}
                                </span>
                                <button
                                  className='btn btn-danger'
                                  onClick={(e) => {
                                    let tempFreq = [...overlayFreq]
                                    tempFreq = tempFreq.filter((x) => x.label !== v.label)
                                    setOverlayFreq(tempFreq)
                                  }}
                                >
                                  sil
                                </button>
                              </div>
                            )
                          })}
                        </div>
                        <div className='w-100 mt-4'>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault()
                              let tempFreq = [...overlayFreq]
                              if (
                                !tempFreq.find((x) => x.label === e.target[0].value) &&
                                e.target[1].value !== ''
                              ) {
                                tempFreq.push({ label: e.target[0].value, value: e.target[1].value })
                              }
                              setOverlayFreq(tempFreq)
                            }}
                          >
                            <div className='row ms-0 me-0'>
                              <select
                                className='form-select col-6'
                                style={{ width: '50%' }}
                                aria-label='Select example'
                              >
                                {data.freqParams.map((v, i) => {
                                  return (
                                    <option key={i} value={v.id}>
                                      {v.name}
                                    </option>
                                  )
                                })}
                              </select>
                              <div className='col-3'>
                                <input
                                  type='text'
                                  className='form-control form-control-solid w-100'
                                  placeholder='5'
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault()
                                    }
                                  }}
                                ></input>
                              </div>
                              <button className='col-3 btn btn-primary' type='submit'>
                                ekle
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}

                    {/* Lokasyon */}
                    <div className='separator my-5'></div>
                    <div className='row mt-4'>
                      <label>Lokasyon</label>
                      <div className='col-12'>
                        <ReactSelect
                          styles={customStyles2}
                          isMulti
                          closeMenuOnSelect={false}
                          options={groupCities}
                          // formatGroupLabel={formatGroupLabel}
                          value={locationsRef.current.tr}
                          onChange={(e) => {
                            let temp = { ...selectedLocations }

                            const results1 = temp.tr.filter(
                              ({ value: id1 }) => !e.some(({ value: id2 }) => id2 === id1)
                            )

                            const results2 = e.filter(
                              ({ value: id1 }) => !temp.tr.some(({ value: id2 }) => id2 === id1)
                            )

                            results1.forEach((va) => {
                              let a = polygonSeries.data.find(
                                (x) =>
                                  x.name.toLocaleLowerCase('TR') ===
                                  va.label.toLocaleLowerCase('TR')
                              )
                              if (a) {
                                let poly = polygonSeries.getPolygonById(`${a.id}`)
                                poly.isActive = true
                              }
                            })

                            results2.forEach((va) => {
                              let a = polygonSeries.data.find(
                                (x) =>
                                  x.name.toLocaleLowerCase('TR') ===
                                  va.label.toLocaleLowerCase('TR')
                              )
                              if (a) {
                                let poly = polygonSeries.getPolygonById(`${a.id}`)
                                poly.isActive = false
                              }
                            })

                            temp.tr = e
                            setSelectedLocations(temp)
                          }}
                        />

                        <div
                          id='chartdiv'
                          className='mt-2'
                          style={{ width: '100%', height: '300px' }}
                        ></div>
                      </div>

                      <div className='col-12 mt-4'>
                        <ReactSelect
                          styles={customStyles2}
                          isMulti
                          closeMenuOnSelect={false}
                          options={groupCountries}
                          formatGroupLabel={formatGroupLabel}
                          value={locationsRef.current.world}
                          onChange={(e) => {
                            let temp = { ...selectedLocations }

                            const results1 = temp.world.filter(
                              ({ value: id1 }) => !e.some(({ value: id2 }) => id2 === id1)
                            )

                            const results2 = e.filter(
                              ({ value: id1 }) => !temp.world.some(({ value: id2 }) => id2 === id1)
                            )

                            results1.forEach((va) => {
                              let a = polygonSeries2.data.find((x) => x.id === va.value)
                              if (a) {
                                let poly = polygonSeries2.getPolygonById(`${a.id}`)
                                poly.isActive = false
                              }
                            })

                            results2.forEach((va) => {
                              let a = polygonSeries2.data.find((x) => x.id === va.value)
                              if (a) {
                                let poly = polygonSeries2.getPolygonById(`${a.id}`)
                                poly.isActive = true
                              }
                            })

                            temp.world = e
                            setSelectedLocations(temp)
                          }}
                        />
                        <div
                          id='chartdiv2'
                          className='mt-2'
                          style={{ width: '100%', height: '300px' }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </KTCard>
              </div>

              <div className='mt-4'>
                <KTCard className='p-4'>
                  <label className='fw-bold fs-3 mb-2'>Program Seçimi</label>
                  <div className='separator my-4'></div>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='row mt-4'>
                        <div className='col-6'>
                          <label>Günlük Bölüm</label>
                          <div className='d-flex flex-column'>
                            <div className='w-100 mt-2'>
                              <input
                                type='checkbox'
                                className='form-check-input'
                                name='partOfDay1'
                                checked={eventsQuery.partOfDay1}
                                onChange={eventsQueryOnChange}
                              />
                              <label className='ms-2'>06:00 - 10:00</label>
                            </div>
                            <div className='w-100 mt-2'>
                              <input
                                type='checkbox'
                                className='form-check-input'
                                name='partOfDay2'
                                checked={eventsQuery.partOfDay2}
                                onChange={eventsQueryOnChange}
                              />
                              <label className='ms-2'>10:00 - 16:00</label>
                            </div>
                            <div className='w-100 mt-2'>
                              <input
                                type='checkbox'
                                className='form-check-input'
                                name='partOfDay3'
                                checked={eventsQuery.partOfDay3}
                                onChange={eventsQueryOnChange}
                              />
                              <label className='ms-2'>16:00 - 18:00</label>
                            </div>
                            <div className='w-100 mt-2'>
                              <input
                                type='checkbox'
                                className='form-check-input'
                                name='partOfDay4'
                                checked={eventsQuery.partOfDay4}
                                onChange={eventsQueryOnChange}
                              />
                              <label className='ms-2'>18:00 - 00:00</label>
                            </div>
                            <div className='w-100 mt-2'>
                              <input
                                type='checkbox'
                                className='form-check-input'
                                name='partOfDay5'
                                checked={eventsQuery.partOfDay5}
                                onChange={eventsQueryOnChange}
                              />
                              <label className='ms-2'>00:00 - 02:00</label>
                            </div>
                            <div className='w-100 mt-2'>
                              <input
                                type='checkbox'
                                className='form-check-input'
                                name='partOfDay6'
                                checked={eventsQuery.partOfDay6}
                                onChange={eventsQueryOnChange}
                              />
                              <label className='ms-2'>02:00 - 06:00</label>
                            </div>
                          </div>
                        </div>
                        <div className='col-6 d-flex flex-column'>
                          <label>Haftalık Bölüm</label>
                          <div className='d-flex flex-column justify-content-between h-100'>
                            <div>
                              <div className='w-100 mt-2'>
                                <input
                                  type='checkbox'
                                  className='form-check-input'
                                  name='partOfWeek1'
                                  checked={eventsQuery.partOfWeek1}
                                  onChange={eventsQueryOnChange}
                                />
                                <label className='ms-2'>Haftasonu</label>
                              </div>
                              <div className='w-100 mt-2'>
                                <input
                                  type='checkbox'
                                  className='form-check-input'
                                  name='partOfWeek2'
                                  checked={eventsQuery.partOfWeek2}
                                  onChange={eventsQueryOnChange}
                                />
                                <label className='ms-2'>Haftaiçi</label>
                              </div>
                            </div>
                            <button className='btn btn-primary' onClick={() => getAgencyEvents()}>
                              Uygula
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-12 mt-4'>
                      <label className='fw-bold fs-6'>Yayınlar</label>
                      <div className='w-100 mt-4'>
                        <div className='accordion accordion-flush' id='accordionFlushExample'>
                          {events.map((v, i) => {
                            let tempEvents = sEventsRef.current
                            const evIndex = tempEvents.findIndex((x) => x.id === v.id)
                            let ev = tempEvents[evIndex]
                            let channelChecked = selectedEvents.findIndex((x) => x.id === v.id) > -1
                            return (
                              <div key={i} className='accordion-item'>
                                <h2
                                  className='accordion-header d-flex align-items-center'
                                  id='flush-headingOne'
                                >
                                  <div style={{ width: '40px' }}>
                                    <input
                                      type='checkbox'
                                      className='form-check-input'
                                      name={v.id}
                                      checked={channelChecked}
                                      onChange={selectEvent}
                                    />
                                  </div>
                                  <button
                                    className='accordion-button collapsed'
                                    type='button'
                                    data-bs-toggle='collapse'
                                    data-bs-target={`#flush-collapse${v.id}`}
                                    aria-expanded='false'
                                    aria-controls={`flush-collapse${v.id}`}
                                  >
                                    <div className='d-flex align-items-center w-100 '>
                                      <span className='ms-2 w-25'>
                                        <img src={v.icon} height={32} alt='' /> {v.name}
                                      </span>
                                      <div className='d-flex flex-column justify-content-center'>
                                        <span>{v.programs.length} Program</span>
                                        {ev && channelChecked && offer.videoEnabled && (
                                          <span>
                                            Toplam Video Bütçesi :{' '}
                                            {ev.videoBudget !== ''
                                              ? `${ev.videoBudget}₺ /
                                      ${((ev.videoBudget * 100) / offer.videoBudget).toFixed(2)}%`
                                              : 'AUTO'}
                                          </span>
                                        )}
                                        {ev && channelChecked && offer.overlayEnabled && (
                                          <span>
                                            Toplam Overlay Bütçesi :{' '}
                                            {ev?.overlayBudget !== ''
                                              ? `${ev?.overlayBudget}₺ /
                                      ${((ev?.overlayBudget * 100) / offer.overlayBudget).toFixed(
                                                2
                                              )}%`
                                              : 'AUTO'}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </button>
                                </h2>
                                <div
                                  id={`flush-collapse${v.id}`}
                                  className='accordion-collapse collapse'
                                  aria-labelledby='flush-headingOne'
                                  data-bs-parent='#accordionFlushExample'
                                >
                                  <div className='accordion-body'>
                                    <div className='w-100 d-flex flex-column mt-4'>
                                      {/* Video */}
                                      {offer.videoEnabled && (<div className='w-100 d-flex align-items-center'>
                                        <div
                                          className='d-flex'
                                          style={{ flex: '1', minWidth: '50px' }}
                                        >
                                          {/* <input
                                            className='form-check-input'
                                            type='checkbox'
                                            checked={ev?.videoEnabled}
                                            disabled
                                            onChange={(e) => {
                                              let temp = [...selectedEvents]
                                              let evIndex = temp.findIndex((x) => x.id === v.id)
                                              temp[evIndex].videoEnabled = e.target.checked
                                              setSelectedEvents(temp)
                                            }}
                                            id='videoCheck'
                                          /> */}
                                          <label
                                            className='form-check-label ms-1'
                                            htmlFor='flexCheckDefault'
                                          >
                                            Video
                                          </label>
                                        </div>
                                        <div className='ms-2'>
                                          <input
                                            type='text'
                                            className='form-control  form-control-solid'
                                            placeholder='AUTO'
                                            onKeyPress={(event) => {
                                              if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault()
                                              }
                                            }}
                                            value={ev?.videoBudget}
                                            disabled={!offer.videoEnabled}
                                            onChange={(e) => {
                                              let temp = [...selectedEvents]
                                              temp[evIndex].videoBudget = e.target.value
                                              setSelectedEvents(temp)
                                            }}
                                          ></input>
                                        </div>
                                      </div>)}
                                      {/* Overlay */}
                                      {offer.overlayEnabled && (<div className='w-100 d-flex align-items-center mt-2'>
                                        <div
                                          className='d-flex'
                                          style={{ flex: '1', minWidth: '50px' }}
                                        >
                                          {/* <input
                                            className='form-check-input'
                                            type='checkbox'
                                            checked={ev?.overlayEnabled}
                                            disabled
                                            onChange={(e) => {
                                              let temp = [...selectedEvents]
                                              let evIndex = temp.findIndex((x) => x.id === v.id)
                                              temp[evIndex].overlayEnabled = e.target.checked
                                              setSelectedEvents(temp)
                                            }}
                                            id='overlayCheck'
                                          /> */}
                                          <label
                                            className='form-check-label ms-1'
                                            htmlFor='flexCheckDefault'
                                          >
                                            Overlay
                                          </label>
                                        </div>
                                        <div className='ms-2'>
                                          <input
                                            type='text'
                                            className='form-control  form-control-solid'
                                            placeholder='AUTO'
                                            onKeyPress={(event) => {
                                              if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault()
                                              }
                                            }}
                                            value={ev?.overlayBudget}
                                            disabled={!offer.overlayEnabled}
                                            onChange={(e) => {
                                              let temp = [...selectedEvents]
                                              temp[evIndex].overlayBudget = e.target.value
                                              setSelectedEvents(temp)
                                            }}
                                          ></input>
                                        </div>
                                      </div>)}
                                    </div>
                                    <div className='w-100 mt-4'>
                                      <div className='row'>
                                        <div className='col-8'>
                                          {ev && (
                                            <div className='d-flex align-items-center'>
                                              <input
                                                className='form-check-input'
                                                type='checkbox'
                                                checked={
                                                  sEventsRef.current[evIndex].allProgramsSelected
                                                }
                                                id='eventCheck'
                                                onChange={(e) => {
                                                  tempEvents[evIndex].allProgramsSelected =
                                                    e.target.checked
                                                  setSelectedEvents([...tempEvents])
                                                }}
                                              />
                                              <label className='ms-4'>Tüm Programlar</label>
                                            </div>
                                          )}
                                          {ev && (
                                            <BootstrapTable
                                              data={v.programs}
                                              striped={true}
                                              hover={true}
                                            >
                                              <TableHeaderColumn
                                                isKey={true}
                                                dataField='id'
                                                dataAlign='center'
                                                dataSort={true}
                                                dataFormat={(cell, row) => {
                                                  return (
                                                    <input
                                                      className='form-check-input'
                                                      type='checkbox'
                                                      disabled={
                                                        sEventsRef.current[evIndex]
                                                          .allProgramsSelected
                                                      }
                                                      checked={sEventsRef.current[
                                                        evIndex
                                                      ].programs.includes(cell)}
                                                      id='eventCheck'
                                                      onClick={() => {
                                                        setSelectedEvent(row)
                                                      }}
                                                      onChange={(e) => {
                                                        if (e.target.checked) {
                                                          if (
                                                            !sEventsRef.current[
                                                              evIndex
                                                            ].programs.find((x) => x === cell)
                                                          ) {
                                                            tempEvents[evIndex].addedPrograms.push(
                                                              cell
                                                            )
                                                          }
                                                          if (
                                                            sEventsRef.current[
                                                              evIndex
                                                            ].removedPrograms.find(
                                                              (x) => x === cell
                                                            )
                                                          ) {
                                                            tempEvents[evIndex].removedPrograms =
                                                              tempEvents[
                                                                evIndex
                                                              ].removedPrograms.filter(
                                                                (x) => x !== cell
                                                              )
                                                          }

                                                          tempEvents[evIndex].programs.push(cell)
                                                        } else {
                                                          if (
                                                            sEventsRef.current[
                                                              evIndex
                                                            ].addedPrograms.find((x) => x === cell)
                                                          ) {
                                                            tempEvents[evIndex].addedPrograms =
                                                              tempEvents[
                                                                evIndex
                                                              ].addedPrograms.filter(
                                                                (x) => x !== cell
                                                              )
                                                          }

                                                          if (
                                                            sEventsRef.current[
                                                              evIndex
                                                            ].programs.find((x) => x === cell)
                                                          ) {
                                                            tempEvents[
                                                              evIndex
                                                            ].removedPrograms.push(cell)
                                                          }

                                                          tempEvents[evIndex].programs = tempEvents[
                                                            evIndex
                                                          ].programs.filter((x) => x !== cell)
                                                        }
                                                        setSelectedEvents([...tempEvents])
                                                      }}
                                                    />
                                                  )
                                                }}
                                              >
                                                Check
                                              </TableHeaderColumn>
                                              <TableHeaderColumn
                                                dataField='title'
                                                dataAlign='center'
                                                dataSort={true}
                                                dataFormat={(cell, row) => {
                                                  return (
                                                    <span
                                                      onClick={() => {
                                                        setSelectedEvent(row)
                                                      }}
                                                    >
                                                      {decodeEntities(cell)}
                                                    </span>
                                                  )
                                                }}
                                              >
                                                Program
                                              </TableHeaderColumn>
                                              <TableHeaderColumn
                                                dataField='events'
                                                dataAlign='center'
                                                dataFormat={(cell, row) => {
                                                  return (
                                                    <span
                                                      onClick={() => {
                                                        setSelectedEvent(row)
                                                      }}
                                                    >
                                                      {cell.length}
                                                    </span>
                                                  )
                                                }}
                                              >
                                                Gösterim
                                              </TableHeaderColumn>
                                              <TableHeaderColumn
                                                dataField='pssEnabled'
                                                dataAlign='center'
                                                dataFormat={(cell, row) => {
                                                  return cell ? (
                                                    <i
                                                      className='bi bi-check-circle fs-2x'
                                                      onClick={() => {
                                                        setSelectedEvent(row)
                                                      }}
                                                    ></i>
                                                  ) : (
                                                      <i
                                                        className='bi bi-x-circle fs-2x'
                                                        onClick={() => {
                                                          setSelectedEvent(row)
                                                        }}
                                                      ></i>
                                                    )
                                                }}
                                              >
                                                PSS
                                              </TableHeaderColumn>
                                            </BootstrapTable>
                                          )}
                                        </div>
                                        <div className='col-4'>
                                          {selectedEvent && (
                                            <>
                                              <label className=' fw-bold fs-6'>
                                                {selectedEvent.title}
                                              </label>
                                              <BootstrapTable
                                                data={selectedEvent.events}
                                                striped={true}
                                                hover={true}
                                              >
                                                <TableHeaderColumn
                                                  isKey={true}
                                                  dataField='id'
                                                  dataAlign='center'
                                                  dataFormat={(cell, row) => {
                                                    return moment(cell.startDate).format('DD MMMM')
                                                  }}
                                                >
                                                  Yayın Tarihi
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                  dataField='startEnd'
                                                  dataFormat={(cell, row) => {
                                                    return (
                                                      selectedEvent.events[0].start +
                                                      ' - ' +
                                                      selectedEvent.events[
                                                        selectedEvent.events.length - 1
                                                      ].end
                                                    )
                                                  }}
                                                >
                                                  Yayın Saati
                                                </TableHeaderColumn>
                                              </BootstrapTable>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </KTCard>
              </div>

              <div className='mt-4'>
                <KTCard className='p-4'>
                  <label className='fw-bold fs-3 mb-2'>Teklifi Gönder</label>
                  <div className='separator my-4'></div>
                  <button type='button' className='btn btn-lg btn-danger' onClick={handleSubmit}>
                    Gönder
                  </button>
                </KTCard>
              </div>
            </>
          )}

          {summary && <SummaryModal showModal={setSummary} offer={offer} />}
        </>
      )}
    </>
  )
}

export { OfferEdit }
