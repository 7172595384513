import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4lang_tr_TR from '@amcharts/amcharts4/lang/tr_TR'
import am4lang_en_US from '@amcharts/amcharts4/lang/en_US'
import * as am4maps from '@amcharts/amcharts4/maps'
import am4geodata_turkeyLow from '@amcharts/amcharts4-geodata/turkeyLow'
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow'

const OVERLAY_COLOR = '#9DC681'
const PSS_COLOR = '#FEDD4B'
const NS_COLOR = '#85C5E3'
const VIEW_COLOR = '#FDD82F'
const CLICK_COLOR = '#76BEDF'

am4core.options.onlyShowOnViewport = true
am4core.options.queue = true
am4core.options.minPolylineStep = 5

export const getChart = (id) => {
  return am4core.registry.baseSprites.find((c) => c.htmlContainer.id === id)
}

export const destroyChart = (chart) => {
  chart.dispose()
  chart = null
}
export const destroyChartIfExist = (element) => {
  const chart = getChart(element)
  if (chart) {
    destroyChart(chart)
  }
}
export const createChart = (element, data, chartType) => {
  destroyChartIfExist(element)
  let chart
  if (chartType === 'map') {
    chart = am4core.create(element, am4maps.MapChart)
    chart.projection = new am4maps.projections.Miller()
  } else if (chartType === 'pie') {
    chart = am4core.create(element, am4charts.PieChart)
    chart.data = data
  } else {
    chart = am4core.create(element, am4charts.XYChart)
    chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd HH:mm'
    chart.data = data
  }

  chart.language.locale = am4lang_tr_TR
  chart.numberFormatter.language = new am4core.Language()
  chart.numberFormatter.language.locale = am4lang_en_US
  // chart.numberFormatter.numberFormat = "#a"; // to show compact numbers

  return chart
}

export const createSeries = (chart, field, seriesType) => {
  let series

  if (seriesType === 'column') {
    series = chart.series.push(new am4charts.ColumnSeries())
    series.sequencedInterpolation = true
    series.stacked = true
    series.columns.template.width = am4core.percent(60)
    series.columns.template.tooltipText = '[bold]{name}: {valueY}'

    series.groupFields.valueY = 'sum'
    series.dataFields.dateX = 'date'
    series.dataFields.valueY = field
  } else if (seriesType === 'map') {
    series = chart.series.push(new am4maps.MapPolygonSeries())
    series.exclude = ['AQ']
    series.heatRules.push({
      property: 'fill',
      target: series.mapPolygons.template,
      min: chart.colors.getIndex(1).brighten(1),
      max: chart.colors.getIndex(1).brighten(-0.3),
    })
    series.useGeodata = true
  } else if (seriesType === 'pie') {
    series = chart.series.push(new am4charts.PieSeries())
    series.dataFields.value = field
    series.slices.template.stroke = am4core.color('#fff')
    series.slices.template.strokeWidth = 2
    series.slices.template.strokeOpacity = 1
    series.hiddenState.properties.opacity = 1
    series.hiddenState.properties.endAngle = -90
    series.hiddenState.properties.startAngle = -90
    series.labels.template.fontSize = 13
  } else {
    series = chart.series.push(new am4charts.LineSeries())
    series.tooltipText = '{valueY}'
    series.tooltip.pointerOrientation = 'vertical'
    series.tooltip.background.fillOpacity = 0.5
    series.strokeWidth = 3
    series.groupFields.valueY = 'sum'
    series.dataFields.dateX = 'date'
    series.dataFields.valueY = field
  }
  return series
}
export const createDateAxis = (chart) => {
  // chart.xAxes = []
  const dateAxis = chart.xAxes.push(new am4charts.DateAxis())
  dateAxis.renderer.minGridDistance = 50

  return dateAxis
}
export const createValueAxis = (chart) => {
  // chart.yAxes = []
  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
  valueAxis.min = 0
  return valueAxis
}
export const createLegend = (chart) => {
  chart.legend = new am4charts.Legend()
}

export const createCursor = (chart, dateAxis) => {
  chart.cursor = new am4charts.XYCursor()
  chart.cursor.xAxis = dateAxis
}

export const createSimpleScrollbar = (chart) => {
  chart.scrollbarX = new am4core.Scrollbar()
}

export const createDetailedScrollbar = (chart, series) => {
  chart.scrollbarX = new am4charts.XYChartScrollbar()
  chart.scrollbarX.parent = chart.topAxesContainer
  chart.scrollbarX.series.push(series)
}

export const createExport = (chart) => {
  chart.exporting.menu = new am4core.ExportMenu()
  chart.exporting.menu.align = 'right'
  chart.exporting.menu.verticalAlign = 'bottom'
}

export const createTitle = (chart, title) => {
  const title1 = chart.titles.create()
  title1.text = title
  title1.fontSize = 17
  title1.marginBottom = 15
  title1.fontWeight = 'bold'
}

export const createGroupedData = (dateAxis) => {
  dateAxis.groupData = true
  dateAxis.groupCount = 100
  dateAxis.groupIntervals.setAll([
    {timeUnit: 'minute', count: 1},
    {timeUnit: 'minute', count: 15},
    {timeUnit: 'minute', count: 30},
    {timeUnit: 'hour', count: 1},
    {timeUnit: 'day', count: 1},
    {timeUnit: 'week', count: 1},
    {timeUnit: 'month', count: 1},
    {timeUnit: 'year', count: 1},
  ])
}

export const simultaneousAdViewerGraph = (element, data) => {
  const chart = createChart(element, data, 'xy')
  const dateAxis = createDateAxis(chart)
  dateAxis.keepSelection = true

  const valueAxis = createValueAxis(chart)

  chart.colors.list = [
    am4core.color(NS_COLOR),
    am4core.color(PSS_COLOR),
    am4core.color(OVERLAY_COLOR),
  ]

  function createAxisAndSeries(field, name) {
    const series = createSeries(chart, field, 'line')
    series.yAxis = valueAxis
    series.name = name

    valueAxis.renderer.line.strokeOpacity = 1
    valueAxis.renderer.line.strokeWidth = 2
    valueAxis.renderer.line.stroke = series.stroke
    valueAxis.renderer.labels.template.fill = series.stroke
  }

  createAxisAndSeries('normal', 'Kuşak')
  createAxisAndSeries('pss', 'Pss')
  createAxisAndSeries('overlay', 'Overlay')

  createLegend(chart)
  createCursor(chart)
  chart.scrollbarX = new am4core.Scrollbar()
  createExport(chart)
  createTitle(chart, 'Anlık İzlenme Sayısı')
}

export const simultaneousViewerGraph = (element, data, opts) => {
  const chart = createChart(element, data, 'xy')
  const dateAxis = createDateAxis(chart)
  const valueAxis = createValueAxis(chart)
  dateAxis.keepSelection = true
  createGroupedData(dateAxis)
  dateAxis.groupCount = 4000
  dateAxis.groupIntervals.setAll([
    {timeUnit: 'minute', count: 1},
    {timeUnit: 'minute', count: 5},
    {timeUnit: 'minute', count: 10},
  ])

  const series = createSeries(chart, 'value', 'line')
  series.groupFields.valueY = 'average'
  series.fillOpacity = 1

  createCursor(chart)
  chart.cursor.behavior = 'panXY'
  chart.cursor.snapToSeries = series

  createDetailedScrollbar(chart, series)
  createExport(chart)
  return chart
}

export const reachChart = (element, data) => {
  const chart = createChart(element, data, 'xy')
  const dateAxis = createDateAxis(chart)
  const valueAxis = createValueAxis(chart)
  createGroupedData(dateAxis)
  const series = createSeries(chart, 'view', 'line')
  series.fillOpacity = 0
  createCursor(chart, dateAxis)
  createSimpleScrollbar(chart)
  createExport(chart)
  formatExportData(chart)
}

export const formatExportData = (chart) => {
  formatFieldNamesForExport(chart)
  convertHourlyDataToDailyDataForExport(chart)
}

export const formatFieldNamesForExport = (chart) => {
  chart.exporting.adapter.add('formatDataFields', function (data, target) {
    data.dataFields = {}
    chart.series.each(function (series) {
      data.dataFields[series.dataFields.dateX] = 'Tarih'
      data.dataFields.view = 'İzlenme'
      data.dataFields.click = 'Tıklanma'
      data.dataFields.budget = 'Gerçekleşen'
      data.dataFields.adView = 'Kuşak İzlenme'
      data.dataFields.adClick = 'Kuşak Tıklanma'
      data.dataFields.adBudget = 'Kuşak Gerçekleşen'
      data.dataFields.pssView = 'PSS İzlenme'
      data.dataFields.pssClick = 'PSS Tıklanma'
      data.dataFields.pssBudget = 'PSS Gerçekleşen'
      data.dataFields.overlayView = 'Overlay İzlenme'
      data.dataFields.overlayClick = 'Overlay Tıklanma'
      data.dataFields.overlayBudget = 'Overlay Gerçekleşen'
    })
    return data
  })
}

export const convertHourlyDataToDailyDataForExport = (chart) => {
  chart.exporting.adapter.add('data', function (data) {
    const groupedData = {}
    const exportData = []
    const origData = []
    data.data.forEach((row) => {
      const date = row.date.substr(0, 10)
      if (!groupedData[date]) {
        groupedData[date] = {
          date,
          view: 0,
          click: 0,
          adView: 0,
          adClick: 0,
          pssView: 0,
          pssClick: 0,
          overlayView: 0,
          overlayClick: 0,
          budget: 0,
          adBudget: 0,
          pssBudget: 0,
          overlayBudget: 0,
        }
      }
      Object.keys(row).forEach((key) => {
        if (key !== 'date') {
          groupedData[date][key] += row[key]
        }
      })
      const r = {...row}
      r.date += ':00'
      origData.push(r)
    })
    Object.keys(groupedData).forEach((date) => {
      exportData.push(groupedData[date])
    })
    return {data: exportData.concat([{}, {date: 'Saatlik'}]).concat(origData)}
  })
}

export const clickChart = (element, data) => {
  const chart = createChart(element, data, 'xy')
  const dateAxis = createDateAxis(chart)
  const valueAxis = createValueAxis(chart)
  createGroupedData(dateAxis)
  const series = createSeries(chart, 'click', 'line')
  createCursor(chart, dateAxis)
  createSimpleScrollbar(chart)
  // createExport(chart);
  // formatExportData(chart);
}

export const clickRateChart = (element, data) => {
  const chart = createChart(element, data, 'xy')
  const dateAxis = createDateAxis(chart)
  const valueAxis1 = createValueAxis(chart)
  const valueAxis2 = createValueAxis(chart)
  createGroupedData(dateAxis)
  chart.colors.list = [am4core.color(CLICK_COLOR), am4core.color(VIEW_COLOR)]

  const series1 = createSeries(chart, 'click', 'line')
  series1.yAxis = valueAxis1
  series1.name = 'Tıklanma'
  series1.fillOpacity = 0
  const series2 = createSeries(chart, 'view', 'line')
  series2.yAxis = valueAxis2
  series2.name = 'İzlenme'
  series2.fillOpacity = 0
  valueAxis2.renderer.opposite = true
  valueAxis2.syncWithAxis = valueAxis1
  createLegend(chart)
  createCursor(chart, dateAxis)
  chart.scrollbarX = new am4core.Scrollbar()
  // createExport(chart);
  // formatExportData(chart);
}

export const spentBudgetChart = (element, data) => {
  const chart = createChart(element, data, 'xy')
  chart.hiddenState.properties.opacity = 0

  const dateAxis = createDateAxis(chart)
  dateAxis.renderer.grid.template.location = 0
  dateAxis.minZoomCount = 5

  const valueAxis = createValueAxis(chart)
  createGroupedData(dateAxis)
  chart.colors.list = [
    am4core.color(NS_COLOR),
    am4core.color(PSS_COLOR),
    am4core.color(OVERLAY_COLOR),
  ]

  createSeriesAndLabel(chart, 'adBudget', 'Kuşak')
  createSeriesAndLabel(chart, 'pssBudget', 'PSS')
  createSeriesAndLabel(chart, 'overlayBudget', 'Overlay')

  createLegend(chart)
  createCursor(chart, dateAxis)
  createSimpleScrollbar(chart)
  // createExport(chart);
  // formatExportData(chart);
}

export const createSeriesAndLabel = (chart, field, name) => {
  const series = createSeries(chart, field, 'column')
  series.name = name

  const labelBullet = series.bullets.push(new am4charts.LabelBullet())
  labelBullet.label.text = '{valueY}'
  labelBullet.locationY = 0.5
  labelBullet.label.hideOversized = true

  labelBullet.label.adapter.add('textOutput', function (text, target) {
    if (target.dataItem && target.dataItem.valueY === 0) {
      return ''
    }
    return text
  })
}

export const pieChart = (element, data, title, titleField, valueField) => {
  if (isPieChartDataEmpty(data, valueField)) {
    const element = {}
    element[titleField] = 'Veri Yok'
    element[valueField] = 1
    data = [element]
  } else {
    data = filterEmptyPieChartData(data, valueField)
  }
  const chart = createChart(element, data, 'pie')
  const pieSeries = createSeries(chart, valueField, 'pie')
  pieSeries.dataFields.category = titleField

  pieSeries.colors.list = [
    '#2cb3bf',
    '#FBC02D',
    '#0288d1',
    '#F44336',
    '#337AB7',
    '#8E24AA',
    '#388E3C',
  ].map(function (color) {
    return new am4core.color(color)
  })

  createTitle(chart, title)
  createExport(chart)
}

export const isPieChartDataEmpty = (data, valueField) => {
  let totalValue = 0
  data.forEach((d) => (totalValue += d[valueField]))
  return totalValue === 0
}

export const filterEmptyPieChartData = (data, valueField) => {
  return data.filter((d) => d[valueField] > 0)
}

export const createHeatLegendForMap = (chart, series) => {
  const heatLegend = chart.createChild(am4maps.HeatLegend)
  heatLegend.id = 'heatLegend'
  heatLegend.series = series
  heatLegend.align = 'bottom'
  heatLegend.valign = 'bottom'
  heatLegend.width = am4core.percent(35)
  heatLegend.marginRight = am4core.percent(4)
  heatLegend.background.fillOpacity = 0.5

  const minRange = heatLegend.valueAxis.axisRanges.create()
  minRange.label.horizontalCenter = 'left'
  const maxRange = heatLegend.valueAxis.axisRanges.create()
  maxRange.label.horizontalCenter = 'right'

  heatLegend.valueAxis.renderer.labels.template.adapter.add('text', function (labelText) {
    return ''
  })

  series.events.on('datavalidated', function (ev) {
    let min, max
    const heatLegend = ev.target.map.getKey('heatLegend')
    if (heatLegend.series.dataItem.values.value.hasOwnProperty('low')) {
      min = heatLegend.series.dataItem.values.value.low
      max = heatLegend.series.dataItem.values.value.high
    } else {
      min = 0
      max = 0
    }

    const minRange = heatLegend.valueAxis.axisRanges.getIndex(0)
    minRange.value = min
    minRange.label.text = heatLegend.numberFormatter.format(min)
    const maxRange = heatLegend.valueAxis.axisRanges.getIndex(1)
    maxRange.value = max
    maxRange.label.text = heatLegend.numberFormatter.format(max)
  })
}
export const createPolygonTemplateForMap = (series) => {
  const polygonTemplate = series.mapPolygons.template
  polygonTemplate.tooltipText = '{name}: {value}'
  polygonTemplate.nonScalingStroke = true
  polygonTemplate.strokeWidth = 0.5

  const hs = polygonTemplate.states.create('hover')
  hs.properties.fill = am4core.color('#3c5bdc')
}

export const createZoomControl = (chart) => {
  chart.zoomControl = new am4maps.ZoomControl()
  const homeButton = new am4core.Button()
  homeButton.events.on('hit', function () {
    chart.goHome()
  })
  homeButton.icon = new am4core.Sprite()
  homeButton.padding(5, 5, 5, 5)
  homeButton.width = 30
  homeButton.icon.path =
    'M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8'
  homeButton.marginBottom = 10
  homeButton.parent = chart.zoomControl
  homeButton.insertBefore(chart.zoomControl.plusButton)
}

export const geographicHeatMap = (element, data, isLocal) => {
  const chart = createChart(element, null, 'map')
  isLocal ? (chart.geodata = am4geodata_turkeyLow) : (chart.geodata = am4geodata_worldLow)

  const series = createSeries(chart, null, 'map')
  series.data = data

  createHeatLegendForMap(chart, series)
  createPolygonTemplateForMap(series)
  createZoomControl(chart)
}

export const spentBudgetHbbChart = (element, data) => {
  const chart = createChart(element, data, 'xy')
  chart.hiddenState.properties.opacity = 0

  const dateAxis = createDateAxis(chart)
  dateAxis.renderer.grid.template.location = 0
  dateAxis.minZoomCount = 5

  const valueAxis = createValueAxis(chart)
  createGroupedData(dateAxis)
  chart.colors.list = [
    am4core.color(NS_COLOR),
    am4core.color(PSS_COLOR),
    am4core.color(OVERLAY_COLOR),
  ]

  createSeriesAndLabel(chart, 'adBudget', 'Adreslenebilir TV')

  createLegend(chart)
  createCursor(chart, dateAxis)
  createSimpleScrollbar(chart)
  createExport(chart)
}

export const chartForAdminDashboardToShowImpressionForAllChannels = (element, data) => {
  const chart = createChart(element, data, 'xy')
  chart.colors.list = [
    am4core.color(NS_COLOR),
    am4core.color('#9D9D9D'),
    am4core.color('#FF6400'),
    am4core.color('#185BA6'),
    am4core.color('#01605F'),
    am4core.color('#0C7DD5'),
  ]
  const dateAxis = createDateAxis(chart)
  const valueAxis = createValueAxis(chart)
  dateAxis.keepSelection = true
  createGroupedData(dateAxis)
  dateAxis.groupCount = 4000
  dateAxis.groupIntervals.setAll([
    {timeUnit: 'minute', count: 1},
    {timeUnit: 'minute', count: 5},
    {timeUnit: 'minute', count: 10},
  ])
  const series = createSeries(chart, 'total', 'line')
  series.yAxis = valueAxis
  series.name = 'Toplam'
  series.groupFields.valueY = 'average'
  series.tooltipText = '{name}: {valueY}'
  const keys = Object.keys(data[0])
  const channels = keys.slice(0, keys.length - 2)
  createSeriesForAdminDashboard(chart, valueAxis, channels)

  createCursor(chart)
  chart.cursor.behavior = 'panXY'
  createLegend(chart)
  createDetailedScrollbar(chart, series)

  return chart
}

export const createSeriesForAdminDashboard = (chart, valueAxis, channels) => {
  channels.forEach((ch) => {
    const series = createSeries(chart, ch, 'line')
    series.yAxis = valueAxis
    series.name = ch
    series.groupFields.valueY = 'average'
    series.tooltipText = '{name}: {valueY}'
  })
}
