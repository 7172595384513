const offerStatus = (id) => {
  switch (id) {
    case 1:
      return {
        id: 1,
        name: 'PENDING',
        messageKey: 'BEKLEMEDE',
        color: 'info',
      }

    case 2:
      return {
        id: 2,
        name: 'CONFIRMED',
        messageKey: 'ONAYLANDI',
        color: 'success',
      }

    case 3:
      return {
        id: 3,
        name: 'DENIED',
        messageKey: 'REDDEDİLDİ',
        color: 'danger',
      }

    case 4:
      return {
        id: 4,
        name: 'COMPLETED',
        messageKey: 'TAMAMLANDI',
        color: 'primary',
      }

    case 5:
      return {
        id: 5,
        name: 'CANCELLED_BY_PUB',
        messageKey: 'YAYINCI İPTAL ETTİ',
        color: 'warning',
      }

    case 6:
      return {
        id: 6,
        name: 'CANCELLED_BY_AGN',
        messageKey: 'AJANS İPTAL ETTİ',
        color: 'warning',
      }

    case 7:
      return {
        id: 7,
        name: 'PARTIALLY_CONFIRMED',
        messageKey: 'KISMEN ONAYLANDI',
        color: 'info',
      }

    case 8:
      return {
        id: 8,
        name: 'EXPIRED',
        messageKey: 'ZAMAN AŞIMINA UĞRADI',
        color: 'danger',
      }

    case 9:
      return {
        id: 9,
        name: 'WITHDRAWN',
        messageKey: 'GERİ ÇEKİLDİ',
        color: 'secondary',
      }

    default:
      break
  }
}

export default offerStatus
