import * as Yup from 'yup'
import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {KTSVG} from '../../../../../_metronic/helpers'
import {getScreenshotUrl} from '../../../../services/offers.service'
import {useParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import {decodeEntities} from '../../../../utils/format'
import clsx from 'clsx'

const screenshotSchema = Yup.object().shape({
  url: Yup.string()
    .required('Url is required'),
  creativeId: Yup.number().required('Kreatif seçimi zorunludur!'),
})

function ScreenshotModal({showModal, creatives, offer}) {
  let {agency_id, offer_id} = useParams()
  if (offer) {
    offer_id = offer
  }

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const [screenshotData] = useState({
    url: '',
    creativeId: creatives[0].id,
  })

  const formik = useFormik({
    initialValues: screenshotData,
    validationSchema: screenshotSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const response = await getScreenshotUrl(agency_id, offer_id, values)
        if (response.success) {
          showModal(null)
          Swal.fire('Ekran Görüntüsü Url', response.data.url, 'success')
        } else {
          Swal.fire(
            'Bir sorunla karşılaştık.',
            response.data.data.errors
              ? response.data.data.errors[0].message
              : 'Lütfen tekrar deneyiniz.',
            'error'
          )
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <div className='modal-header'>
              {/* begin::Modal title */}
              <h2 className='fw-bolder'>Teklif Ekran Görüntüsü Al</h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => showModal(null)}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <form
                id='kt_modal_add_user_form'
                className='form'
                onSubmit={formik.handleSubmit}
                noValidate
              >
                {/* begin::Scroll */}
                <div
                  className='d-flex flex-column scroll-y me-n7 pe-7'
                  id='kt_modal_add_user_scroll'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  data-kt-scroll-max-height='auto'
                  data-kt-scroll-dependencies='#kt_modal_add_user_header'
                  data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                  data-kt-scroll-offset='300px'
                >
                  {/* begin::Input group */}
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>URL</label>
                    <input
                      placeholder='Ekran goruntusu URL'
                      {...formik.getFieldProps('url')}
                      type='text'
                      name='url'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.url && formik.errors.url},
                        {
                          'is-valid': formik.touched.url && !formik.errors.url,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                    />
                    {formik.touched.url && formik.errors.url && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.url}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* end::Input group */}
                  {/* begin::Input group */}
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Kreatif</label>
                    <select
                      className='form-select'
                      aria-label='Select example'
                      onChange={(e) => {
                        formik.setFieldValue('creativeId', parseInt(e.target.value))
                      }}
                    >
                      {creatives.length > 0 &&
                        creatives.map((v, i) => {
                          return (
                            <option key={i} value={v.id}>
                              {decodeEntities(v.name)}
                            </option>
                          )
                        })}
                    </select>
                    {formik.touched.creativeId && formik.errors.creativeId && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.creativeId}</span>
                      </div>
                    )}
                  </div>
                  {/* end::Input group */}
                </div>
                {/* end::Scroll */}

                {/* begin::Actions */}
                <div className='text-center pt-15'>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                  >
                    <span className='indicator-label'>Gönder</span>
                    {formik.isSubmitting && (
                      <span className='indicator-progress'>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
                {/* end::Actions */}
              </form>
              {/* <UserEditModalFormWrapper /> */}
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export default ScreenshotModal
