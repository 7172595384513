/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import moment from 'moment-timezone/builds/moment-timezone-with-data'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import './../../../../../src/_metronic/assets/css/rsuite.css'
import 'shaka-player/dist/controls.css'
import Table from './components/Table'
import {KTCard, KTSVG} from '../../../../_metronic/helpers'
import {useNavigate, useParams} from 'react-router-dom'
import {getChannelOffers, offerStatu} from '../../../services/publishers.service'
import DetailModal from './DetailModal'
import Player from './components/Player'
import offerStatus from '../../../utils/offerStatus'
import {decodeEntities} from '../../../utils/format'

const ChannelOffers = () => {
  const intl = useIntl()
  let {publisher_id} = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const [selectedOffer, setSelectedOffer] = useState(null)
  const [modal, setModal] = useState(false)

  const customColumns = React.useMemo(
    () => [
      {
        Header: (props) => (
          <th
            {...props.column.getHeaderProps()}
            className='min-w-125px'
            style={{cursor: 'pointer'}}
            // onClick={sortColumn}
          >
            Teklif Adı
          </th>
        ),
        accessor: 'offer.name',
        Cell: ({...props}) => {
          let offer = props.row.original
          return (
            <div className='d-flex flex-column align-items-center'>
              <div style={{maxWidth: '350px'}}>
                {offer.type === 1 ? (
                  <Player url={offer.offer.creatives[0].url} />
                ) : (
                  <img src={offer.offer.creatives[0].url} width={'100%'} />
                )}
              </div>
              <span className='fw-bold text-center mt-2'>{decodeEntities(offer.offer.name)}</span>
            </div>
          )
        },
      },
      {
        Header: (props) => (
          <th
            {...props.column.getHeaderProps()}
            className='min-w-125px'
            style={{cursor: 'pointer'}}
            // onClick={sortColumn}
          >
            Teklif Detayları
          </th>
        ),
        id: 'offers',
        Cell: ({...props}) => {
          let value = props.row.original
          return (
            <div>
              <div className='row'>
                <div className='col-6'>Tarih</div>
                <div className='col-6'>
                  {moment(value.offer.startDate).format('DD-MM-YYYY')} -{' '}
                  {moment(value.offer.endDate).format('DD-MM-YYYY')}
                </div>
              </div>
              <div className='row'>
                <div className='col-6'>Kanal</div>
                <div className='col-6'>{decodeEntities(value.channel.name)}</div>
              </div>
              <div className='row'>
                <div className='col-6'>Ajans</div>
                <div className='col-6'>{decodeEntities(value.offer.campaign.agency.name)}</div>
              </div>
              <div className='row'>
                <div className='col-6'>Reklam Veren</div>
                <div className='col-6'>{decodeEntities(value.offer.advertiser.name)}</div>
              </div>
              <div className='row'>
                <div className='col-6'>Yayın Tipi</div>
                {value.type === 1 && <div className='col-6'>{getAdType(value)}</div>}
                {value.type === 2 && <div className='col-6'>Overlay</div>}
              </div>
              <div className='row'>
                <div className='col-6'>Kampanya</div>
                <div className='col-6'>{decodeEntities(value.offer.campaign.name)}</div>
              </div>
              <div className='row'>
                <div className='col-6'>Teklif</div>
                <div className='col-6'>{decodeEntities(value.offer.name)}</div>
              </div>
              <div className='row'>
                <div className='col-6'>Gerçekleşen Bütçe</div>
                <div className='col-6'>{value.spentBudget}₺</div>
              </div>
              {value.type === 1 && (
                <div className='row'>
                  <div className='col-6'>CPV (PSS)</div>
                  <div className='col-6'>
                    {value.pssType ? `${value.pssPrice}₺` : 'Aktif Değil'}
                  </div>
                </div>
              )}
              {value.type === 1 && (
                <div className='row'>
                  <div className='col-6'>CPV (NS)</div>
                  <div className='col-6'>{value.nsType ? `${value.nsPrice}₺` : 'Aktif Değil'}</div>
                </div>
              )}
              {value.type === 2 && (
                <div className='row'>
                  <div className='col-6'>CPM</div>
                  <div className='col-6'>{value.cpm}₺</div>
                </div>
              )}
              <div className='row'>
                <div className='col-6'>Oluşturma Tarihi</div>
                <div className='col-6'>{value.createdAt}</div>
              </div>
            </div>
          )
        },
      },
      {
        Header: (props) => (
          <th
            {...props.column.getHeaderProps()}
            className='min-w-125px'
            style={{cursor: 'pointer'}}
          >
            Statü
          </th>
        ),
        id: 'statu',
        Cell: ({...props}) => {
          let value = props.row.original
          let status = offerStatus(value.status.id)
          return value.status.id !== 1 ? (
            <div className={`btn btn-${status.color} w-100`}>{status.messageKey}</div>
          ) : (
            <div className='row'>
              <div className='col-12'>
                <button
                  className='btn btn-secondary w-100'
                  onClick={() => {
                    const params = new URLSearchParams()

                    params.append('status', 2)
                    offerStatu(value.tvStationId, value.id, params).then((res) => {
                      if (res.success) {
                        fetchData()
                      } else {
                        Swal.fire('Bir sorun çıktı', res.data.data.errors[0].message, 'warning')
                      }
                    })
                  }}
                >
                  Onay
                </button>
              </div>
              <div className='col-12'>
                <button
                  className='btn btn-danger w-100 mt-2'
                  onClick={() => {
                    const params = new URLSearchParams()

                    params.append('status', 3)
                    offerStatu(value.tvStationId, value.id, params).then((res) => {
                      if (res.success) {
                        fetchData()
                      } else {
                        Swal.fire('Bir sorun çıktı', res.data.data.errors[0].message, 'warning')
                      }
                    })
                  }}
                >
                  Red
                </button>
              </div>
            </div>
          )
        },
      },
      {
        Header: (props) => (
          <th
            {...props.column.getHeaderProps()}
            className='min-w-125px'
            style={{cursor: 'pointer'}}
          >
            İşlemler
          </th>
        ),
        id: 'detail',
        Cell: ({...props}) => {
          let value = props.row.original
          return (
            <div className='d-flex flex-column'>
              <button
                className='btn btn-secondary text-center'
                onClick={() => {
                  // setModal(true)
                  // setSelectedOffer(value)
                  navigate(
                    `/publishers/${publisher_id}/channels/${value.tvStationId}/offers/${value.id}`
                  )
                }}
              >
                <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-3 ms-2' />
              </button>

              {value.status.id === 2 && (
                <button
                  className='btn btn-danger text-center mt-2'
                  onClick={() => {
                    const params = new URLSearchParams()

                    params.append('status', 5)
                    offerStatu(value.tvStationId, value.id, params).then((res) => {
                      if (res.success) Swal.fire('Başarılı', 'Teklif iptal edildi.', 'success')
                      else Swal.fire('Bir sorun çıktı', res.data.data.errors[0].message, 'error')
                    })
                  }}
                >
                  İptal Et
                  <KTSVG
                    path='/media/icons/duotune/general/gen040.svg'
                    className='svg-icon-3  ms-2'
                  />
                </button>
              )}
            </div>
          )
        },
      },
    ],
    []
  )

  async function fetchData() {
    const offers = await getChannelOffers(publisher_id)
    const publisher = offers.data.publisher
    offers.data.offers.forEach((offer) => {
      offer.createdAt = moment(offer.createdAt).tz(publisher.timezone).format('DD-MM-YYYY HH:mm')
    })
    setData(offers.data.offers)
    // setAdvertisers(campaigns.data.advertisers)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <KTCard>{data && <Table data={data} columns={customColumns} />}</KTCard>
      {modal && <DetailModal showModal={setModal} offer={selectedOffer} />}
    </>
  )
}
function getAdType(value) {
  if (value.pssType && value.nsType) {
    return 'Kuşak, PSS'
  }
  if (value.pssType) {
    return 'PSS'
  }
  if (value.nsType) {
    return 'Kuşak'
  }
}

export {ChannelOffers}
