import axios from 'axios'
import {instance} from './http'

export const login = (data) => {
  return axios
    .post(`${process.env.REACT_APP_API}/login`, data, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const refresh = (token, header) => {
  return axios
    .post(`${process.env.REACT_APP_API}/refreshToken`, {}, header)
    .then((res) => {
      if (res.success) return res.data.token
      else return false
    })
    .catch((err) => {
      console.error(err)
      return false
    })
}

export const changePassword = (data) => {
  return instance
    .post(`user/changePassword`, {
      oldPassword: data.oldPass,
      newPassword: data.newPass,
      repeatNewPassword: data.newCheck,
    })
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}
