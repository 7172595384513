import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Swal from 'sweetalert2'
import {instance} from '../../../../services/http'
import {useParams} from 'react-router-dom'
import {Droppable, Draggable, DragDropContext} from 'react-beautiful-dnd'
import {
  changePriorityDatatable,
  getPriorityDatatable,
} from '../../../../services/publishers.service'
import moment from 'moment'
import {decodeEntities} from '../../../../utils/format'

const grid = 8
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  width: 250,
})

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

function PriorityModal({showModal, fetchData}) {
  let {publisher_id} = useParams()
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const [data, setData] = useState([])

  useEffect(() => {
    getPriorityDatatable(publisher_id).then((res) => {
      if (!res.success) {
        Swal.fire('Hata', res.data.data.errors[0].message, 'error')
      } else {
        setData(res.data.sort((a, b) => a.priority - b.priority))
      }
    })
  }, [])

  const onDragEnd = (e) => {
    if (!e.destination) {
      return
    }
    let tempData = [...data]

    const items = reorder(tempData, e.source.index, e.destination.index)
    setData(items)
  }

  const handleSubmit = () => {
    let query = 'time[part]=1&time[month]'
    data.forEach((v, i) => {
      query += '&' + v.id + '=' + (i + 1)
    })
    changePriorityDatatable(publisher_id, query).then((res) => {
      if (!res.success) {
        Swal.fire('Hata', res.data.data.errors[0].message, 'error')
      } else {
        showModal(false)
        Swal.fire('Başarılı', 'Öncelikler değiştirildi.', 'success')
      }
    })
  }

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>Öncelik Değiştir</h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => showModal(false)}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <div className='row'>
                <div className='col-2 p-2 border-start border-top border-bottom'>Öncelik</div>
                <div className='col-3 p-2 border-start border-top border-bottom'>Tanıtım Adı</div>
                <div className='col-3 p-2 border-start border-top border-bottom'>Başlangıç</div>
                <div className='col-2 p-2 border-start border-top border-bottom'>Bitiş</div>
                <div className='col-2 p-2 border-start border-top border-bottom border-end'>
                  Saat
                </div>
              </div>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='droppable'>
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {data.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                          {(provided, snapshot) => (
                            <>
                              <div
                                className='row'
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={
                                  snapshot.isDragging
                                    ? {background: '#d2d2d2', ...provided.draggableProps.style}
                                    : {background: 'transparent', ...provided.draggableProps.style}
                                }
                              >
                                <div className='col-2 p-2 border-start border-bottom'>
                                  {item.priority}
                                </div>
                                <div className='col-3 p-2 border-start border-bottom'>
                                  {decodeEntities(item.creative.name)}
                                </div>
                                <div className='col-3 p-2 border-start border-bottom'>
                                  {moment(item.startData).format('DD MMM YY')}
                                </div>
                                <div className='col-2 p-2 border-start border-bottom'>
                                  {moment(item.endDate).format('DD MMM YY')}
                                </div>
                                <div className='col-2 p-2 border-start border-bottom border-end'>
                                  {item.endHour}
                                </div>
                              </div>
                            </>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <button className='btn btn-primary w-100 mt-4' onClick={() => handleSubmit()}>
                Kaydet
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default PriorityModal
