import {instance} from './http'

export const getCampaigns = (agency, part, month) => {
  let params = new URLSearchParams()
  params.append('time[part]', part.toString())
  params.append('time[month]', month.toString())
  return instance
    .get(`/agencies/${agency}/campaigns/time?${params}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const getCampaignsWithoutTime = (agency) => {
  return instance
    .get(`/agencies/${agency}/campaigns`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const getAdvertiserCampaigns = (agency, advertiserId) => {
  return instance
    .get(`/agencies/${agency}/customers/${advertiserId}/campaigns`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const getSecondaryAdvertisers = (agency, id) => {
  return instance
    .get(`/agencies/${agency}/campaigns/${id}/secondaryAdvertisers`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const addCampaign = (agency, data) => {
  return instance
    .post(`/agencies/${agency}/campaigns`, data)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const deleteCampaign = (agency, id) => {
  return instance
    .delete(`/agencies/${agency}/campaigns/${id}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const statusCampaign = (agency, id, status) => {
  return instance
    .put(`/agencies/${agency}/campaigns/${id}/status`, {
      status,
    })
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const updateCampaign = (agency, id, data) => {
  return instance
    .put(`/agencies/${agency}/campaigns/${id}`, data)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}
