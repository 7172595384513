import React, {useState} from 'react'
import {LinearProgress} from '@mui/material'
import {instance} from '../../../../services/http'
import Swal from 'sweetalert2'

function Upload({formValues, publisher_id, fetchData}) {
  const [isLoading, setIsLoading] = useState(false)
  const [progress, setProgress] = useState(0)

  const upload = async () => {
    const formData = new FormData()
    formData.append('name', formValues.title)
    formData.append('logo', formValues.logo)
    formValues.file && formData.append('files[]', formValues.file)
    setIsLoading(true)
    await instance
      .post(`/publishers/${publisher_id}/trailers`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 50
          setProgress(progress)
        },
        onDownloadProgress: (progressEvent) => {
          const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50
          setProgress(progress)
        },
      })
      .then((res) => {
        fetchData()
        Swal.fire('Başarılı!', 'Tanıtım başarıyla eklendi.', 'success')
        setProgress(0)
      })
      .catch((err) => {
        Swal.fire(
          'Hata!',
          err.data.data.errors[0].message ?? 'Daha sonra tekrar deneyiniz.',
          'error'
        )
        setProgress(0)
      })
    setIsLoading(false)
  }

  return (
    <>
      <div className='col-12 mt-4'>
        <button
          className='btn btn-success'
          onClick={async () => {
            upload()
          }}
          disabled={isLoading}
        >
          {isLoading && (
            <span className='indicator-progress'>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
          Ekle
        </button>
      </div>
      <div className='col-12 mt-4'>
        <LinearProgress variant='determinate' value={progress} />
      </div>
    </>
  )
}

export default Upload
