import moment from 'moment'
import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import ReactSelect from 'react-select'
import {KTSVG} from '../../../../_metronic/helpers'
import {getOfferPrograms} from '../../../services/publishers.service'

function DetailModal({showModal, offer}) {
  let {publisher_id} = useParams()

  const [programs, setPrograms] = useState([])

  useEffect(() => {
    const getData = async () => {
      let a = await getOfferPrograms(publisher_id, offer.tvStationId, offer.id)
      if (a.success) {
        setPrograms(a.data)
      }
    }
    getData()
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content' style={{minHeight: '80vh'}}>
            <div className='modal-header'>
              <h2 className='fw-bolder'>Reklam Teklifi Detayı</h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => showModal(false)}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <div className='row'>
                <div className='col-3 fw-bold'>Tarih</div>
                <div className='col-9'>
                  {moment(offer.offer.start).format('DD MMMM YYYY')} -{' '}
                  {moment(offer.offer.end).format('DD MMMM YYYY')}
                </div>
              </div>

              <div className='row mt-2'>
                <div className='col-3 fw-bold'>Ajans</div>
                <div className='col-9'>{offer.offer.campaign.agency.name}</div>
              </div>

              <div className='row mt-2'>
                <div className='col-3 fw-bold'>Reklam Veren</div>
                <div className='col-9'>{offer.offer.advertiser.name}</div>
              </div>

              {offer.type === 1 && (
                <div className='row mt-2'>
                  <div className='col-3 fw-bold'>CPV (PSS)</div>
                  <div className='col-9'>
                    {offer.pssType ? `${offer.pssPrice}₺` : 'Aktif Değil'}
                  </div>
                </div>
              )}
              {offer.type === 1 && (
                <div className='row mt-2'>
                  <div className='col-3 fw-bold'>CPV (NS)</div>
                  <div className='col-9'>{offer.nsType ? `${offer.nsPrice}₺` : 'Aktif Değil'}</div>
                </div>
              )}
              {offer.type === 2 && (
                <div className='row mt-2'>
                  <div className='col-3 fw-bold'>CPM</div>
                  <div className='col-9'>{offer.cpm}₺</div>
                </div>
              )}

              <div className='row mt-2'>
                <div className='col-3 fw-bold'>Programlar</div>
                <div className='col-9'>
                  {offer.isAllProgramsSelected
                    ? 'Tüm programlar seçili'
                    : programs.map((v, i) => {
                        return <span key={i}>{v.title}, </span>
                      })}
                </div>
              </div>

              <label className='fs-2 mt-4'>Hedef Kitle</label>

              <div className='row mt-2'>
                <div className='col-3 fw-bold'>Yaş</div>
                <div className='col-9'>{offer.offer.age}</div>
              </div>

              <div className='row mt-2'>
                <div className='col-3 fw-bold'>Cinsiyet</div>
                <div className='col-9'>{offer.offer.gender}</div>
              </div>

              <div className='row mt-2'>
                <div className='col-3 fw-bold'>Cihaz</div>
                <div className='col-9'>{offer.offer.device}</div>
              </div>

              <div className='row mt-2'>
                <div className='col-3 fw-bold'>Operatör</div>
                <div className='col-9'>{offer.offer.operator}</div>
              </div>

              <div className='row mt-2'>
                <div className='col-3 fw-bold'>Saatler</div>
                <div className='col-9'>{offer.offer.partOfDay}</div>
              </div>

              <div className='row mt-2'>
                <div className='col-3 fw-bold'>Günler</div>
                <div className='col-9'>{offer.offer.partOfWeek}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default DetailModal
