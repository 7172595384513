import {Divider} from 'antd'
import React, {useState, useEffect, useRef} from 'react'
import {useParams} from 'react-router-dom'
import ReactSelect from 'react-select'
import Swal from 'sweetalert2'
import {DatePicker} from 'antd'
import moment from 'moment-timezone/builds/moment-timezone-with-data'
import 'moment/locale/tr'
import locale from 'antd/es/date-picker/locale/tr_TR'
import {KTCard, KTSVG} from '../../../../_metronic/helpers'
import {
  deleteAgencyReport,
  downloadReport,
  getAgencyReports,
  getAgencyReportsCampaigns,
  getAgencyReportsCampaignsOffers,
  getAgencyReportsCampaignsOffersFilter,
} from '../../../services/agencies.service'
import ConfirmModal from './confirmModal'
import Table from './components/Table'
import DetailModal from './detailModal'
import {useThemeMode} from '../../../../_metronic/partials'
import {decodeEntities} from '../../../utils/format'

const {RangePicker} = DatePicker

const customStyles = {
  control: (base) => {
    return {
      ...base,
      maxHeight: 150,
    }
  },
  valueContainer: (provided, state) => ({
    ...provided,
    maxHeight: '150px',
    overflow: 'scroll',
  }),
}

function AgencyReports() {
  let {agency_id} = useParams()
  const {mode} = useThemeMode()

  const [modal, showModal] = useState(false)
  const [selectedDetail, setSelectedDetail] = useState(false)

  const [initData, setInitData] = useState([])

  const [reportsData, setReportsData] = useState([])

  const [selectedAdvertiser, setSelectedAdvertiser] = useState(null)
  const [campaigns, setCampaigns] = useState([])
  const [selectedCampaign, setSelectedCampaign] = useState(null)
  const [offers, setOffers] = useState([])
  const [offerOptions, setOfferOptions] = useState([])
  const [selectedOffers, setSelectedOffers] = useState([{label: 'Tümü', value: -1}])
  const [reportFilterInit, setReportFilterInit] = useState(null)

  const [channels, setChannels] = useState([])
  const [selectedChannels, setSelectedChannels] = useState(null)

  const [creatives, setCreatives] = useState([])
  const [selectedCreatives, setSelectedCreatives] = useState(null)

  const [videoTypes, setVideoTypes] = useState([])
  const [selectedVideoTypes, setSelectedVideoTypes] = useState(null)

  const [selectedDate, setSelectedDate] = useState(null)

  const [operators, setOperators] = useState([])
  const [selectedOperators, setSelectedOperators] = useState(null)

  const [devices, setDevices] = useState([])
  const [selectedDevices, setSelectedDevices] = useState(null)

  const [ages, setAges] = useState([])
  const [selectedAges, setSelectedAges] = useState(null)

  const [genders, setGenders] = useState([])
  const [selectedGenders, setSelectedGenders] = useState(null)

  const [programs, setPrograms] = useState([])
  const [selectedPrograms, setSelectedPrograms] = useState(null)

  const [trLocations, setTrLocations] = useState([])
  const [selectedTrLocations, setSelectedTrLocations] = useState(null)

  const [worldLocations, setWorldLocations] = useState([])
  const [selectedWorldLocations, setSelectedWorldLocations] = useState(null)

  const [periods, setPeriods] = useState([])

  const [channelGroup, setChannelGroup] = useState(false)
  const [creativeGroup, setCreativeGroup] = useState(false)
  const [videoTypeGroup, setVideoTypeGroup] = useState(false)
  const [periodCheck, setPeriodCheck] = useState(false)
  const [periodGroup, setPeriodGroup] = useState(null)
  const [operatorGroup, setOperatorGroup] = useState(false)
  const [deviceGroup, setDeviceGroup] = useState(false)
  const [ageGroup, setAgeGroup] = useState(false)
  const [genderGroup, setGenderGroup] = useState(false)
  const [locationTurkeyGroup, setLocationTurkeyGroup] = useState(false)
  const [locationWorldGroup, setLocationWorldGroup] = useState(false)

  const [dataParams, setDataParams] = useState('')

  const [selectedReportTime, setSelectedReportTime] = useState({
    label: 'Tüm Zamanlar',
    value: {part: 10, month: 'years'},
  })
  const selectedReportTimeRef = useRef()
  selectedReportTimeRef.current = selectedReportTime

  const loadInit = async () => {
    const res = await getAgencyReports(agency_id)
    if (!res.success) {
      Swal.fire('Bir sorun çıktı.', res.data.data.errors[0].message, 'error')
    } else {
      setInitData(res.data)
    }
  }

  const loadReports = async () => {
    const res = await getAgencyReports(agency_id, selectedReportTimeRef.current)
    if (!res.success) {
      Swal.fire('Bir sorun çıktı.', res.data.data.errors[0].message, 'error')
    } else {
      setReportsData(res.data.allReportsJSON)
    }
  }

  useEffect(() => {
    loadReports()
  }, [selectedReportTime])

  const getCampaigns = async (id) => {
    const res = await getAgencyReportsCampaigns(agency_id, id)
    if (!res.success) {
      Swal.fire('Bir sorun çıktı.', res.data.data.errors[0].message, 'error')
    } else {
      setCampaigns(res.data)
      setSelectedDate({
        start: moment(res.data.start).tz(initData.agency.timezone).unix(),
        end: moment(res.data.end).tz(initData.agency.timezone).unix(),
      })
    }
  }

  const getOffers = async (id) => {
    const res = await getAgencyReportsCampaignsOffers(agency_id, selectedAdvertiser.id, id)
    if (!res.success) {
      Swal.fire('Bir sorun çıktı.', res.data.data.errors[0].message, 'error')
    } else {
      setOffers(res.data)
      let arr = [{label: 'Tümü', value: -1}]
      res.data.forEach((v) => {
        arr.push({label: decodeEntities(v.name), value: v.id})
      })
      setOfferOptions(arr)
    }
  }

  const loadReportFilterInit = (data) => {
    setReportFilterInit(data)
    let tempChannels = data.channels.map((v) => {
      return {label: decodeEntities(v.name), value: v.id}
    })
    setChannels(tempChannels)

    let tempCreatives = data.creatives.map((v) => {
      return {label: decodeEntities(v.name), value: v.id}
    })
    setCreatives(tempCreatives)

    let tempVideoTypes = initData.videoTypeJSON.map((v) => {
      return {label: v.name, value: v.id}
    })
    setVideoTypes(tempVideoTypes)

    let tempOperators = initData.operatorJSON.map((v) => {
      return {label: v.name, value: v.id}
    })
    setOperators(tempOperators)

    let tempDevices = initData.deviceJSON.map((v) => {
      return {label: v.name, value: v.id}
    })
    setDevices(tempDevices)

    let tempAges = initData.ageJSON.map((v) => {
      return {label: v.name, value: v.id}
    })
    setAges(tempAges)

    let tempGenders = initData.genderJSON.map((v) => {
      return {label: v.name, value: v.id}
    })
    setGenders(tempGenders)

    let tempPrograms = data.programs.map((v) => {
      return {label: decodeEntities(v.title), value: v.id}
    })
    setPrograms(tempPrograms)

    let tempTrLocations = data.turkeyLocation.map((v) => {
      return {label: v.name, value: v.id}
    })
    setTrLocations(tempTrLocations)

    let tempWorldLocations = data.worldLocation.map((v) => {
      return {label: v.name, value: v.id}
    })
    setWorldLocations(tempWorldLocations)

    let tempPeriods = Object.keys(initData.periodParams).map((v) => {
      return {label: initData.periodParams[v], value: v}
    })
    setPeriods(tempPeriods)
  }

  useEffect(() => {
    loadInit()
    loadReports()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      loadReports()
    }, 15000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (selectedAdvertiser) getCampaigns(selectedAdvertiser.id)
  }, [selectedAdvertiser])

  useEffect(() => {
    if (selectedCampaign) {
      getOffers(selectedCampaign.id)
    }
  }, [selectedCampaign])

  const disabledDate = (current, start, end) => {
    return current < moment(start).add(-1, 'day') || current >= moment(end)
  }

  const createReport = () => {
    let params = ''
    params +=
      'advertiserId=' +
      selectedAdvertiser.id +
      '&campaignId=' +
      selectedCampaign.id +
      '&start=' +
      selectedDate.start +
      '&end=' +
      selectedDate.end

    if (channelGroup) params += '&channelGroup=' + channelGroup
    if (creativeGroup) params += '&creativeGroup=' + creativeGroup
    if (videoTypeGroup) params += '&videoTypeGroup=' + videoTypeGroup
    if (operatorGroup) params += '&operatorGroup=' + operatorGroup
    if (deviceGroup) params += '&deviceGroup=' + deviceGroup
    if (genderGroup) params += '&genderGroup=' + genderGroup
    if (ageGroup) params += '&ageGroup=on'
    if (locationTurkeyGroup) params += '&locationTurkeyGroup=' + locationTurkeyGroup
    if (locationWorldGroup) params += '&locationWorldGroup=' + locationWorldGroup
    if (periodCheck) params += '&periodCheck=on&periodGroup=' + periodGroup.value

    selectedOffers &&
      selectedOffers.forEach((v) => {
        params += '&offerIds[]=' + v.value
      })

    selectedChannels &&
      selectedChannels.forEach((v) => {
        params += '&channelWhere[]=' + v.value
      })

    selectedCreatives &&
      selectedCreatives.forEach((v) => {
        params += '&creativeWhere[]=' + v.value
      })

    selectedVideoTypes &&
      selectedVideoTypes.forEach((v) => {
        params += '&videoTypeWhere[]=' + v.value
      })

    selectedOperators &&
      selectedOperators.forEach((v) => {
        params += '&operatorWhere[]=' + v.value
      })

    selectedDevices &&
      selectedDevices.forEach((v) => {
        params += '&deviceWhere[]=' + v.value
      })

    selectedAges &&
      selectedAges.forEach((v) => {
        params += '&ageWhere[]=' + v.value
      })

    selectedGenders &&
      selectedGenders.forEach((v) => {
        params += '&genderWhere[]=' + v.value
      })

    selectedPrograms &&
      selectedPrograms.forEach((v) => {
        params += '&programWhere[]=' + v.value
      })

    selectedTrLocations &&
      selectedTrLocations.forEach((v) => {
        params += '&locationTurkeyWhere[]=' + v.value
      })

    selectedWorldLocations &&
      selectedWorldLocations.forEach((v) => {
        params += '&locationWorldWhere[]=' + v.value
      })

    setDataParams(params)
    showModal(true)
  }

  const customColumns = [
    {
      Header: (props) => (
        <th {...props.column.getHeaderProps()} className='min-w-125px' style={{cursor: 'pointer'}}>
          Rapor Adı
        </th>
      ),
      accessor: 'name',
      Cell: ({...props}) => {
        let value = props.row.original
        return (
          <div className='d-flex flex-column'>
            <span>{value.name}</span>
          </div>
        )
      },
    },
    {
      Header: (props) => (
        <th {...props.column.getHeaderProps()} className='min-w-125px' style={{cursor: 'pointer'}}>
          Müşteri Adı
        </th>
      ),
      accessor: 'advertiser.name',
    },
    {
      Header: (props) => (
        <th {...props.column.getHeaderProps()} className='min-w-125px' style={{cursor: 'pointer'}}>
          Kampanya Adı
        </th>
      ),
      accessor: 'campaign.name',
    },
    {
      Header: (props) => (
        <th {...props.column.getHeaderProps()} className='min-w-125px' style={{cursor: 'pointer'}}>
          Teklif(ler)
        </th>
      ),
      accessor: 'offerName',
    },
    {
      Header: (props) => (
        <th {...props.column.getHeaderProps()} className='min-w-125px' style={{cursor: 'pointer'}}>
          Oluşturulma Tarihi
        </th>
      ),
      accessor: 'createdAt',
      Cell: ({...props}) => {
        let value = props.row.original
        return (
          <div className='d-flex flex-column'>
            <span>{moment(value.createdAt).format('DD-MM-YYYY HH:mm')}</span>
          </div>
        )
      },
    },
    {
      Header: (props) => (
        <th {...props.column.getHeaderProps()} className='min-w-125px' style={{cursor: 'pointer'}}>
          Oluşturan Kullanıcı
        </th>
      ),
      accessor: 'user',
      Cell: ({...props}) => {
        let value = props.row.original
        return (
          <div className='d-flex flex-column'>
            <span>
              {value.creator.name} {value.creator.surname}
            </span>
          </div>
        )
      },
    },
    {
      Header: (props) => (
        <th {...props.column.getHeaderProps()} className='min-w-125px' style={{cursor: 'pointer'}}>
          İndir
        </th>
      ),
      accessor: 'download',
      Cell: ({...props}) => {
        let value = props.row.original
        return (
          <div className='d-flex justify-content-end flex-shrink-0'>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => {
                setSelectedDetail(value)
              }}
            >
              <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-3' />
            </button>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={async () => {
                const res = await downloadReport(agency_id, value.id, value.name)
                if (!res.success) {
                  Swal.fire('Bir sorun çıktı.', res.data.data.errors[0].message, 'error')
                } else {
                  Swal.fire('İndiriliyor', '', 'success')
                }
              }}
            >
              <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-3' />
            </button>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => {
                Swal.fire({
                  title: 'Silmek istediğine emin misin?',
                  text: 'Sildiğinde geri dönüşü olmayacak!',
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  cancelButtonText: 'İptal',
                  confirmButtonText: 'Evet, sil!',
                  preConfirm: async () => {
                    const res = await deleteAgencyReport(agency_id, value.id)
                    if (res.success) {
                      Swal.fire('Silindi!', 'Rapor başarıyla silindi.', 'success')
                      loadReports()
                    } else {
                      Swal.fire('Error!', res.data.data.errors[0].message)
                    }
                  },
                  allowOutsideClick: () => !Swal.isLoading(),
                })
              }}
            >
              <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
            </button>
          </div>
        )
      },
    },
  ]

  return (
    <>
      <KTCard className='p-4'>
        <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
          <li className='nav-item'>
            <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_1'>
              Rapor Oluştur
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_2'>
              Raporlarım
            </a>
          </li>
        </ul>
        <div className='tab-content' id='myTabContent'>
          <div className='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
            <div className='row'>
              <div className='col-3'>
                <label className='fw-bold'>Müşteri</label>
                <select
                  className='form-select mt-2'
                  aria-label='Select example'
                  onChange={(e) => {
                    setSelectedAdvertiser(
                      initData.advertisersJSON.find((x) => x.id === parseInt(e.target.value))
                    )
                  }}
                >
                  <option value={''}>Müşteri Seç</option>
                  {initData?.advertisersJSON?.length > 0 &&
                    initData.advertisersJSON.map((v, i) => {
                      return (
                        <option key={i} value={v.id}>
                          {decodeEntities(v.name)}
                        </option>
                      )
                    })}
                </select>
              </div>
              <div className='col-3'>
                <label className='fw-bold'>Kampanya</label>
                <select
                  className='form-select mt-2'
                  aria-label='Select example'
                  onChange={(e) => {
                    let campaign = campaigns.find((x) => x.id === parseInt(e.target.value))
                    setSelectedCampaign(campaign)
                    setSelectedDate({
                      start: moment(campaign.start).tz(initData.agency.timezone).unix(),
                      end: moment(campaign.end).tz(initData.agency.timezone).unix(),
                    })
                  }}
                >
                  <option value={''}>Kampanya Seç</option>
                  {campaigns.length > 0 &&
                    campaigns.map((v, i) => {
                      return (
                        <option key={i} value={v.id}>
                          {decodeEntities(v.name)}
                        </option>
                      )
                    })}
                </select>
              </div>
              <div className='col-3'>
                <label className='fw-bold'>Teklif(ler)</label>
                <ReactSelect
                  isMulti
                  options={offerOptions}
                  styles={customStyles}
                  defaultValue={selectedOffers}
                  closeMenuOnSelect={false}
                  onChange={(e) => setSelectedOffers(e)}
                  classNamePrefix='select'
                  isDisabled={offers.length < 1}
                  className='mt-2'
                />
              </div>
              <div className='col-3'>
                <label className='fw-bold w-100'></label>
                <button
                  className='btn btn-primary btn-sm w-100 mt-2'
                  onClick={async (e) => {
                    let params = new URLSearchParams()
                    selectedOffers.forEach((v) => {
                      params.append('offer[]', v.value.toString())
                    })
                    setSelectedDate({
                      start: moment(selectedCampaign.start).tz(initData.agency.timezone).unix(),
                      end: moment(selectedCampaign.end).tz(initData.agency.timezone).unix(),
                    })
                    let res = await getAgencyReportsCampaignsOffersFilter(
                      agency_id,
                      selectedAdvertiser.id,
                      selectedCampaign.id,
                      selectedOffers[0].value,
                      params
                    )
                    if (!res.success) {
                      Swal.fire('Bir sorun çıktı.', res.data.data.errors[0].message, 'error')
                    } else {
                      loadReportFilterInit(res.data)
                    }
                  }}
                >
                  Kırılım Seç
                </button>
              </div>
            </div>

            <Divider />
            {reportFilterInit && (
              <>
                <div className='row mt-4'>
                  <label className='fw-bold'>Rapor Filtresi</label>
                  <div className='row mt-2'>
                    <div className='col-3 mt-2'>
                      <label className='mb-2'>Kanallar</label>
                      <ReactSelect
                        isMulti
                        options={channels}
                        styles={customStyles}
                        closeMenuOnSelect={false}
                        onChange={(e) => setSelectedChannels(e)}
                        classNamePrefix='select'
                        isDisabled={offers.length < 1}
                      />
                    </div>
                    <div className='col-3 mt-2'>
                      <label className='mb-2'>Kreatifler</label>
                      <ReactSelect
                        isMulti
                        options={creatives}
                        styles={customStyles}
                        closeMenuOnSelect={false}
                        onChange={(e) => setSelectedCreatives(e)}
                        classNamePrefix='select'
                        isDisabled={offers.length < 1}
                      />
                    </div>
                    <div className='col-3 mt-2'>
                      <label className='mb-2'>Gösterim Tipi</label>
                      <ReactSelect
                        isMulti
                        options={videoTypes}
                        styles={customStyles}
                        closeMenuOnSelect={false}
                        onChange={(e) => setSelectedVideoTypes(e)}
                        classNamePrefix='select'
                        isDisabled={offers.length < 1}
                      />
                    </div>
                    <div className='col-3 mt-2'>
                      <label className='mb-2'>Teklif Periyodu</label>
                      <RangePicker
                        locale={locale}
                        disabledDate={(e) =>
                          disabledDate(e, selectedCampaign.start, selectedCampaign.end)
                        }
                        defaultValue={[
                          moment(selectedCampaign.start).tz(initData.agency.timezone),
                          moment(selectedCampaign.end).tz(initData.agency.timezone),
                        ]}
                        format={'DD.MM.YYYY'}
                        onChange={(e) => {
                          setSelectedDate({
                            start: e[0].tz(initData.agency.timezone).startOf('day').unix(),
                            end: e[1].tz(initData.agency.timezone).endOf('day').unix(),
                          })
                        }}
                      />
                    </div>
                    <div className='col-3 mt-2'>
                      <label className='mb-2'>Operatör</label>
                      <ReactSelect
                        isMulti
                        options={operators}
                        styles={customStyles}
                        closeMenuOnSelect={false}
                        onChange={(e) => setSelectedOperators(e)}
                        classNamePrefix='select'
                        isDisabled={offers.length < 1}
                      />
                    </div>
                    <div className='col-3 mt-2'>
                      <label className='mb-2'>Cihaz</label>
                      <ReactSelect
                        isMulti
                        options={devices}
                        styles={customStyles}
                        closeMenuOnSelect={false}
                        onChange={(e) => setSelectedDevices(e)}
                        classNamePrefix='select'
                        isDisabled={offers.length < 1}
                      />
                    </div>
                    <div className='col-3 mt-2'>
                      <label className='mb-2'>Yaş</label>
                      <ReactSelect
                        isMulti
                        options={ages}
                        styles={customStyles}
                        closeMenuOnSelect={false}
                        onChange={(e) => setSelectedAges(e)}
                        classNamePrefix='select'
                        isDisabled={offers.length < 1}
                      />
                    </div>
                    <div className='col-3 mt-2'>
                      <label className='mb-2'>Cinsiyet</label>
                      <ReactSelect
                        isMulti
                        options={genders}
                        styles={customStyles}
                        closeMenuOnSelect={false}
                        onChange={(e) => setSelectedGenders(e)}
                        classNamePrefix='select'
                        isDisabled={offers.length < 1}
                      />
                    </div>
                    <div className='col-3 mt-2'>
                      <label className='mb-2'>Programlar</label>
                      <ReactSelect
                        isMulti
                        options={programs}
                        styles={customStyles}
                        value={selectedPrograms}
                        closeMenuOnSelect={false}
                        onChange={(e) => setSelectedPrograms(e)}
                        classNamePrefix='select'
                        isDisabled={offers.length < 1}
                      />
                      <div className='form-check form-check-custom form-check-solid mt-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexCheckDefault1'
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedPrograms(programs)
                            } else {
                              setSelectedPrograms([])
                            }
                          }}
                        />
                        <label className='form-check-label' htmlFor='flexCheckDefault'>
                          Hepsini seç
                        </label>
                      </div>
                    </div>
                    <div className='col-3 mt-2'>
                      <label className='mb-2'>Lokasyon Türkiye</label>
                      <ReactSelect
                        isMulti
                        options={trLocations}
                        styles={customStyles}
                        value={selectedTrLocations}
                        closeMenuOnSelect={false}
                        onChange={(e) => setSelectedTrLocations(e)}
                        classNamePrefix='select'
                        isDisabled={offers.length < 1}
                      />
                      <div className='form-check form-check-custom form-check-solid mt-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexCheckDefault2'
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedTrLocations(trLocations)
                            } else {
                              setSelectedTrLocations([])
                            }
                          }}
                        />
                        <label className='form-check-label' htmlFor='flexCheckDefault'>
                          Hepsini seç
                        </label>
                      </div>
                    </div>
                    <div className='col-3 mt-2'>
                      <label className='mb-2'>Lokasyon Dünya</label>
                      <ReactSelect
                        isMulti
                        options={worldLocations}
                        styles={customStyles}
                        value={selectedWorldLocations}
                        closeMenuOnSelect={false}
                        onChange={(e) => setSelectedWorldLocations(e)}
                        classNamePrefix='select'
                        isDisabled={offers.length < 1}
                      />
                      <div className='form-check form-check-custom form-check-solid mt-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexCheckDefault3'
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedWorldLocations(worldLocations)
                            } else {
                              setSelectedWorldLocations([])
                            }
                          }}
                        />
                        <label className='form-check-label' htmlFor='flexCheckDefault'>
                          Hepsini seç
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <Divider />
                <div className='row mt-4'>
                  <label className='fw-bold'>Rapor Kırılımları</label>
                  <div className='row'>
                    <div className='col-3 mt-4'>
                      <div className='form-check form-check-custom form-check-solid mt-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexCheckDefault1'
                          onChange={(e) => {
                            setChannelGroup(e.target.checked)
                          }}
                        />
                        <label className='form-check-label' htmlFor='flexCheckDefault'>
                          Kanal
                        </label>
                      </div>
                    </div>

                    <div className='col-3 mt-4'>
                      <div className='form-check form-check-custom form-check-solid mt-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexCheckDefault1'
                          onChange={(e) => {
                            setCreativeGroup(e.target.checked)
                          }}
                        />
                        <label className='form-check-label' htmlFor='flexCheckDefault'>
                          Kreatif
                        </label>
                      </div>
                    </div>

                    <div className='col-3 mt-4'>
                      <div className='form-check form-check-custom form-check-solid mt-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexCheckDefault1'
                          onChange={(e) => {
                            setVideoTypeGroup(e.target.checked)
                          }}
                        />
                        <label className='form-check-label' htmlFor='flexCheckDefault'>
                          Gösterim Tipi
                        </label>
                      </div>
                    </div>

                    <div className='col-3 mt-4'>
                      <div className='d-flex flex-column'>
                        <div className='form-check form-check-custom form-check-solid mt-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value=''
                            id='flexCheckDefault1'
                            onChange={(e) => {
                              setPeriodCheck(e.target.checked)
                            }}
                          />
                          <label className='form-check-label' htmlFor='flexCheckDefault'>
                            Period
                          </label>
                        </div>
                        {periodCheck && (
                          <ReactSelect
                            options={periods}
                            styles={customStyles}
                            value={periodGroup}
                            onChange={(e) => setPeriodGroup(e)}
                            classNamePrefix='select'
                            isDisabled={offers.length < 1}
                            className={'mt-2'}
                          />
                        )}
                      </div>
                    </div>

                    <div className='col-3 mt-4'>
                      <div className='form-check form-check-custom form-check-solid mt-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexCheckDefault1'
                          onChange={(e) => {
                            setOperatorGroup(e.target.checked)
                          }}
                        />
                        <label className='form-check-label' htmlFor='flexCheckDefault'>
                          Operatör
                        </label>
                      </div>
                    </div>

                    <div className='col-3 mt-4'>
                      <div className='form-check form-check-custom form-check-solid mt-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexCheckDefault1'
                          onChange={(e) => {
                            setDeviceGroup(e.target.checked)
                          }}
                        />
                        <label className='form-check-label' htmlFor='flexCheckDefault'>
                          Cihaz
                        </label>
                      </div>
                    </div>

                    <div className='col-3 mt-4'>
                      <div className='form-check form-check-custom form-check-solid mt-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexCheckDefault1'
                          onChange={(e) => {
                            setAgeGroup(e.target.checked)
                          }}
                        />
                        <label className='form-check-label' htmlFor='flexCheckDefault'>
                          Yaş
                        </label>
                      </div>
                    </div>

                    <div className='col-3 mt-4'>
                      <div className='form-check form-check-custom form-check-solid mt-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexCheckDefault1'
                          onChange={(e) => {
                            setGenderGroup(e.target.checked)
                          }}
                        />
                        <label className='form-check-label' htmlFor='flexCheckDefault'>
                          Cinsiyet
                        </label>
                      </div>
                    </div>

                    <div className='col-3 mt-4'>
                      <div className='form-check form-check-custom form-check-solid mt-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexCheckDefault1'
                          onChange={(e) => {
                            setLocationTurkeyGroup(e.target.checked)
                          }}
                        />
                        <label className='form-check-label' htmlFor='flexCheckDefault'>
                          Lokasyon Türkiye
                        </label>
                      </div>
                    </div>

                    <div className='col-3 mt-4'>
                      <div className='form-check form-check-custom form-check-solid mt-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexCheckDefault1'
                          onChange={(e) => {
                            setLocationWorldGroup(e.target.checked)
                          }}
                        />
                        <label className='form-check-label' htmlFor='flexCheckDefault'>
                          Lokasyon Dünya
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-center mt-4'>
                  <button className='btn btn-primary' onClick={createReport}>
                    Rapor Oluştur
                  </button>
                </div>
              </>
            )}
          </div>
          <div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>
            <div className='d-flex flex-column justify-content-end align-items-end'>
              <ReactSelect
                options={[
                  {label: 'Son 1 Hafta', value: {part: 1, month: 'weeks'}},
                  {label: 'Son 1 Ay', value: {part: 1, month: 'months'}},
                  {label: 'Son 3 Ay', value: {part: 3, month: 'months'}},
                  {label: 'Son 6 Ay', value: {part: 6, month: 'months'}},
                  {label: 'Tüm Zamanlar', value: {part: 10, month: 'years'}},
                ]}
                styles={{
                  control: (base) => {
                    return {
                      ...base,
                      minWidth: 150,
                    }
                  },
                  valueContainer: (provided, state) => ({
                    ...provided,
                    minWidth: '150px',
                  }),
                }}
                value={selectedReportTime}
                closeMenuOnSelect={true}
                onChange={(e) => setSelectedReportTime(e)}
                classNamePrefix='select'
              />
            </div>

            {reportsData && <Table data={reportsData} columns={customColumns} />}
          </div>
        </div>
      </KTCard>
      {modal && (
        <ConfirmModal
          showModal={showModal}
          dataParams={dataParams}
          id={agency_id}
          loadReports={loadReports}
        />
      )}
      {selectedDetail && <DetailModal showModal={setSelectedDetail} detail={selectedDetail} />}
    </>
  )
}

export default AgencyReports
