import moment from 'moment'
import {instance} from './http'

export const getChannelOffers = (publisher) => {
  return instance
    .get(`/publishers/${publisher}/channel-offers`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const getChannelOffer = (publisher, tvStationId, offerId) => {
  return instance
    .get(`/publishers/${publisher}/channels/${tvStationId}/offers/${offerId}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const offerStatu = (tvStationId, id, bodyFormData) => {
  return instance
    .post(`/channels/${tvStationId}/offers/${id}/status`, bodyFormData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    })
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const getOfferPrograms = (publisher, channelId, offerId) => {
  return instance
    .get(`/publishers/${publisher}/channels/${channelId}/offers/${offerId}/programs`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const dashboardOffers = (id) => {
  return instance
    .get(`/channels/${id}/dashboard/offers`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const dashboarReport = (id, start, end) => {
  return instance
    .get(`/channels/${id}/dashboard/report?start=${start}&end=${end}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const getTrailers = (publisher, part, month) => {
  let params = new URLSearchParams()
  params.append('time[part]', part.toString())
  params.append('time[month]', month.toString())
  return instance
    .get(`/publishers/${publisher}/trailers/time?${params}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const getTrailersWithoutTime = (publisher) => {
  return instance
    .get(`/publishers/${publisher}/trailers`)
    .then((res) => {
      return { success: true, data: res.data }
    })
    .catch((err) => {
      return { success: false, data: err.response }
    })
}

export const getPriorityDatatable = (publisher) => {
  return instance
    .get(`/publishers/${publisher}/trailers/priorityDatatable`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}
export const changePriorityDatatable = (publisher, query) => {
  return instance
    .get(`/publishers/${publisher}/trailers/priorityDatatable?${query}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const statusTrailer = (publisher, id, status) => {
  return instance
    .put(`/publishers/${publisher}/trailers/${id}/activity`, {
      status,
    })
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const broadcastTrailer = (publisher, id, status) => {
  return instance
    .put(`/publishers/${publisher}/trailers/${id}/broadcast`, {
      status,
    })
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const updateTrailer = (publisher, id, data) => {
  return instance
    .put(`/publishers/${publisher}/trailers/${id}`, data)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}
export const deleteTrailer = (publisher, id) => {
  return instance
    .delete(`/publishers/${publisher}/trailers/${id}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const getPublisherReport = (publisher) => {
  return instance
    .get(`/publishers/${publisher}/report`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const createPublisherReport = (publisher, params) => {
  params = new URLSearchParams(params).toString()
  return instance
    .post(`/publishers/${publisher}/report/downloadReport`, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    })
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const createMaestroReport = (publisher, params) => {
  params = new URLSearchParams(params).toString()
  return instance
    .post(`/publishers/${publisher}/report/downloadMaestroReport`, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    })
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const deletePublisherReport = (publisher, id) => {
  return instance
    .delete(`/publishers/${publisher}/report/${id}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const downloadPublisherReport = (publisher, id, name) => {
  return instance
    .post(
      `/publishers/${publisher}/report/download`,
      {
        reportId: id,
      },
      {
        responseType: 'blob',
      }
    )
    .then((res) => {
      const href = URL.createObjectURL(res.data)

      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      if (res.headers['content-type'] === 'text/csv') {
        link.setAttribute('download', `${name}.csv`) //or any other extension
      } else {
        link.setAttribute('download', `${name}.xlsx`) //or any other extension
      }
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const getPublisherChannelReport = (publisher) => {
  return instance
    .get(`/publishers/${publisher}/channel-report`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const getChannelsReport = (id) => {
  return instance
    .get(`/channels/${id}/report`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const getChannelsReportQuery = (id, query) => {
  return instance
    .get(`/channels/${id}/report/query?${query}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const getChannelReportEvents = (id, event) => {
  return instance
    .get(`/channels/${id}/report/events/${event}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const getChannelStat = (id, query) => {
  return instance
    .get(`/channels/${id}/stats?${query}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const graphEventTime = (id, eventId, date) => {
  let query = eventId
    ? `eventId=${eventId}`
    : date
    ? `start=${moment(date.start).unix()}&end=${moment(date.end).unix()}&interval=`
    : ''
  return instance
    .get(`/channels/${id}/report/graph/event/time?${query}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const graphEventAgencies = (id, eventId, date) => {
  let query = eventId
    ? `eventId=${eventId}`
    : date
    ? `start=${moment(date.start).unix()}&end=${moment(date.end).unix()}&interval=`
    : ''
  return instance
    .get(`/channels/${id}/report/graph/event/agencies?${query}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const graphEventGeneral = (id, eventId, date) => {
  let query = eventId
    ? `eventId=${eventId}`
    : date
    ? `start=${moment(date.start).unix()}&end=${moment(date.end).unix()}&interval=`
    : ''
  return instance
    .get(`/channels/${id}/report/graph/event/general?${query}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const graphEventDemographic = (id, eventId, date) => {
  let query = eventId
    ? `eventId=${eventId}`
    : date
    ? `start=${moment(date.start).unix()}&end=${moment(date.end).unix()}&interval=`
    : ''
  return instance
    .get(`/channels/${id}/report/graph/event/demographic?${query}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const graphEventGeographic = (id, eventId, date) => {
  let query = eventId
    ? `eventId=${eventId}`
    : date
    ? `start=${moment(date.start).unix()}&end=${moment(date.end).unix()}&interval=`
    : ''
  return instance
    .get(`/channels/${id}/report/graph/event/geographic?${query}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const graphEventDevice = (id, eventId) => {
  return instance
    .get(`/channels/${id}/report/graph/event/device?eventId=${eventId}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const graphDateTime = (id, date) => {
  let query = `start=${moment(date.start).startOf('day').unix()}&end=${moment(date.end)
    .endOf('day')
    .unix()}`
  return instance
    .get(`/channels/${id}/report/graph/date/time?${query}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const graphDateAgencies = (id, date) => {
  let query = `start=${moment(date.start).startOf('day').unix()}&end=${moment(date.end)
    .endOf('day')
    .unix()}`
  return instance
    .get(`/channels/${id}/report/graph/date/agencies?${query}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const graphDateGeneral = (id, date) => {
  let query = `start=${moment(date.start).startOf('day').unix()}&end=${moment(date.end)
    .endOf('day')
    .unix()}`
  return instance
    .get(`/channels/${id}/report/graph/date/general?${query}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const graphDateDemographic = (id, date) => {
  let query = `start=${moment(date.start).startOf('day').unix()}&end=${moment(date.end)
    .endOf('day')
    .unix()}`
  return instance
    .get(`/channels/${id}/report/graph/date/demographic?${query}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const graphDateGeographic = (id, date) => {
  let query = `start=${moment(date.start).startOf('day').unix()}&end=${moment(date.end)
    .endOf('day')
    .unix()}`
  return instance
    .get(`/channels/${id}/report/graph/date/geographic?${query}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const graphDateDaily = (id, date) => {
  let query = `start=${moment(date.start).startOf('day').unix()}&end=${moment(date.end)
    .endOf('day')
    .unix()}`
  return instance
    .get(`/channels/${id}/report/graph/date/daily?${query}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const getSchedule = (id) => {
  return instance
    .get(`/channels/${id}/schedule`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const getChannelsSchedule = (id, date) => {
  return instance
    .get(`/channels/${id}/events/daily?day=${date}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const updateChannelsSchedule = (id, programId, params) => {
  params = new URLSearchParams(params).toString()
  return instance
    .put(`/channels/${id}/events/${programId}`, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    })
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const updateChannelsProgram = (id, programId, params) => {
  params = new URLSearchParams(params).toString()
  return instance
    .put(`/channels/${id}/programs/${programId}`, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    })
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const deleteChannelsProgram = (id, programId) => {
  return instance
    .delete(`/channels/${id}/programs/${programId}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const getPublisherSchedule = (publisherId) => {
  return instance
    .get(`/publishers/${publisherId}/schedule`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const getPublisher = (publisherId) => {
  return instance
    .get(`/publishers/${publisherId}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}
