import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import Time from '../../../agency/offers/Time/Time'

function ShowDetailModal({showAddModal, data}) {
  const [time, setTime] = useState(data.endHour ?? '12:00')

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <div className='modal-header'>
              {/* begin::Modal title */}
              <h2 className='fw-bolder'>Yayını Görüntüle</h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => showAddModal(false)}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
              {/* end::Close */}
            </div>

            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-md-12 mb-4'>
                    <h5>Başlama Tarihi: </h5>
                    <span>{moment(data.startDate).format('DD-MM-YYYY')}</span>
                    <br />
                  </div>

                  <div className='row'>
                    <div className='col-md-6 mb-4'>
                      <h5>Başlama Saati: </h5>
                      <span>{data.startHour}</span>
                    </div>

                    <div className='col-md-6 mb-4'>
                      <h5>Bitiş Saati: </h5>
                      <span>{data.endHour}</span>
                    </div>
                  </div>
                  <br />

                  <div className='row'>
                    <div className='col-md-6 mb-4'>
                      <h5>Program: </h5>
                      <span>{data.program.title}</span>
                    </div>

                    <div className='col-md-6 mb-4'>
                      <div className='form-group'>
                        <h5>
                          <label htmlFor='programId' className='control-label'>
                            Yayın Tipi
                          </label>
                        </h5>
                        <div className='mt-radio-inline'>
                          <label className='mt-radio'>
                            <input
                              type='radio'
                              name='isReplay'
                              value='0'
                              checked
                              disabled='disabled'
                            />{' '}
                            Canlı
                            <span></span>
                          </label>
                          <label className='mt-radio ms-4'>
                            <input type='radio' name='isReplay' value='1' disabled='disabled' />
                            Tekrar
                            <span></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  {/* <div className='row'>
                      <div className="col-md-12 mb-4">
                        <h5>Tür </h5>
                        <span></span>
                      </div>
                    </div>

                    <div className='row'>
                      <div className="col-md-12 mb-4">
                        <h5>Etiketler </h5>
                        {data.program.tags.map((tag) => {
                          return <span className='tag' key={tag.id}>{tag.value}</span>
                        })}
                      </div>
                    </div> */}
                </div>
              </div>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export default ShowDetailModal
