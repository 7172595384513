import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import {KTCard} from '../../../../_metronic/helpers'
import {getChannelOffer} from '../../../services/publishers.service'
import 'shaka-player/dist/controls.css'
import ShakaPlayer from 'shaka-player-react'
import offerStatus from '../../../utils/offerStatus'
import moment from 'moment'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4maps from '@amcharts/amcharts4/maps'
import am4geodata_turkeyLow from '@amcharts/amcharts4-geodata/turkeyLow'
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table'
import {decodeEntities} from '../../../utils/format'

function ChannelOfferDetail() {
  let {publisher_id, channel_id, offer_id} = useParams()
  let nf = new Intl.NumberFormat('tr-TR', {style: 'currency', currency: 'TRY'})
  const [data, setData] = useState()
  const [polygonSeries, setPolygonSeries] = useState()
  const chartTr = useRef(null)
  const chartWorld = useRef(null)

  useEffect(() => {
    getChannelOffer(publisher_id, channel_id, offer_id).then((res) => {
      if (!res.success) {
        Swal.fire('Hata', res.data.data.errors[0].message, 'error')
      } else {
        setData(res.data)
      }
    })
  }, [])

  useEffect(() => {
    let x, y
    if (data?.offer.offer.location.tr.length > 0) {
      x = am4core.create('trMap', am4maps.MapChart)
      x.geodata = am4geodata_turkeyLow

      x.projection = new am4maps.projections.Miller()
      x.seriesContainer.draggable = false
      x.seriesContainer.resizable = false
      x.maxZoomLevel = 1

      let aa = createPolygonSeries(x)
      setPolygonSeries(aa)

      const polygonTemplate = createPolygonTemplate(aa)

      const hs = createActiveState(polygonTemplate)

      x.events.on('ready', function (ev) {
        if (data.offer.offer.location.tr[0] === 'ALL') {
          aa._data.forEach((v) => {
            let poly = aa.getPolygonById(v.id)
            if (poly) poly.isActive = true
          })
        } else {
          data.offer.offer.location.tr.forEach((v) => {
            let poly = aa.getPolygonById(`TR-${parseInt(v) < 10 ? '0' + v : v}`)
            if (poly) poly.isActive = true
          })
        }
      })

      chartTr.current = x
    }

    if (data?.offer.offer.location.world.length > 0) {
      y = am4core.create('worldMap', am4maps.MapChart)
      y.geodata = am4geodata_worldLow

      y.projection = new am4maps.projections.Miller()
      y.seriesContainer.draggable = false
      y.seriesContainer.resizable = false
      y.maxZoomLevel = 1

      let aaa = createPolygonSeries(y)
      setPolygonSeries(aaa)

      const polygonTemplate = createPolygonTemplate(aaa)

      const hs = createActiveState(polygonTemplate)

      y.events.on('ready', function (ev) {
        if (data.offer.offer.location.world[0] === 'ALL') {
          aaa._data.forEach((v) => {
            let poly = aaa.getPolygonById(v.id)
            if (poly) poly.isActive = true
          })
        } else {
          data.offer.offer.location.world.forEach((v) => {
            let poly = aaa.getPolygonById(`${v}`)
            if (poly) poly.isActive = true
          })
        }
      })

      chartWorld.current = y
    }

    return () => {
      if (x) x.dispose()
      if (y) y.dispose()
    }
  }, [data])

  function createPolygonSeries(chart) {
    const polygonSeries = chart.series.push(new am4maps.MapPolygonSeries())
    polygonSeries.useGeodata = true
    return polygonSeries
  }

  function createPolygonTemplate(polygonSeries, t) {
    const polygonTemplate = polygonSeries.mapPolygons.template
    polygonTemplate.tooltipText = '{name}'
    polygonTemplate.fill = am4core.color(t ? '#7FC5E4' : '#D7D7D7')
    return polygonTemplate
  }

  function createActiveState(polygonTemplate, t) {
    const activeState = polygonTemplate.states.create('active')
    activeState.properties.fill = am4core.color(t ? '#D7D7D7' : '#7FC5E4')
    return activeState
  }

  return (
    <div className='row'>
      {data && (
        <>
          <div className='col-12 col-md-5'>
            <KTCard className='p-4'>
              <div className='d-flex flex-column'>
                <label className='fs-3 fw-bold mb-2'>Teklif Detay</label>
                <div className='row'>
                  <div className='col-6 fw-bold'>Statü:</div>
                  <div className='col-6'>
                    <div className={`btn btn-${offerStatus(data.offer.status.id).color} btn-sm`}>
                      {offerStatus(data.offer.status.id).messageKey}
                    </div>
                  </div>
                </div>
                <div className='row mt-2'>
                  <div className='col-6 fw-bold'>Tarih:</div>
                  <div className='col-6'>
                    {moment(data.offer.offer.start).format('DD.MM.YYYY')} -{' '}
                    {moment(data.offer.offer.end).format('DD.MM.YYYY')}
                  </div>
                </div>
                <div className='row mt-2'>
                  <div className='col-6 fw-bold'>Ajans:</div>
                  <div className='col-6'>
                    {decodeEntities(data.offer.offer.campaign.agency.name)}
                  </div>
                </div>
                <div className='row mt-2'>
                  <div className='col-6 fw-bold'>Reklam Veren:</div>
                  <div className='col-6'>{decodeEntities(data.offer.offer.advertiser.name)}</div>
                </div>
                <div className='row mt-2'>
                  <div className='col-6 fw-bold'>Gerçekleşen Bütçe:</div>
                  <div className='col-6'>{nf.format(data.offer.spentBudget)}</div>
                </div>
                <div className='row mt-2'>
                  <div className='col-6 fw-bold'>Toplam İzlenme:</div>
                  <div className='col-6'>{data.offer.viewCount}</div>
                </div>
                <div className='row mt-2'>
                  <div className='col-6 fw-bold'>Toplam Tıklanma:</div>
                  <div className='col-6'>{data.offer.clickCount}</div>
                </div>
                {data.offer.type === 1 && (
                  <div className='row mt-2'>
                    <div className='col-6 fw-bold'>CPV (PSS):</div>
                    <div className='col-6'>
                      {nf.format(data.offer.pssPrice !== -1 ? data.offer.pssPrice : 0)}
                    </div>
                  </div>
                )}
                {data.offer.type === 1 && (
                  <div className='row mt-2'>
                    <div className='col-6 fw-bold'>CPV (NS):</div>
                    <div className='col-6'>
                      {nf.format(data.offer.nsPrice !== -1 ? data.offer.nsPrice : 0)}
                    </div>
                  </div>
                )}
                {data.offer.type !== 1 && (
                  <div className='row mt-2'>
                    <div className='col-6 fw-bold'>CPM:</div>
                    <div className='col-6'>{nf.format(data.offer.cpm)}</div>
                  </div>
                )}
              </div>
            </KTCard>
            <KTCard className='p-4 mt-4'>
              <div className='d-flex flex-column'>
                <label className='fs-3 fw-bold mb-2'>Hedef Kitle</label>
                <div className='row'>
                  <div className='col-6 fw-bold'>Yaş:</div>
                  <div className='col-6'>{data.offer.offer.age}</div>
                </div>
                <div className='row mt-2'>
                  <div className='col-6 fw-bold'>Cinsiyet:</div>
                  <div className='col-6'>{data.offer.offer.gender}</div>
                </div>
                <div className='row mt-2'>
                  <div className='col-6 fw-bold'>Cihaz:</div>
                  <div className='col-6'>{data.offer.offer.device}</div>
                </div>
                <div className='row mt-2'>
                  <div className='col-6 fw-bold'>Operatör:</div>
                  <div className='col-6'>{data.offer.offer.operator}</div>
                </div>
                <div className='row mt-2'>
                  <div className='col-6 fw-bold'>Saatler:</div>
                  <div className='col-6'>{data.offer.offer.partOfDay}</div>
                </div>
                <div className='row mt-2'>
                  <div className='col-6 fw-bold'>Günler:</div>
                  <div className='col-6'>{data.offer.offer.partOfWeek}</div>
                </div>
                <div className='mt-2 w-100'>
                  <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
                    {data.offer.offer.location.tr.length > 0 && (
                      <li className='nav-item'>
                        <a className='nav-link active' data-bs-toggle='tab' href='#tr'>
                          Yurtiçi
                        </a>
                      </li>
                    )}
                    {data.offer.offer.location.world.length > 0 && (
                      <li className='nav-item'>
                        <a className='nav-link' data-bs-toggle='tab' href='#world'>
                          Yurtdışı
                        </a>
                      </li>
                    )}
                  </ul>
                  <div className='tab-content' id='myTabContent'>
                    <div className='tab-pane fade active show' id='tr' role='tabpanel'>
                      <div
                        id='trMap'
                        className='mt-2'
                        style={{width: '100%', height: '300px'}}
                      ></div>
                    </div>
                    <div className='tab-pane fade' id='world' role='tabpanel'>
                      <div
                        id='worldMap'
                        className='mt-2'
                        style={{width: '100%', height: '300px'}}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </KTCard>
          </div>
          <div className='col-12 col-md-7'>
            <KTCard className='p-4'>
              <label className='fs-3 fw-bold mb-2'>Kreatifler</label>
              {data.offer.offer.creatives.map((v, i) => {
                return (
                  <div className='d-flex flex-column w-100' key={i}>
                    {v.creativeTypeId === 1 ? (
                      <ShakaPlayer width={200} src={v.url} />
                    ) : (
                      <img src={v.url} width={'100%'} />
                    )}
                    <label className='fw-bold'>Tıklama URL:</label>
                    <span>{decodeEntities(v.targetUrl)}</span>
                  </div>
                )
              })}
            </KTCard>
            <KTCard className='p-4 mt-4'>
              <BootstrapTable
                data={data.offer.programs}
                striped
                hover
                condensed
                options={{noDataText: 'Eşleşen Kayıt Bulunmadı'}}
              >
                <TableHeaderColumn
                  isKey={true}
                  dataField='id'
                  dataAlign='left'
                  dataSort={true}
                  width='250'
                  dataFormat={(cell, row) => {
                    return (
                      <span>
                        {decodeEntities(row.title)}({row.count})
                      </span>
                    )
                  }}
                >
                  Program
                </TableHeaderColumn>
                <TableHeaderColumn dataField='viewCount' dataAlign='center' dataSort={true}>
                  Reklam İzlenme
                </TableHeaderColumn>
                <TableHeaderColumn dataField='clickCount' dataAlign='center' dataSort={true}>
                  Reklam Tıklanma
                </TableHeaderColumn>
              </BootstrapTable>
            </KTCard>
          </div>
        </>
      )}
    </div>
  )
}

export default ChannelOfferDetail
