import * as Yup from 'yup'
import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {DateRange} from 'react-date-range'
import {KTSVG} from '../../../../../_metronic/helpers'
import {updateCampaign} from '../../../../services/campaigns.service'
import {useParams} from 'react-router-dom'
import clsx from 'clsx'
import moment from 'moment'
import {DatePicker} from 'antd'
import locale from 'antd/es/date-picker/locale/tr_TR'
import Swal from 'sweetalert2'
import {decodeEntities} from '../../../../utils/format'

const editCampaignSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
  advertiserId: Yup.number().required('Advertiser is required'),
  secondaryAdvertiserId: Yup.number().nullable(),
  start: Yup.date().required('Start date is required'),
  end: Yup.date().required('End date is required'),
})

const {RangePicker} = DatePicker

function UpdateModal({showUpdateModal, advertisers, fetchData, agency, data}) {
  let {agency_id} = useParams()

  const [date, setDate] = useState([
    {
      startDate: new Date(data.start),
      endDate: new Date(data.end),
      key: 'selection',
    },
  ])

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const [campaign] = useState({
    name: decodeEntities(data.name) || '',
    advertiserId: data.advertiser.id || null,
    secondaryAdvertiserId: data.secondaryAdvertiserId || null,
    start: data.start || '',
    end: data.end || '',
  })

  const formik = useFormik({
    initialValues: campaign,
    validationSchema: editCampaignSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const response = await updateCampaign(agency_id, data.id, values)
        if (response.success) {
          fetchData()
          showUpdateModal(null)
        } else {
          Swal.fire(
            'Bir sorunla karşılaştık.',
            response.data.data.errors
              ? response.data.data.errors[0].message
              : 'Lütfen tekrar deneyiniz.',
            'error'
          )
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })

  const disabledDate = (current) => {
    return current < moment().tz(agency.timezone).startOf('day')
  }

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <div className='modal-header'>
              {/* begin::Modal title */}
              <h2 className='fw-bolder'>Kampanya Güncelle</h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => showUpdateModal(null)}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <form
                id='kt_modal_add_user_form'
                className='form'
                onSubmit={formik.handleSubmit}
                noValidate
              >
                {/* begin::Scroll */}
                <div
                  className='d-flex flex-column scroll-y me-n7 pe-7'
                  id='kt_modal_add_user_scroll'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  data-kt-scroll-max-height='auto'
                  data-kt-scroll-dependencies='#kt_modal_add_user_header'
                  data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                  data-kt-scroll-offset='300px'
                >
                  {/* begin::Input group */}
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Kampanya Adı</label>
                    <input
                      placeholder='Name'
                      {...formik.getFieldProps('name')}
                      type='text'
                      name='name'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.name && formik.errors.name},
                        {
                          'is-valid': formik.touched.name && !formik.errors.name,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.name}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* end::Input group */}

                  {/* begin::Input group */}
                  <div className='mb-7'>
                    <label className='required fw-bold fs-6 mb-5'>Kampanya Dönemi</label>
                    <div className='w-100'>
                      <RangePicker
                        locale={locale}
                        className='w-100 mt-4'
                        disabledDate={(e) => disabledDate(e, data.start)}
                        defaultValue={[
                          moment(date[0].startDate, 'DD.MM.YYYY').tz(agency.timezone),
                          moment(date[0].endDate, 'DD.MM.YYYY').tz(agency.timezone),
                        ]}
                        format={'DD.MM.YYYY'}
                        onChange={(e) => {
                          setDate([
                            {
                              startDate: e[0],
                              endDate: e[1],
                              key: 'selection',
                            },
                          ])
                          formik.setFieldValue('start', moment(e[0]).format('YYYY-MM-DD'))
                          formik.setFieldValue('end', moment(e[1]).format('YYYY-MM-DD'))
                        }}
                      />
                      {/* <DateRange
                        editableDateInputs={true}
                        onChange={(item) => {
                          setDate([item.selection])
                          formik.setFieldValue(
                            'start',
                            moment(item.selection.startDate).format('YYYY-MM-DD')
                          )
                          formik.setFieldValue(
                            'end',
                            moment(item.selection.endDate).format('YYYY-MM-DD')
                          )
                        }}
                        moveRangeOnFirstSelection={false}
                        ranges={date}
                      /> */}
                    </div>
                  </div>
                  {/* end::Input group */}
                </div>
                {/* end::Scroll */}

                {/* begin::Actions */}
                <div className='text-center pt-15'>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                  >
                    <span className='indicator-label'>Gönder</span>
                    {formik.isSubmitting && (
                      <span className='indicator-progress'>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
                {/* end::Actions */}
              </form>
              {/* <UserEditModalFormWrapper /> */}
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export default UpdateModal
