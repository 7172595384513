import React, {createContext, useState, useEffect} from 'react'
const UserContext = createContext(undefined)
const UserDispatchContext = createContext(undefined)

const UserProvider = ({children}) => {
  const [user, setUser] = useState(localStorage.getItem('user'))

  useEffect(() => {
    if (user) {
      setUser(JSON.parse(user))
    }
  }, [])

  const loginUser = (data) => {
    localStorage.setItem('token', data.token)
    localStorage.setItem('user', JSON.stringify(data.user))
    setUser(data.user)
  }

  const logoutUser = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    document.location.reload()
  }

  return (
    <UserContext.Provider value={user}>
      <UserDispatchContext.Provider value={{loginUser, logoutUser}}>
        {children}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  )
}
export {UserProvider, UserContext, UserDispatchContext}
