import {useEffect, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import {KTCard, KTSVG} from '../../../../_metronic/helpers'
import Swal from 'sweetalert2/dist/sweetalert2.js'

import TableHeader from '../../agency/creatives/components/TableHeader'
import Table from '../../agency/creatives/components/Table'
import {
  getCreatives,
  statusCreative,
  deleteCreative,
  getCreativesWithoutTime,
} from '../../../services/creatives.service'
import AddModal from './components/AddModal'
import ShowDetailModal from './components/ShowDetailModal'
import AddCallToActionModal from './components/AddCallToActionModal'
import ReactSelect from 'react-select'
import moment from 'moment'
import EditModal from './components/EditModal'
import {decodeEntities} from '../../../utils/format'

const Creatives = () => {
  let {agency_id} = useParams()
  const [data, setData] = useState([])
  const [addModal, setAddModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [callToActionModal, setCallToActionModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [creativeName, setCreativeName] = useState('')
  const [creativeUrl, setCreativeUrl] = useState('')
  const [creativeType, setCreativeType] = useState(1)
  const [creativeObj, setCreativeObj] = useState({})
  const [advertisers, setAdvertisers] = useState([])
  const [canIntervalWithTime, setCanIntervalWithTime] = useState(false)
  const [editData, setEditData] = useState(null)
  const [selectedTime, setSelectedTime] = useState({
    label: 'Son 1 Hafta',
    value: {
      part: 1,
      month: 'weeks',
    },
  })

  const intervalRef = useRef()
  const modalRef = useRef()
  modalRef.current = addModal

  const changeStatus = async (id, status) => {
    setLoading(true)
    const res = await statusCreative(agency_id, id, status)
    if (res.success) {
      const index = data.findIndex((x) => x.id === id)
      let temp = [...data]
      temp[index] = res.data
      setData(temp)
    }
    setLoading(false)
  }

  const customColumns = [
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          // onClick={sortColumn}
        >
          Müşteri
        </th>
      ),
      id: 'advertiser.name',
      Cell: ({...props}) => (
        <span>
          {props.row.original.advertiser.superAdvertiser
            ? decodeEntities(props.row.original.advertiser.superAdvertiser.name)
            : decodeEntities(props.row.original.advertiser.name)}
        </span>
      ),
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          // onClick={sortColumn}
        >
          Marka
        </th>
      ),
      id: 'marka',
      Cell: ({...props}) => <span>{decodeEntities(props.row.original.superAdvertiser) ? '' : '-'}</span>,
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          style={{cursor: 'pointer'}}
          // onClick={sortColumn}
        >
          Reklam Adı
        </th>
      ),
      accessor: 'name',
      Cell: ({...props}) => (
        <span>
          {decodeEntities(props.row.original.name)}
        </span>
      ),
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          style={{cursor: 'pointer'}}
          // onClick={sortColumn}
        >
          Vast
        </th>
      ),
      id: 'isVast',
      Cell: ({...props}) => (
        <span>
          {props.row.original.isVast ? (
            <KTSVG
              path='/media/icons/duotune/general/gen043.svg'
              className='svg-icon-2 me-0 svg-icon-success'
            />
          ) : (
            <KTSVG
              path='/media/icons/duotune/general/gen040.svg'
              className='svg-icon-2 me-0 svg-icon-danger'
            />
          )}
        </span>
      ),
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          style={{cursor: 'pointer'}}
          // onClick={sortColumn}
        >
          Tıklanabilir
        </th>
      ),
      id: 'isClick',
      Cell: ({...props}) => (
        <span>
          {props.row.original.isVast ? (
            <KTSVG
              path='/media/icons/duotune/general/gen043.svg'
              className='svg-icon-2 me-0 svg-icon-success'
            />
          ) : (
            <KTSVG
              path='/media/icons/duotune/general/gen040.svg'
              className='svg-icon-2 me-0 svg-icon-danger'
            />
          )}
        </span>
      ),
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          // onClick={sortColumn}
        >
          İçerik Tipi
        </th>
      ),
      id: 'donem',
      Cell: ({...props}) => (
        <span>
          {props.row.original.creativeTypeId == 1
            ? 'Video'
            : props.row.original.creativeTypeId == 2
            ? 'Overlay'
            : ''}
        </span>
      ),
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-150px'
          // onClick={sortColumn}
        >
          Call to Action
        </th>
      ),
      id: 'call-to-action',
      Cell: ({...props}) => (
        <div>
          {props.row.original.creativeTypeId == 1 ? (
            props.row.original.heading &&
            props.row.original.button &&
            props.row.original.displayUrl ? (
              <span>
                <div>
                  <b>Başlık: </b>
                  {decodeEntities(props.row.original.heading)}
                  <br />
                </div>
                <div>
                  <b>Buton: </b>
                  {decodeEntities(props.row.original.button)}
                  <br />
                </div>
                <div>
                  <b>URL: </b>
                  {decodeEntities(props.row.original.displayUrl)}
                  <br />
                </div>
                <div>
                  <button
                    href='#'
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    onClick={() => {
                      setCallToActionModal(true)
                      setCreativeObj(props)
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                  </button>
                </div>
              </span>
            ) : (
              <button
                type='button'
                className='btn btn-primary'
                onClick={() => {
                  setCallToActionModal(true)
                  setCreativeObj(props)
                }}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2 me-0' />
              </button>
            )
          ) : (
            <span>-</span>
          )}
        </div>
      ),
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          // onClick={sortColumn}
        >
          Durumu
        </th>
      ),
      id: 'status',
      Cell: ({...props}) =>
        loading ? (
          <div data-kt-indicator='on'>
            <span className='indicator-progress'>
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          </div>
        ) : (
          <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              value=''
              name='notifications'
              defaultChecked={props.row.original.isActive}
              onChange={(e) => {
                changeStatus(props.row.original.id, !props.row.original.isActive)
              }}
            />
          </div>
        ),
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          // onClick={sortColumn}
        >
          Yayın Statüsü
        </th>
      ),
      id: 'ready-status',
      Cell: ({...props}) => <span>{props.row.original.status.messageKey}</span>,
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px d-flex justify-content-end'
          // onClick={sortColumn}
        >
          İşlemler
        </th>
      ),
      id: 'actions',
      Cell: ({...props}) => (
        <div className='d-flex justify-content-end flex-shrink-0'>
          {props.row.original.url && (
            <button
              href='#'
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => {
                setShowModal(true)
                setCreativeName(props.row.original.name)
                setCreativeUrl(props.row.original.url)
                setCreativeType(props.row.original.creativeTypeId)
              }}
            >
              <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1' />
            </button>
          )}
          <button
            href='#'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => {
              setEditData(props.row.original)
            }}
          >
            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-1' />
          </button>

          <button
            href='#'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            onClick={() => {
              Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                preConfirm: async (login) => {
                  const res = await deleteCreative(agency_id, props.row.original.id)
                  if (res.success) {
                    Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
                    fetchData()
                  } else {
                    Swal.fire('Error!', res.data.data.errors[0].message)
                  }
                },
                allowOutsideClick: () => !Swal.isLoading(),
              })
            }}
          >
            <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
          </button>
        </div>
      ),
    },
  ]

  async function fetchData() {
    const creatives = await getCreatives(
      agency_id,
      selectedTime.value.part,
      selectedTime.value.month
    )
    setData(creatives.data)
  }

  async function fetchDataWithoutTime() {
    const creatives = await getCreativesWithoutTime(
      agency_id,
      selectedTime.value.part,
      selectedTime.value.month
    )
    setData(creatives.data.ads)
    setAdvertisers(creatives.data.advertisersForCreativeAndVastJSON)
    setCanIntervalWithTime(true)
  }

  useEffect(() => {
    if (canIntervalWithTime) {
      fetchData()
      intervalRef.current = setInterval(() => {
        fetchData()
      }, 15000)
    }

    return () => {
      clearInterval(intervalRef.current)
    }
  }, [selectedTime, canIntervalWithTime])

  useEffect(() => {
    fetchDataWithoutTime()
  }, [])

  return (
    <>
      <KTCard>
        <TableHeader showAddModal={setAddModal} title='Görseller' />
        {data && (
          <div className='d-flex flex-row-reverse pe-4'>
            <ReactSelect
              options={[
                {
                  label: 'Son 1 Hafta',
                  value: {
                    part: 1,
                    month: 'weeks',
                  },
                },
                {
                  label: 'Son 1 Ay',
                  value: {
                    part: 1,
                    month: 'months',
                  },
                },
                {
                  label: 'Son 3 Ay',
                  value: {
                    part: 3,
                    month: 'months',
                  },
                },
                {
                  label: 'Son 6 Ay',
                  value: {
                    part: 6,
                    month: 'months',
                  },
                },
                {
                  label: 'Tüm Zamanlar',
                  value: {
                    part: 10,
                    month: 'years',
                  },
                },
              ]}
              styles={{
                control: (base) => {
                  return {
                    ...base,
                    minWidth: 150,
                    maxWidth: 200,
                  }
                },
                valueContainer: (provided, state) => ({
                  ...provided,
                  minWidth: '150px',
                  maxWidth: '200px',
                }),
              }}
              value={selectedTime}
              closeMenuOnSelect={true}
              onChange={(e) => setSelectedTime(e)}
              classNamePrefix='select'
            />
          </div>
        )}
        {data && <Table data={data} columns={customColumns} />}
      </KTCard>

      {addModal && (
        <AddModal showAddModal={setAddModal} advertisers={advertisers} fetchData={fetchData} />
      )}

      {showModal && (
        <ShowDetailModal
          showAddModal={setShowModal}
          creativeName={creativeName}
          creativeUrl={creativeUrl}
          creativeType={creativeType}
        />
      )}

      {callToActionModal && (
        <AddCallToActionModal
          creativeObj={creativeObj}
          setCallToActionModal={setCallToActionModal}
          creativeName={creativeName}
          fetchData={fetchData}
        />
      )}
      {editData && <EditModal data={editData} toggleModal={setEditData} fetchData={fetchData} />}
    </>
  )
}

export {Creatives}
