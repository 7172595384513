import React, {memo, useState, useRef} from 'react'
import clsx from 'clsx'
import {useParams} from 'react-router-dom'
import {LinearProgress} from '@mui/material'
import {instance} from '../../../../services/http'

import {addCreative} from '../../../../services/creatives.service'
import Button from '@mui/material/Button'
import Swal from 'sweetalert2'
import Player from './Player'

const AddModalForm = ({advertisers, showAddModal, fetchData}) => {
  let {agency_id} = useParams()
  const hiddenFileInput = useRef(null)
  const [loading, setLoading] = useState(false);

  const [formValue, setFormValues] = useState({
    name: '',
    advertiserId: '',
    vastUrl: '',
    creativeName: '',
    isVast: true,
    file: null,
    targetUrl: "",
    trackingUrl: "",
    completionUrl: ""
  })

  const [progress, setProgress] = useState(0)

  const addCreative = (agency, data) => {
    let formData;
    if(data.isVast){
      formData = data
    formData = new URLSearchParams(data).toString()
    }else{
      formData = new FormData()
    Object.keys(data).forEach((v) => {
      if (v !== 'file') formData.append(v, data[v])
    })
    formValue.file && formData.append('files[]', formValue.file)
    }


    return instance
      .post(`/agencies/${agency}/creatives`, formData, {
        headers: {
          'Content-Type': data.isVast ? 'application/x-www-form-urlencoded' : 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 50
          setProgress(progress)
        },
        onDownloadProgress: (progressEvent) => {
          const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50
          setProgress(progress)
        },
      })
      .then((res) => {
        setProgress(0)
        return {success: true, data: res.data}
      })
      .catch((err) => {
        setProgress(0)
        return {success: false, data: err.response}
      })
  }

const handleSubmit = async() => {
  setLoading(true)
  let form = formValue;
  form.advertiserId = parseInt(form.advertiserId)
  form.creativeName = form.name;
  if(form.isVast){
    delete form.file;
    delete form.targetUrl
    delete form.trackingUrl
    delete form.completionUrl
  }else{
    delete form.vastUrl
    delete form.creativeName
    delete form.isVast
  }
  const response = await addCreative(agency_id, form)

  if (response.success) {
    if (response.data.files && response.data.files[0].error) {
      Swal.fire('Hata', response.data.files[0].error, 'error')
    } else {
      fetchData()
      showAddModal(false)
    }
  }else{
      Swal.fire('Hata', response.data.data.errors[0].message, 'error')
  }
  setLoading(false)
}

  return (
    <>
      <div className='mt-radio-inline d-flex justify-content-evenly mt-4'>
        <label className='mt-radio'>
          <input
            type='radio'
            name='adType'
            id='manual'
            value='M'
            onClick={() => {
              setFormValues({...formValue, isVast: false})
            }}
            checked={!formValue.isVast}
          />{' '}
          Kreatif
          <span></span>
        </label>
        <label className='mt-radio'>
          <input
            type='radio'
            name='adType'
            id='vast'
            value='V'
            onClick={() => {
              setFormValues({...formValue, isVast: true})
            }}
            checked={formValue.isVast}
          />{' '}
          VAST
          <span></span>
        </label>
      </div>

      <form
        id='kt_modal_add_user_form'
        className='form'
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmit()
        }}
        noValidate
      >
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Kreatif Adı</label>
            <input
              placeholder='Kreatif Adı'
              onChange={(e) => setFormValues({...formValue, name: e.target.value})}
              type='text'
              name='name'
              className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
              autoComplete='off'
            />
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Müşteri</label>
            <select
              className='form-select'
              aria-label='Select example'
              onChange={(e) => {
                setFormValues({...formValue, advertiserId: e.target.value})
              }}
            >
              <option value={''}>Müşteri Seç</option>
              {advertisers &&
                advertisers.length > 0 &&
                advertisers.map((v, i) => {
                  return (
                    <option key={i} value={v.id}>
                      {v.name}
                    </option>
                  )
                })}
            </select>
          </div>
          {formValue.isVast && (
            <>
              <div className='mb-7'>
                <label className='required fw-bold fs-6 mb-5'>VAST Url</label>
                <input
                  placeholder='VAST URL'
                  onChange={(e) => {
                    setFormValues({...formValue, vastUrl: e.target.value})
                  }}
                  type='text'
                  name='vastUrl'
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  autoComplete='off'
                />
              </div>
            </>
          )}

          {!formValue.isVast && (
            <>
              <label className='fs-8 mb-2 d-block'>
                (Video için MP4 formatı kabul edilmektedir. Maksimum dosya boyutu 200MB. Image
                dosyası için 728*90 ölçülerinde JPG, JPEG, GIF, PNG formatları kabul edilmektedir.
                Maksimum dosya boyutu 150 KB.)
              </label>
              <Button
                variant='contained'
                component='label'
                className='my-2 mx-2'
                color='primary'
                onClick={() => {
                  // hiddenFileInput.current.click()
                }}
              >
                {formValue.file?.name ?? 'Dosya Ekle'}
                <input
                  ref={hiddenFileInput}
                  onChange={(e) => {
                    let file = e.target.files ? e.target.files[0] : null

                    if (file && file.type !== 'video/mp4' && file.size / 1024 / 1024 > 200) {
                      Swal.fire('Hata!', "Dosya boyutu 200MB'dan fazla olamaz.", 'error')
                      setFormValues({...formValue, file: null})
                      return
                    }

                    if (
                      file &&
                      !(
                        file.type !== 'image/png' ||
                        file.type !== 'image/gif' ||
                        file.type !== 'image/jpg' ||
                        file.type !== 'image/jpeg'
                      )
                    ) {
                      Swal.fire(
                        'Hata!',
                        'JPG, JPEG, GIF, PNG formatları kabul edilmektedir',
                        'error'
                      )
                      setFormValues({...formValue, file: null})
                      return
                    } else if (
                      file.type !== 'image/png' ||
                      file.type !== 'image/gif' ||
                      file.type !== 'image/jpg' ||
                      file.type !== 'image/jpeg'
                    ) {
                      var reader = new FileReader()

                      //Read the contents of Image File.
                      reader.readAsDataURL(file)
                      reader.onload = function (e) {
                        //Initiate the JavaScript Image object.
                        var image = new Image()

                        //Set the Base64 string return from FileReader as source.
                        image.src = e.target.result

                        //Validate the File Height and Width.
                        image.onload = function () {
                          var height = this.height
                          var width = this.width
                          if (height !== 90 || width !== 728) {
                            Swal.fire('Hata!', '728*90 ölçülerinde kabul edilmektedir.', 'error')
                            return
                          }
                        }
                      }
                    }

                    setFormValues({...formValue, file: file})
                  }}
                  type='file'
                  accept='.mp4,.png,.jpg,.jpeg,.gif'
                  hidden
                />
              </Button>

              {formValue.file && formValue.file.type.includes('video') && (
                <div>
                  <Player file={formValue.file} />
                </div>
              )}

              {formValue.file && (
                <>
                  <div className='fv-row mb-7 mt-4'>
                    <label className='required fw-bold fs-6 mb-2'>Tıklama URL</label>
                    <input
                      placeholder='Tıklama URL'
                      onChange={(e) => setFormValues({...formValue, targetUrl: e.target.value})}
                      type='text'
                      name='name'
                      className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                      autoComplete='off'
                    />
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>İzleme Kodu</label>
                    <input
                      placeholder='İzleme Kodu'
                      onChange={(e) => setFormValues({...formValue, trackingUrl: e.target.value})}
                      type='text'
                      name='name'
                      className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                      autoComplete='off'
                    />
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Tamamlanma Takip Kodu</label>
                    <input
                      placeholder='Tamamlanma Takip Kodu'
                      onChange={(e) => setFormValues({...formValue, completionUrl: e.target.value})}
                      type='text'
                      name='name'
                      className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                      autoComplete='off'
                    />
                  </div>
                </>
              )}
            </>
          )}

          <LinearProgress variant='determinate' value={progress} />
          <div className='text-center pt-2'>
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={loading}
            >
              <span className='indicator-label'>Gönder</span>
            </button>
          </div>
        </div>
      </form>
    </>
  )
}

export default memo(AddModalForm)
