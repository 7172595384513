import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import {
  deleteChannelsProgram,
  getChannelsSchedule,
  getSchedule,
  getPublisherSchedule,
} from '../../../services/publishers.service'
import {KTCard, KTSVG} from '../../../../_metronic/helpers'
import TableHeader from './components/TableHeader'
import Table from './components/Table'
import '../../../..//_metronic/assets/sass/components/schedule.scss'
import ShowSchedule from './components/ShowSchedule'
import moment from 'moment'
import {UserContext} from '../../../../app/contexts/auth'
import UpdateSchedule from './components/UpdateSchedule'
import ProgramDetailModal from './components/ProgramDetail'
import ProgramUpdateModal from './components/ProgramUpdate'
import Swal from 'sweetalert2'
import {decodeEntities} from '../../../utils/format'
import ReactSelect from 'react-select'

function Schedule() {
  const paginatorRef = useRef()
  const userDetails = React.useContext(UserContext)
  const [data, setData] = useState([])
  const [programs, setPrograms] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [updateModal, setUpdatedModal] = useState(false)
  const [sheduleDate, setScheduleDate] = useState(moment(new Date()).format('YYYY-MM-DD'))
  const [selectedDate, setSelectedDate] = useState(null)
  const [channels, setChannels] = useState([])
  const [selectedChannel, setSelectedChannel] = useState(null)

  const [selectedProgram, setSelectedProgram] = useState(null)
  const [updateProgram, setUpdateProgram] = useState(null)

  const customColumns = [
    {
      Header: (props) => (
        <th {...props.column.getHeaderProps()} className='min-w-125px'>
          Program Saati
        </th>
      ),
      id: 'time',
      Cell: ({...props}) => {
        let value = props.row.original
        return (
          <span>
            {value.startHour} - {value.endHour}
          </span>
        )
      },
    },
    {
      Header: (props) => (
        <th {...props.column.getHeaderProps()} className='min-w-125px'>
          Program Adı
        </th>
      ),
      id: 'program.title',
      Cell: ({...props}) => {
        let value = props.row.original
        let title = decodeEntities(value.program.title)
        return <span>{title}</span>
      },
    },
    // {
    //   Header: (props) => (
    //     <th {...props.column.getHeaderProps()} className='min-w-125px'>
    //       Tür
    //     </th>
    //   ),
    //   id: 'id',
    //   Cell: ({...props}) => {
    //     return <span></span>
    //   },
    // },
    // {
    //   Header: (props) => (
    //     <th {...props.column.getHeaderProps()} className='min-w-125px'>
    //       Etiketler
    //     </th>
    //   ),
    //   id: 'endHour',
    //   Cell: ({...props}) =>
    //     props.row.original.program.tags.map((tag) => {
    //       return (
    //         <span className='tag' key={tag.id}>
    //           {tag.value}
    //         </span>
    //       )
    //     }),
    // },
    {
      Header: (props) => (
        <th {...props.column.getHeaderProps()} className='min-w-125px text-end'>
          İşlemler
        </th>
      ),
      id: 'actions',
      Cell: ({...props}) => (
        <div className='d-flex justify-content-end flex-shrink-0'>
          <button
            href='#'
            className='btn btn-secondary btn-sm'
            onClick={() => {
              setShowModal(true)
              setSelectedDate(props.row.original)
            }}
          >
            <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-3 me-0' />
          </button>
          {moment().isBefore(moment(props.row.original.start)) ? (
            <>
              <button
                href='#'
                className='btn btn-info btn-sm ms-2'
                onClick={() => {
                  setUpdatedModal(true)
                  setSelectedDate(props.row.original)
                }}
              >
                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3 me-0' />
              </button>
              {/* <button href='#' className='btn btn-danger btn-sm ms-2'>
                <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
              </button> */}
            </>
          ) : (
            ''
          )}
        </div>
      ),
    },
  ]

  const programsColumns = [
    {
      Header: (props) => (
        <th {...props.column.getHeaderProps()} className='min-w-125px'>
          Program Adı
        </th>
      ),
      id: 'title',
      Cell: ({...props}) => {
        let value = props.row.original
        let title = decodeEntities(value.title)
        return <span>{title}</span>
      },
    },
    {
      Header: (props) => (
        <th {...props.column.getHeaderProps()} className='min-w-125px'>
          Açıklama
        </th>
      ),
      id: 'desc',
      Cell: ({...props}) => {
        let value = props.row.original
        return <span>{value.description}</span>
      },
    },
    {
      Header: (props) => (
        <th {...props.column.getHeaderProps()} className='min-w-125px'>
          PSS
        </th>
      ),
      id: 'pss',
      Cell: ({...props}) => {
        let value = props.row.original
        return (
          <span>
            {value.pssEnabled ? (
              <KTSVG path='/media/icons/duotune/general/gen043.svg' className='svg-icon-3' />
            ) : (
              <KTSVG path='/media/icons/duotune/general/gen040.svg' className='svg-icon-3' />
            )}
          </span>
        )
      },
    },
    {
      Header: (props) => (
        <th {...props.column.getHeaderProps()} className='min-w-125px'>
          PSS Delay
        </th>
      ),
      id: 'pssdelay',
      Cell: ({...props}) => {
        let value = props.row.original
        return <span>{value.pssEnabled ? value.pssDelay + 'sn' : '-'}</span>
      },
    },
    {
      Header: (props) => (
        <th {...props.column.getHeaderProps()} className='min-w-125px'>
          PSS Drift
        </th>
      ),
      id: 'pssdrift',
      Cell: ({...props}) => {
        let value = props.row.original
        return <span>{value.pssEnabled ? value.pssDrift + 'sn' : '-'}</span>
      },
    },
    {
      Header: (props) => (
        <th {...props.column.getHeaderProps()} className='min-w-125px'>
          Overlay Delay
        </th>
      ),
      id: 'overlaydelay',
      Cell: ({...props}) => {
        let value = props.row.original
        return <span>{value.overlayDelay}sn</span>
      },
    },
    {
      Header: (props) => (
        <th {...props.column.getHeaderProps()} className='min-w-125px text-end'>
          İşlemler
        </th>
      ),
      id: 'actions',
      Cell: ({...props}) => (
        <div className='d-flex justify-content-end flex-shrink-0'>
          <button
            href='#'
            className='btn btn-secondary btn-sm'
            onClick={() => {
              setSelectedProgram(props.row.original)
            }}
          >
            <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-3 me-0' />
          </button>
          <>
            <button
              href='#'
              className='btn btn-info btn-sm ms-2'
              onClick={() => {
                setUpdateProgram(props.row.original)
              }}
            >
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3 me-0' />
            </button>
            <button
              href='#'
              className='btn btn-danger btn-sm ms-2'
              onClick={() => {
                Swal.fire({
                  title: 'Silmek istediğine emin misin?',
                  text: 'Sildiğinde geri dönüşü olmayacak!',
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  cancelButtonText: 'İptal',
                  confirmButtonText: 'Evet, sil!',
                  preConfirm: async () => {
                    const res = await deleteChannelsProgram(userDetails.id, props.row.original.id)
                    if (res.success) {
                      Swal.fire('Silindi!', 'Rapor başarıyla silindi.', 'success')
                      fetchData()
                    } else {
                      Swal.fire('Error!', res.data.data.errors[0].message)
                    }
                  },
                  allowOutsideClick: () => !Swal.isLoading(),
                })
              }}
            >
              <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3 me-0' />
            </button>
          </>
        </div>
      ),
    },
  ]

  async function fetchData() {
    const publisherData = await getPublisherSchedule(userDetails.pubId)
    let channelArr = []
    publisherData.data.publisher.channels.forEach((v) => {
      channelArr.push({label: v.name, value: v.id})
    })
    setChannels(channelArr)
    setSelectedChannel({label: channelArr[0].label, value: channelArr[0].value})
  }

  const getData = async () => {
    const data = await getChannelsSchedule(selectedChannel.value, sheduleDate)
    if (data.success) {
      setData(data.data)
    }
    const res = await getSchedule(selectedChannel.value)
    if (res.success) {
      let progs = res.data.programs
      progs = progs.sort((a, b) => (a.title > b.title ? 1 : -1))
      setPrograms(progs)
    }
  }

  useEffect(() => {
    if (selectedChannel) {
      getData()
    }
  }, [selectedChannel])

  useEffect(() => {
    fetchData()
  }, [sheduleDate])

  useLayoutEffect(() => {
    if (paginatorRef.current) {
      setTimeout(() => {
        window.$('#datepaginator').datepaginator({
          language: 'tr',
          startDate: '2000-01-01',
          endDate: '2050-01-01',
          hint: 'dddd, DD MMMM YYYY',
          offDays: 'Cts,Paz',
          text: 'ddd<br/>D/M',
          textSelected: 'dddd<br/>DD MMMM YYYY',
          onSelectedDateChanged: function (a, t) {
            setScheduleDate(t._i)
          },
        })
      }, 1000)
    }
  }, [paginatorRef.current])

  return (
    <>
      {channels.length > 1 && (
        <div className='col-12'>
          <KTCard className='p-4'>
            <ReactSelect
              options={channels}
              value={selectedChannel}
              onChange={(e) => setSelectedChannel(e)}
            />
          </KTCard>
        </div>
      )}
      <KTCard className='p-4 mt-4'>
        <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
          <li className='nav-item'>
            <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_1'>
              Yayın Akışı
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_2'>
              Program
            </a>
          </li>
        </ul>
        <div className='tab-content' id='myTabContent'>
          <div className='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
            <div className='row'>
              <div id='datepaginator' ref={paginatorRef}></div>
            </div>

            <KTCard>
              <TableHeader title='Yayın Akışı' />
              {data && <Table data={data} columns={customColumns} />}
            </KTCard>
          </div>
          <div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>
            <KTCard>
              <TableHeader title='Yayın Akışı' />
              {data && <Table data={programs} columns={programsColumns} />}
            </KTCard>
          </div>
        </div>
      </KTCard>

      {showModal && <ShowSchedule showAddModal={setShowModal} data={selectedDate} />}

      {updateModal && (
        <UpdateSchedule
          showUpdateModal={setUpdatedModal}
          data={selectedDate}
          programs={programs}
          fetchData={fetchData}
        />
      )}

      {selectedProgram && (
        <ProgramDetailModal showModal={setSelectedProgram} program={selectedProgram} />
      )}
      {updateProgram && (
        <ProgramUpdateModal
          showModal={setUpdateProgram}
          program={updateProgram}
          fetchData={fetchData}
        />
      )}
    </>
  )
}

export default Schedule
