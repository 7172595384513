import React, {useEffect, useRef, useState} from 'react'
import {KTCard} from '../../../../_metronic/helpers'
import {DatePicker} from 'antd'
import moment from 'moment-timezone/builds/moment-timezone-with-data'
import locale from 'antd/es/date-picker/locale/tr_TR'
import {
  getChannelsReport,
  getPublisherChannelReport,
  getChannelsReportQuery,
  getChannelReportEvents,
  getChannelStat,
  graphEventTime,
  graphEventAgencies,
  graphEventGeneral,
  graphEventDemographic,
  graphEventGeographic,
  graphEventDevice,
  graphDateAgencies,
  graphDateGeneral,
  graphDateDemographic,
  graphDateGeographic,
  graphDateDaily,
  getPublisherSchedule,
} from '../../../services/publishers.service'
import {useLocation, useParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import ReactSelect from 'react-select'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table'
import * as am4core from '@amcharts/amcharts4/core'

import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import {useThemeMode} from '../../../../_metronic/partials'

import './style.scss'
import {Alert} from '@mui/material'
import {
  clickChart,
  clickRateChart,
  geographicHeatMap,
  pieChart,
  reachChart,
  simultaneousAdViewerGraph,
  simultaneousViewerGraph,
  spentBudgetChart,
} from '../../../utils/charts'
import {UserContext} from '../../../contexts/auth'
import {decodeEntities} from '../../../utils/format'

const {RangePicker} = DatePicker

const customStyles = {
  multiValue: (base, state) => {
    return {...base, backgroundColor: '#0e0e15'}
  },
  multiValueLabel: (base, state) => {
    return {...base, fontWeight: 'bold', color: '#92929F', paddingRight: 6}
  },
  option: (base) => {
    return {...base, color: '#92929F', backgroundColor: '#1e1e2d'}
  },
  menu: (base) => {
    return {...base, color: '#92929F', backgroundColor: '#1e1e2d'}
  },
  control: (base) => {
    return {
      ...base,
      color: '#92929F',
      backgroundColor: '#1e1e2d',
      borderColor: '#323248',
      maxHeight: 150,
    }
  },
  valueContainer: (provided, state) => ({
    ...provided,
    maxHeight: '150px',
    overflow: 'scroll',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#fff',
  }),
}
let nf = new Intl.NumberFormat('tr-TR', {
  style: 'currency',
  currency: 'TRY',
  minimumFractionDigits: 0,
})

am4core.useTheme(am4themes_animated)

function ChannelRerports() {
  let {publisher_id} = useParams()
  const {mode} = useThemeMode()
  const userDetails = React.useContext(UserContext)

  const location = useLocation()

  const chartajansbutce = useRef()
  const chartajansizlenme = useRef()
  const chartcogtr = useRef()
  const chartcogworld = useRef()
  const chartdemoyas = useRef()
  const chartdemocinsiyet = useRef()
  const chartgeneral = useRef()
  const charttiklanma = useRef()
  const charttiklanmaoran = useRef()
  const chartgerceklesen = useRef()
  const chartizlenme = useRef()
  const chartbutce = useRef()
  const chartreklam = useRef()
  const chartanlik = useRef()
  const chartgunluk = useRef()

  const intervalRef = useRef()
  const intervalDateRef = useRef()

  const [type, setType] = useState('date')
  const [selectedDate, setSelectedDate] = useState({
    start: moment().add(-6, 'days'),
    end: moment(),
  })
  const [selectedProgram, setSelectedProgram] = useState(null)
  const [programDetail, setProgramDetail] = useState(null)

  const [tvStationId, setTvStationId] = useState('')
  const [programs, setPrograms] = useState([])

  const [data, setData] = useState(null)
  const [stats, setStats] = useState([])
  const [eventTimeStats, setEventTimeStats] = useState([])
  const [eventAgenciesStats, setEventAgenciesStats] = useState([])
  const [eventGeneralStats, setEventGeneralStats] = useState([])
  const [eventDemographicStats, setEventDemographicStats] = useState(null)
  const [eventGeographicStats, setEventGeographicStats] = useState([])
  const [eventDeviceStats, setEventDeviceStats] = useState([])
  const [dateDaily, setDateDaily] = useState(null)
  const [detail, setDetail] = useState([])
  const [channels, setChannels] = useState([])
  const [selectedChannel, setSelectedChannel] = useState(null)

  const [dataLoaded, setDataLoaded] = useState(false)

  const loadChannelReportEvents = (eventId) => {
    setDetail([])
    getChannelReportEvents(selectedChannel.value, eventId).then((res) => {
      if (!res.success) {
        Swal.fire('Bir sorun çıktı.', res.data.data.errors[0].message, 'error')
      } else {
        setDetail(res.data)
      }
    })
  }

  const loadDataChannels = async () => {
    const publisherData = await getPublisherSchedule(userDetails.pubId)
    let channelArr = []
    publisherData.data.publisher.channels.forEach((v) => {
      channelArr.push({label: v.name, value: v.id})
    })
    setChannels(channelArr)
    setSelectedChannel({label: channelArr[0].label, value: channelArr[0].value})
  }

  useEffect(() => {
    loadDataChannels()

    // getPublisherChannelReport(publisher_id).then((res) => {
    //   if (!res.success) {
    //     Swal.fire('Bir sorun çıktı.', res.data.data.errors[0].message, 'error')
    //   } else {
    //     let tvStationId = res.data.publisher.channels[0].id
    //     if (res.data.publisher.channels.length > 1) {
    //       tvStationId = res.data.publisher.channels.filter((chn) => chn.name === 'TRT1')[0].id
    //     }
    //     setTvStationId(tvStationId)
    //   }
    // })
  }, [])

  useEffect(() => {
    if (selectedChannel) {
      getChannelsReport(selectedChannel.value).then((res) => {
        if (!res.success) {
          Swal.fire('Bir sorun çıktı.', res.data.data.errors[0].message, 'error')
        } else {
          let programOptions = []
          res.data.programs.forEach((v) => {
            programOptions.push({label: v.title, value: v.id})
          })
          setPrograms(programOptions)
        }
      })

      getStats({start: moment().add(-7, 'days'), end: moment()})
    }
  }, [selectedChannel])

  const getData = () => {
    let query = `start=${moment(selectedDate.start)
      .tz('Europe/London')
      .startOf('day')
      .unix()}&end=${moment(selectedDate.end).tz('Europe/London').endOf('day').unix()}`
    if (selectedProgram) {
      query += `&programId=${selectedProgram.value}`
    }
    getChannelsReportQuery(selectedChannel.value, query).then((res) => {
      if (!res.success) {
        Swal.fire('Bir sorun çıktı.', res.data.data.errors[0].message, 'error')
      } else {
        setData(res.data)
      }
    })
  }

  const getStats = async (date, concat, interval) => {
    intervalDateRef.current = date
    await getChannelStat(
      selectedChannel.value,
      `start=${moment(date.start).unix()}&end=${moment(date.end).unix()}&interval=1`
    ).then((res) => {
      if (!res.success) {
        Swal.fire('Bir sorun çıktı.', res.data.data.errors[0].message, 'error')
      } else {
        let data = [...stats]
        if (concat) {
          data = data.concat(res.data)
        } else {
          data = res.data
        }
        setStats(data)
      }
    })

    if (interval) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
      intervalRef.current = setInterval(() => {
        getStats(
          {
            start: moment(intervalDateRef.current.start).add(1, 'minute'),
            end: moment(intervalDateRef.current.end).add(1, 'minute'),
          },
          true
        )
      }, 60 * 1000)
    }
  }

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [])

  const getStatsWithEventId = (id) => {
    graphEventTime(selectedChannel.value, id).then((res) => {
      if (!res.success) {
        Swal.fire('Bir sorun çıktı.', res.data.data.errors[0].message, 'error')
      } else {
        setEventTimeStats(res.data.result)
      }
    })

    graphEventDevice(selectedChannel.value, id).then((res) => {
      if (!res.success) {
        Swal.fire('Bir sorun çıktı.', res.data.data.errors[0].message, 'error')
      } else {
        setEventDeviceStats(res.data)
      }
    })

    graphEventAgencies(selectedChannel.value, id).then((res) => {
      if (!res.success) {
        Swal.fire('Bir sorun çıktı.', res.data.data.errors[0].message, 'error')
      } else {
        setEventAgenciesStats(res.data)
      }
    })

    graphEventGeneral(selectedChannel.value, id).then((res) => {
      if (!res.success) {
        Swal.fire('Bir sorun çıktı.', res.data.data.errors[0].message, 'error')
      } else {
        setEventGeneralStats(res.data)
      }
    })

    graphEventDemographic(selectedChannel.value, id).then((res) => {
      if (!res.success) {
        Swal.fire('Bir sorun çıktı.', res.data.data.errors[0].message, 'error')
      } else {
        setEventDemographicStats(res.data)
      }
    })

    graphEventGeographic(selectedChannel.value, id).then((res) => {
      if (!res.success) {
        Swal.fire('Bir sorun çıktı.', res.data.data.errors[0].message, 'error')
      } else {
        setEventGeographicStats(res.data)
      }
    })
  }

  const getStatsWithDate = (date) => {
    graphDateAgencies(selectedChannel.value, date).then((res) => {
      if (!res.success) {
        Swal.fire('Bir sorun çıktı.', res.data.data.errors[0].message, 'error')
      } else {
        setEventAgenciesStats(res.data)
      }
    })

    graphDateGeneral(selectedChannel.value, date).then((res) => {
      if (!res.success) {
        Swal.fire('Bir sorun çıktı.', res.data.data.errors[0].message, 'error')
      } else {
        setEventGeneralStats(res.data)
      }
    })

    graphDateDemographic(selectedChannel.value, date).then((res) => {
      if (!res.success) {
        Swal.fire('Bir sorun çıktı.', res.data.data.errors[0].message, 'error')
      } else {
        setEventDemographicStats(res.data)
      }
    })

    graphDateGeographic(selectedChannel.value, date).then((res) => {
      if (!res.success) {
        Swal.fire('Bir sorun çıktı.', res.data.data.errors[0].message, 'error')
      } else {
        setEventGeographicStats(res.data)
      }
    })

    graphDateDaily(selectedChannel.value, date).then((res) => {
      if (!res.success) {
        Swal.fire('Bir sorun çıktı.', res.data.data.errors[0].message, 'error')
      } else {
        setDateDaily(res.data)
      }
    })
  }

  useEffect(() => {
    if (stats.length > 0 && chartanlik.current) {
      const now = moment().add(-2, 'minutes').startOf('minute').unix()
      const start = now - 2 * 60 * 60
      let isLive = false
      const current = moment().unix()
      if (now > current && start < current) {
        isLive = true
      }
      const lineData = []
      for (let j = 0; j < 120; j++) {
        lineData.push({
          date: moment
            .unix(start + j * 60)
            .tz('Turkey')
            .toDate(),
          value: 0,
        })
      }
      simultaneousViewerGraph('chartanlik', isLive ? stats : lineData)
    }
  }, [stats, chartanlik.current])

  useEffect(() => {
    if (eventTimeStats.length > 0 && chartreklam.current) {
      eventTimeStats.forEach((d) => (d.date = moment.unix(d.date).tz('Turkey').toDate()))
      simultaneousAdViewerGraph('chartreklam', eventTimeStats)
    }
  }, [eventTimeStats, chartreklam.current])

  useEffect(() => {
    const arrChn = []
    const arrChn2 = []
    if (eventDeviceStats.result?.length > 0 && chartizlenme.current && chartbutce.current) {
      eventDeviceStats.result.forEach(function (row) {
        let dev = row.device.split(',')
        if (dev && dev.length === 2) {
          dev = dev[dev.length - 1] + '(' + dev[0] + ')'
        } else {
          dev = dev[dev.length - 1]
        }
        arrChn.push({device: dev || 'Bilinmeyen', count: parseInt(row.cnt)})
        arrChn2.push({device: dev || 'Bilinmeyen', count: Number(row.price.toFixed(4))})
      })
      pieChart('chartizlenme', arrChn, 'İzlenme', 'device', 'count')
      pieChart('chartbutce', arrChn2, 'Bütçe', 'device', 'count')
    }
  }, [eventDeviceStats, chartizlenme.current, chartbutce.current])

  useEffect(() => {
    if (
      eventGeneralStats.result &&
      chartgeneral.current &&
      charttiklanma.current &&
      charttiklanmaoran.current &&
      chartgerceklesen.current
    ) {
      reachChart('chartgeneral', eventGeneralStats.result)
      clickChart('charttiklanma', eventGeneralStats.result)
      clickRateChart('charttiklanmaoran', eventGeneralStats.result)
      spentBudgetChart('chartgerceklesen', eventGeneralStats.result)
    }
  }, [
    eventGeneralStats,
    chartgeneral.current,
    charttiklanma.current,
    charttiklanmaoran.current,
    chartgerceklesen.current,
  ])

  useEffect(() => {
    if (eventAgenciesStats && chartajansbutce.current && chartajansizlenme.current) {
      const arrChn = []
      const arrChn2 = []
      Object.keys(eventAgenciesStats).forEach(function (name) {
        arrChn.push({name: decodeEntities(name), count: eventAgenciesStats[name].budget})
        arrChn2.push({name: decodeEntities(name), count: eventAgenciesStats[name].reach})
      })
      pieChart('chartajansbutce', arrChn, 'Ajans Bütçe Dağılımı', 'name', 'count')
      pieChart('chartajansizlenme', arrChn2, 'Ajans İzlenme Dağılımı', 'name', 'count')
    }
  }, [eventAgenciesStats, chartajansbutce.current, chartajansizlenme.current])

  useEffect(() => {
    if (eventGeographicStats && chartcogtr.current && chartcogworld.current) {
      const arr1 = []
      const arr2 = []
      const array = Object.keys(eventGeographicStats)
      const values = Object.values(eventGeographicStats)

      for (let i = 0; i < array.length; i++) {
        if (isNaN(array[i])) {
          arr2.push({id: array[i], value: values[i].count})
        } else {
          arr1.push({id: `TR-${array[i]}`, value: values[i].count})
        }
      }
      geographicHeatMap('chartcogtr', arr1, true)
      geographicHeatMap('chartcogworld', arr2)
    }
  }, [eventGeographicStats, chartcogtr.current, chartcogworld.current])

  useEffect(() => {
    if (eventDemographicStats && chartdemoyas.current && chartdemocinsiyet.current) {
      pieChart('chartdemoyas', eventDemographicStats.ageResult, 'Yaş Dağılımı', 'age', 'count')
      pieChart(
        'chartdemocinsiyet',
        eventDemographicStats.genderResult,
        'Cinsiyet Dağılımı',
        'gender',
        'count'
      )
    }
  }, [eventDemographicStats, chartdemoyas.current, chartdemocinsiyet.current])

  useEffect(() => {
    if (dateDaily && chartgunluk.current) {
      spentBudgetChart('chartgunluk', dateDaily.result)
    }
  }, [dateDaily, chartgunluk.current])

  return (
    <>
      {channels.length > 1 && (
        <div className='col-12'>
          <KTCard className='p-4'>
            <ReactSelect
              options={channels}
              value={selectedChannel}
              onChange={(e) => setSelectedChannel(e)}
            />
          </KTCard>
        </div>
      )}
      {(dataLoaded || programDetail) && (
        <KTCard className='p-4 mt-4'>
          <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
            {(dataLoaded || programDetail) && (
              <li className='nav-item'>
                <a className='nav-link active' data-bs-toggle='tab' href={`#kt_tab_pane_1`}>
                  Genel Bakış
                </a>
              </li>
            )}

            {/* {(dataLoaded || programDetail) && (
              <li className='nav-item'>
                <a className='nav-link' data-bs-toggle='tab' href={`#kt_tab_pane_2`}>
                  Anlık İzlenme
                </a>
              </li>
            )} */}

            {!dataLoaded && programDetail && (
              <li className='nav-item'>
                <a className='nav-link' data-bs-toggle='tab' href={`#kt_tab_pane_3`}>
                  Program - Cihaz
                </a>
              </li>
            )}

            {!dataLoaded && programDetail && (
              <li className='nav-item'>
                <a className='nav-link' data-bs-toggle='tab' href={`#kt_tab_pane_4`}>
                  Program - Reklam
                </a>
              </li>
            )}

            {dataLoaded && (
              <li className='nav-item'>
                <a className='nav-link' data-bs-toggle='tab' href={`#kt_tab_pane_5`}>
                  Günlük Kazanç
                </a>
              </li>
            )}
          </ul>
          {(dataLoaded || programDetail) && (
            <div className='tab-content' id='myTabContent'>
              <div className='tab-pane fade active show' id={`kt_tab_pane_1`} role='tabpanel'>
                <div className='tabbable-custom tabs-below nav-justified'>
                  <div className='tab-content' id='myTabContent'>
                    <div
                      className='tab-pane fade active show'
                      id={`kt_tab_pane_11`}
                      role='tabpanel'
                    >
                      <div
                        id='chartgeneral'
                        ref={chartgeneral}
                        className='mt-2'
                        style={{width: '100%', height: '300px'}}
                      ></div>
                    </div>
                    <div className='tab-pane fade' id={`kt_tab_pane_12`} role='tabpanel'>
                      <div
                        id='charttiklanma'
                        ref={charttiklanma}
                        className='mt-2'
                        style={{width: '100%', height: '300px'}}
                      />
                    </div>
                    <div className='tab-pane fade' id={`kt_tab_pane_13`} role='tabpanel'>
                      <div
                        id='charttiklanmaoran'
                        ref={charttiklanmaoran}
                        className='mt-2'
                        style={{width: '100%', height: '300px'}}
                      />
                    </div>
                    <div className='tab-pane fade' id={`kt_tab_pane_14`} role='tabpanel'>
                      <div
                        id='chartgerceklesen'
                        ref={chartgerceklesen}
                        className='mt-2'
                        style={{width: '100%', height: '300px'}}
                      />
                    </div>
                    <div className='tab-pane fade' id={`kt_tab_pane_15`} role='tabpanel'>
                      <div className='row'>
                        <div className='col-6'>
                          <div
                            id='chartajansbutce'
                            ref={chartajansbutce}
                            className='mt-2'
                            style={{width: '100%', height: '300px'}}
                          />
                        </div>
                        <div className='col-6'>
                          <div
                            id='chartajansizlenme'
                            ref={chartajansizlenme}
                            className='mt-2'
                            style={{width: '100%', height: '300px'}}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='tab-pane fade' id={`kt_tab_pane_16`} role='tabpanel'>
                      <div className='row'>
                        <div className='col-6'>
                          <div
                            id='chartcogtr'
                            ref={chartcogtr}
                            className='mt-2'
                            style={{width: '100%', height: '300px'}}
                          />
                        </div>
                        <div className='col-6'>
                          <div
                            id='chartcogworld'
                            ref={chartcogworld}
                            className='mt-2'
                            style={{width: '100%', height: '300px'}}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='tab-pane fade' id={`kt_tab_pane_17`} role='tabpanel'>
                      <div className='row'>
                        <div className='col-6'>
                          <div
                            id='chartdemoyas'
                            ref={chartdemoyas}
                            className='mt-2'
                            style={{width: '100%', height: '300px'}}
                          />
                        </div>
                        <div className='col-6'>
                          <div
                            id='chartdemocinsiyet'
                            ref={chartdemocinsiyet}
                            className='mt-2'
                            style={{width: '100%', height: '300px'}}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul className='nav nav-tabs nav-justified'>
                    <li className='nav-item'>
                      <a
                        className='nav-link active d-flex flex-column'
                        data-bs-toggle='tab'
                        href={`#kt_tab_pane_11`}
                      >
                        {eventGeneralStats && <span>{eventGeneralStats.view}</span>}
                        <span>İzlenme</span>
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className='nav-link d-flex flex-column'
                        data-bs-toggle='tab'
                        href={`#kt_tab_pane_12`}
                      >
                        {eventGeneralStats && <span>{eventGeneralStats.click}</span>}
                        <span>Tıklanma</span>
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className='nav-link d-flex flex-column'
                        data-bs-toggle='tab'
                        href={`#kt_tab_pane_13`}
                      >
                        {eventGeneralStats && <span>{eventGeneralStats.clickRate}</span>}
                        <span>Tıklanma Oranı</span>
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className='nav-link d-flex flex-column'
                        data-bs-toggle='tab'
                        href={`#kt_tab_pane_14`}
                      >
                        {eventGeneralStats && <span>{eventGeneralStats.budget}</span>}
                        <span>Gerçekleşen</span>
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className='nav-link d-flex flex-column'
                        data-bs-toggle='tab'
                        href={`#kt_tab_pane_15`}
                      >
                        {eventGeneralStats && <span>{Object.keys(eventAgenciesStats).length}</span>}
                        <span>Ajans</span>
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link' data-bs-toggle='tab' href={`#kt_tab_pane_16`}>
                        COĞRAFİK DAĞILIM
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link' data-bs-toggle='tab' href={`#kt_tab_pane_17`}>
                        DEMOGRAFİK DAĞILIM
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='tab-pane fade' id={`kt_tab_pane_2`} role='tabpanel'>
                <div
                  id='chartanlik'
                  ref={chartanlik}
                  className='mt-2'
                  style={{width: '100%', height: '300px'}}
                ></div>
              </div>
              <div className='tab-pane fade' id={`kt_tab_pane_3`} role='tabpanel'>
                <div className='row'>
                  <div className='col-6'>
                    <div
                      id='chartizlenme'
                      ref={chartizlenme}
                      className='mt-2'
                      style={{width: '100%', height: '300px'}}
                    ></div>
                  </div>
                  <div className='col-6'>
                    <div
                      id='chartbutce'
                      ref={chartbutce}
                      className='mt-2'
                      style={{width: '100%', height: '300px'}}
                    ></div>
                  </div>
                </div>
              </div>
              <div className='tab-pane fade' id={`kt_tab_pane_4`} role='tabpanel'>
                <div
                  id='chartreklam'
                  ref={chartreklam}
                  className='mt-2'
                  style={{width: '100%', height: '300px'}}
                ></div>
              </div>
              <div className='tab-pane fade' id={`kt_tab_pane_5`} role='tabpanel'>
                <div
                  id='chartgunluk'
                  ref={chartgunluk}
                  className='mt-2'
                  style={{width: '100%', height: '300px'}}
                ></div>
              </div>
            </div>
          )}
        </KTCard>
      )}

      <KTCard className='p-4 mt-4'>
        <label className='fw-bold fs-6'>RAPORLAMA</label>
        <div className='d-flex flex-row mt-4'>
          <button
            className={`btn btn-${type === 'date' ? 'primary' : 'secondary'}`}
            onClick={() => {
              setType('date')
              setSelectedProgram(null)
            }}
          >
            Tarih Bazlı
          </button>
          <button
            className={`ms-2 btn btn-${type === 'program' ? 'primary' : 'secondary'}`}
            onClick={() => {
              setType('program')
            }}
          >
            Program Bazlı
          </button>
        </div>
        <div className='mt-4 row'>
          <div className='col-9 d-flex alig-items-center'>
            <RangePicker
              locale={locale}
              defaultValue={[moment().add(-6, 'days'), moment()]}
              format={'DD.MM.YYYY'}
              onChange={(e) => setSelectedDate({start: e[0], end: e[1]})}
            />
            {type === 'program' && (
              <ReactSelect
                options={programs}
                styles={mode === 'light' ? {} : customStyles}
                closeMenuOnSelect={true}
                value={selectedProgram}
                onChange={(e) => setSelectedProgram(e)}
                classNamePrefix='select'
                className='ms-4 w-25'
              />
            )}
          </div>
          <div className='col-3'>
            <button
              className='btn btn-primary'
              onClick={async () => {
                await getData()
                await getStats({start: selectedDate.start, end: selectedDate.end}, false, true)
                await getStatsWithDate({
                  start: selectedDate.start,
                  end: selectedDate.end,
                })
                setDataLoaded(true)
              }}
            >
              Bul
            </button>
          </div>
        </div>
        {data && data.length > 0 && (
          <div
            className='row mt-4 channel-reports'
            style={{maxHeight: '400px', overflow: 'scroll'}}
          >
            <div className='accordion accordion-flush' id='accordionFlushExample'>
              {data.map((v, i) => {
                return (
                  <div key={i} className='accordion-item'>
                    <h2
                      className='accordion-header d-flex align-items-center'
                      id='flush-headingOne'
                    >
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target={`#flush-collapse${v.id}`}
                        aria-expanded='false'
                        aria-controls={`flush-collapse${v.id}`}
                        onClick={async () => {
                          if (programDetail && v.id === programDetail.id) {
                            getStats(
                              {start: selectedDate.start, end: selectedDate.end},
                              false,
                              true
                            )
                            getStatsWithDate({
                              start: selectedDate.start,
                              end: selectedDate.end,
                            })
                            setProgramDetail(null)
                          } else {
                            setDateDaily(null)
                            loadChannelReportEvents(v.id)
                            getStatsWithEventId(v.id)
                            getStats({start: v.start, end: v.end}, false, true)
                            setProgramDetail(v)
                          }
                        }}
                      >
                        <div className='d-flex align-items-center w-100'>
                          <div className='col-12 col-md-3 d-flex flex-column'>
                            <span>{decodeEntities(v.program.title)}</span>
                            <span>
                              {v.date} {v.startTime} - {v.endTime}
                            </span>
                          </div>
                          <div className='col-12 col-md-3 d-flex flex-column'>
                            <span>Video Ciro: {nf.format(v.budget)}</span>
                            <span>Overlay Ciro: {nf.format(v.overlayBudget)}</span>
                          </div>
                          <div className='col-12 col-md-3 d-flex flex-column'>
                            <span>Ortalama CPV: {nf.format(v.averageCPV)}</span>
                            <span>Ortalama CPM: {nf.format(v.averageCPM)}</span>
                          </div>
                          <div className='col-12 col-md-3 d-flex flex-column'>
                            <span>
                              İzlenme / Tıklanma: {v.reach} / {v.clickCount}
                            </span>
                            <span>
                              İzlenme / Tıklanma: {v.overlayReach} / {v.overlayClickCount}
                            </span>
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div
                      id={`flush-collapse${v.id}`}
                      className='accordion-collapse collapse'
                      aria-labelledby='flush-headingOne'
                      data-bs-parent='#accordionFlushExample'
                    >
                      <div className='accordion-body'>
                        <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
                          <li className='nav-item'>
                            <a
                              className='nav-link active'
                              data-bs-toggle='tab'
                              href={`#kt_tab_pane_${v.id}`}
                            >
                              Video
                            </a>
                          </li>
                          <li className='nav-item'>
                            <a
                              className='nav-link'
                              data-bs-toggle='tab'
                              href={`#kt_tab_pane_${v.id}-2`}
                            >
                              Overlay
                            </a>
                          </li>
                        </ul>
                        <div className='tab-content' id='myTabContent'>
                          <div
                            className='tab-pane fade active show'
                            id={`kt_tab_pane_${v.id}`}
                            role='tabpanel'
                          >
                            <BootstrapTable data={detail.videoOffers} striped={true} hover={true}>
                              <TableHeaderColumn
                                isKey={true}
                                dataField='id'
                                dataAlign='left'
                                width='130'
                                dataSort={true}
                                dataFormat={(cell, row) => {
                                  return <span>{decodeEntities(row.offer.advertiser.name)}</span>
                                }}
                              >
                                Marka
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                dataField='creatives'
                                dataAlign='left'
                                dataSort={true}
                                dataFormat={(cell, row) => {
                                  return <span>{decodeEntities(row.offer.creatives[0].name)}</span>
                                }}
                              >
                                Kreatif
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                dataField='agency'
                                dataAlign='left'
                                width='130'
                                dataSort={true}
                                dataFormat={(cell, row) => {
                                  return <span>{decodeEntities(row.offer.agency.name)}</span>
                                }}
                              >
                                Ajans
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                dataField='spentBudget'
                                dataAlign='center'
                                width='100'
                                dataSort={true}
                                dataFormat={(cell, row) => {
                                  return <span>{nf.format(row.spentBudget)}</span>
                                }}
                              >
                                Bütçe
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                dataField='viewCount'
                                dataAlign='center'
                                width='100'
                                dataSort={true}
                                dataFormat={(cell, row) => {
                                  return <span>{row.viewCount}</span>
                                }}
                              >
                                İzlenme
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                dataField='clickCount'
                                dataAlign='center'
                                width='100'
                                dataSort={true}
                                dataFormat={(cell, row) => {
                                  return row.clickCount
                                }}
                              >
                                Tıklanma
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                dataField='advertiser'
                                dataAlign='center'
                                width='150'
                                dataSort={true}
                                dataFormat={(cell, row) => {
                                  return nf.format(row.averageCPV)
                                }}
                              >
                                Ortalama CPV
                              </TableHeaderColumn>
                            </BootstrapTable>
                          </div>
                          <div
                            className='tab-pane fade'
                            id={`kt_tab_pane_${v.id}-2`}
                            role='tabpanel'
                          >
                            <BootstrapTable data={detail.overlayOffers} striped={true} hover={true}>
                              <TableHeaderColumn
                                isKey={true}
                                dataField='advertiser.name'
                                dataAlign='left'
                                width='130'
                                dataSort={true}
                                dataFormat={(cell, row) => {
                                  return <span>{decodeEntities(row.offer.advertiser.name)}</span>
                                }}
                              >
                                Marka
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                dataField='creatives'
                                dataAlign='left'
                                dataSort={true}
                                dataFormat={(cell, row) => {
                                  return <span>{decodeEntities(row.offer.creatives[0].name)}</span>
                                }}
                              >
                                Görsel
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                dataField='agency'
                                dataAlign='left'
                                width='130'
                                dataSort={true}
                                dataFormat={(cell, row) => {
                                  return <span>{decodeEntities(row.offer.agency.name)}</span>
                                }}
                              >
                                Ajans
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                dataField='spentBudget'
                                dataAlign='center'
                                width='100'
                                dataSort={true}
                                dataFormat={(cell, row) => {
                                  return <span>{nf.format(row.spentBudget)}</span>
                                }}
                              >
                                Bütçe
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                dataField='viewCount'
                                dataAlign='center'
                                width='100'
                                dataSort={true}
                                dataFormat={(cell, row) => {
                                  return <span>{row.viewCount}</span>
                                }}
                              >
                                İzlenme
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                dataField='clickCount'
                                dataAlign='center'
                                width='100'
                                dataSort={true}
                                dataFormat={(cell, row) => {
                                  return row.clickCount
                                }}
                              >
                                Tıklanma
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                dataField='advertiser'
                                dataAlign='center'
                                width='120'
                                dataSort={true}
                                dataFormat={(cell, row) => {
                                  return nf.format(row.cpm)
                                }}
                              >
                                CPM
                              </TableHeaderColumn>
                            </BootstrapTable>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
        {data && data.length === 0 && (
          <Alert className=' mt-4' severity='error'>
            Aranılan kriterlere uygun sonuç bulunamadı!
          </Alert>
        )}
      </KTCard>
    </>
  )
}

export default ChannelRerports
