import clsx from 'clsx'
import {useLayout} from '../../../../../_metronic/layout/core'

function TableHeader({title}) {
  const {classes} = useLayout()

  return (
    <div className='card-header border-0 pt-6'>
      <div
        id='kt_page_title'
        data-kt-swapper='true'
        data-kt-swapper-mode='prepend'
        data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
        className={clsx('page-title d-flex', classes.pageTitle.join(' '))}
      >
        <h1>{title}</h1>
      </div>
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'></div>
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export default TableHeader
