import {useContext, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTCard} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {UserContext} from '../../contexts/auth'
import {
  dashboardOffers,
  dashboarReport,
  getChannelStat,
  getPublisher,
} from '../../services/publishers.service'
import {useNavigate} from 'react-router-dom'
import moment from 'moment-timezone/builds/moment-timezone-with-data'
import {simultaneousViewerGraph} from '../../utils/charts'
import ReactSelect from 'react-select'
import {decodeEntities} from '../../utils/format'

let cf = new Intl.NumberFormat('tr-TR', {
  style: 'currency',
  currency: 'TRY',
  minimumFractionDigits: 0,
})

let nf = new Intl.NumberFormat('tr-TR')

const DashboardPage = () => {
  let navigate = useNavigate()
  const userDetails = useContext(UserContext)

  const intervalRef = useRef()
  const intervalDateRef = useRef()
  const chartanlik = useRef()

  const [channels, setChannels] = useState([])
  const [selectedChannel, setSelectedChannel] = useState(null)
  const [offers, setOffers] = useState(null)
  const [report, setReport] = useState(null)
  const [stats, setStats] = useState([])
  const [timezone, setTimezone] = useState('Turkey')
  const [selectedReportTime, setSelectedReportTime] = useState({
    label: 'Bu Ay',
    value: {
      start: moment().tz(timezone).startOf('month').unix(),
      end: moment().tz(timezone).endOf('month').unix(),
    },
  })

  useEffect(() => {
    fetchData()

  }, [])

  const getOffers = async (channelId) => {
    const res = await dashboardOffers(channelId)
    if (res.success) {
      setOffers(res.data)
    }
  }

  const getReport = async (channelId, date) => {
    if (!date) {
      date = {start: moment().tz(timezone).startOf('month').unix(), end: moment().tz(timezone).endOf('month').unix()}
    }
    const res = await dashboarReport(channelId, date.start, date.end)
    if (res.success) {
      setReport(res.data)
    }
  }

  const getStats = async (channelId, date) => {
    intervalDateRef.current = date
    await getChannelStat(
      channelId,
      `start=${moment(date.start).unix()}&end=${moment(date.end).unix()}&interval=1`
    ).then((res) => {
      if (res.success) {
        res.data.forEach(function (record) {
          record.date = moment.unix(record.date).tz('Turkey').toDate()
        })
        let data = [...stats]
        data = data.concat(res.data)
        setStats(data)
      }
    })

    // if (!intervalRef.current) {
    //   intervalRef.current = setInterval(() => {
    //     getStats(
    //       channelId,
    //       {
    //         start: moment(intervalDateRef.current.start).add(1, 'minute'),
    //         end: moment(intervalDateRef.current.end).add(1, 'minute'),
    //       },
    //       true
    //     )
    //   }, 60 * 1000)
    // }
  }

  const fetchData = async () => {
    const publisherData = await getPublisher(userDetails.pubId)
    let channelArr = []
    publisherData.data.publisher.channels.forEach((v) => {
      channelArr.push({label: v.name, value: v.id})
    })
    setTimezone(publisherData.data.publisher.timezone)
    setChannels(channelArr)
    setSelectedChannel({label: channelArr[0].label, value: channelArr[0].value})
  }

  useEffect(() => {
    if (selectedChannel) {
      getOffers(selectedChannel.value)
      getStats(selectedChannel.value, {start: moment().add(-2, 'hours'), end: moment()})
    }
  }, [selectedChannel])

  const fetchReportData = async (selectedReportTime) => {
    getReport(selectedChannel.value, selectedReportTime)
  }

  useEffect(() => {
    if (stats.length > 0 && chartanlik.current) {
      // const now = moment().add(-2, 'minutes').startOf('minute').unix()
      // const start = now - 2 * 60 * 60
      // let isLive = true
      // const lineData = []
      // for (let j = 0; j < 120; j++) {
      //   lineData.push({
      //     date: moment
      //       .unix(start + j * 60)
      //       .tz('Europe/London')
      //       .toDate(),
      //     value: 0,
      //   })
      // }
      simultaneousViewerGraph('chartanlik', stats)
    }
  }, [stats, chartanlik.current])

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [])

  useEffect(() => {
    if (selectedChannel) fetchReportData(selectedReportTime.value)
    // getReport(selectedReportTime.value)
  }, [selectedReportTime, selectedChannel])

  return (
    <>
      <div className='row'>
        {channels.length > 1 && (
          <div className='col-12'>
            <KTCard className='p-4'>
              <ReactSelect
                options={channels}
                value={selectedChannel}
                onChange={(e) => setSelectedChannel(e)}
              />
            </KTCard>
          </div>
        )}
        <div className='col-12 mt-4'>
          <KTCard className='p-4'>
            <label className='fw-bolder fs-6'>ANLIK İZLEME</label>
            <div
              id='chartanlik'
              ref={chartanlik}
              className='mt-2'
              style={{width: '100%', height: '300px'}}
            ></div>
          </KTCard>
        </div>
        {report && (
          <div className='col-6 mt-4'>
            <KTCard className='p-4 '>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='fw-bolder fs-6'>RAPORLAMA</label>
                <div className='d-flex'>
                  <ReactSelect
                    options={[
                      {
                        label: 'Bugün',
                        value: {
                          start: moment().tz(timezone).startOf('day').unix(),
                          end: moment().tz(timezone).endOf('day').unix(),
                        },
                      },
                      {
                        label: 'Dün',
                        value: {
                          start: moment().tz(timezone).add(-1, 'days').startOf('day').unix(),
                          end: moment().tz(timezone).add(-1, 'days').endOf('day').unix(),
                        },
                      },
                      {
                        label: 'Son 7 Gün',
                        value: {start: moment().tz(timezone).add(-6, 'days').startOf('day').unix(), end: moment().tz(timezone).endOf('day').unix()},
                      },
                      {
                        label: 'Son 30 Gün',
                        value: {
                          start: moment().tz(timezone).add(-29, 'days').startOf('day').unix(),
                          end: moment().tz(timezone).endOf('day').unix(),
                        },
                      },
                      {
                        label: 'Bu Ay',
                        value: {
                          start: moment().tz(timezone).startOf('month').unix(),
                          end: moment().tz(timezone).endOf('month').unix(),
                        },
                      },
                      {
                        label: 'Önceki Ay',
                        value: {
                          start: moment().tz(timezone).add(-1, 'month').startOf('month').unix(),
                          end: moment().tz(timezone).add(-1, 'month').endOf('month').unix(),
                        },
                      },
                    ]}
                    styles={{
                      control: (base) => {
                        return {
                          ...base,
                          minWidth: 150,
                        }
                      },
                      valueContainer: (provided, state) => ({
                        ...provided,
                        minWidth: '150px',
                      }),
                    }}
                    value={selectedReportTime}
                    closeMenuOnSelect={true}
                    onChange={(e) => setSelectedReportTime(e)}
                    classNamePrefix='select'
                  />
                  <button
                    className='btn btn-sm btn-secondary rounded-pill ms-2'
                    onClick={() => {
                      navigate(`/publishers/${userDetails.pubId}/channelReports`)
                    }}
                  >
                    Detay
                  </button>
                </div>
              </div>

              <div className='separator my-2'></div>
              <div className='row mt-4'>
                <div className='col-6 d-flex flex-column align-items-center'>
                  <span style={{fontSize: '30px', color: 'green'}}>
                    {cf.format(report.revenue)}
                  </span>
                  GELİR
                </div>
                <div className='col-6 d-flex flex-column align-items-center'>
                  <span style={{fontSize: '30px', color: 'green'}}>{nf.format(report.reach)}</span> İZLENME
                </div>
                <div className='col-6 d-flex flex-column align-items-center'>
                  <span style={{fontSize: '30px', color: 'green'}}>
                    {cf.format(report.topPrice)}
                  </span>
                  <span>EN ÇOK KAZANDIRAN</span>
                  <span>{decodeEntities(report.topPriceAdvertiser)}</span>
                </div>
                <div className='col-6 d-flex flex-column align-items-center'>
                  <span style={{fontSize: '30px', color: 'green'}}>{nf.format(report.topReach)}</span>
                  <span>EN ÇOK İZLENEN</span>
                  <span>{decodeEntities(report.topReachAdvertiser)}</span>
                </div>
              </div>
            </KTCard>
          </div>
        )}
        {offers && (
          <div className='col-6 mt-4'>
            <KTCard className='p-4'>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='fw-bolder fs-6'>REKLAM TEKLİFLERİ</label>
                <button
                  className='btn btn-sm btn-secondary rounded-pill'
                  onClick={() => {
                    navigate(`/publishers/${userDetails.pubId}/channel-offers`)
                  }}
                >
                  Detay
                </button>
              </div>

              <div className='separator my-2'></div>
              <div className='row mt-4'>
                <div className='col-6 d-flex flex-column align-items-center'>
                  <span style={{fontSize: '30px', color: 'green'}}>{offers.pending}</span> ONAY
                  BEKLEYEN TEKLIFLER
                </div>
                <div className='col-6 d-flex flex-column align-items-center'>
                  <span style={{fontSize: '30px', color: 'green'}}>{offers.active}</span> YAYINDAKI
                  TEKLIFLER
                </div>
              </div>
            </KTCard>
          </div>
        )}
      </div>
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
