/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import moment from 'moment'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import './../../../../../src/_metronic/assets/css/rsuite.css'

import Table from './components/Table'
import {KTCard, KTSVG} from '../../../../_metronic/helpers'
import {getAdvertiserCampaigns} from '../../../services/campaigns.service'
import {Link, useParams} from 'react-router-dom'
import {
  getCampaignDetail,
  getReportOffers,
  setAgencyOfferStatu,
} from '../../../services/agencies.service'
import offerStatus from '../../../utils/offerStatus'
import CreativeModal from './components/CreativeModal'
import {Dropdown} from 'react-bootstrap'
import {decodeEntities} from '../../../utils/format'
import ScreenShotModal from './../offerDetail/components/ScreenshotModal'

const ReportOffers = () => {
  const intl = useIntl()
  let {agency_id} = useParams()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [advertisers, setAdvertisers] = useState([])
  const [campaigns, setCampaigns] = useState([])
  const [selectedCampaign, setSelectedCampaign] = useState(null)
  const [selectedAdv, setSelectedAdv] = useState(null)
  const [selectedCreative, setSelectedCreative] = useState(null)
  const [showScreenshotModal, setShowScreenshotModal] = useState(false)
  const [ssCreatives, setSsCreatives] = useState([])
  const [ssOffer, setSsOffer] = useState(null)

  const customColumns = [
    {
      Header: (props) => (
        <th {...props.column.getHeaderProps()} className='min-w-125px' style={{cursor: 'pointer'}}>
          Teklif Adı
        </th>
      ),
      accessor: 'name',
      Cell: ({...props}) => {
        let value = props.row.original
        let status = offerStatus(value.status.id)
        let isClickable = value.status.id === 2 || value.status.id === 1
        return (
          <div className='d-flex flex-column'>
            <Link to={`/agencies/${agency_id}/offers/${value.id}`}>
              {decodeEntities(value.name)}
            </Link>
            <div
              className={`btn btn-${status.color} btn-sm mt-2`}
              style={{cursor: isClickable ? 'pointer' : 'default'}}
              onClick={() => {
                if (isClickable) {
                  Swal.fire({
                    title: `Teklif "${
                      value.status.id === 1 ? 'GERİ ÇEKİLDİ' : 'AJANS İPTAL ETTİ'
                    }" statüsüne geçirilecektir. Onaylıyor musunuz?`,
                    showCancelButton: true,
                    confirmButtonText: 'Evet',
                    cancelButtonText: 'Kapat',
                    preConfirm: () => {
                      const params = new URLSearchParams()
                      params.append('status', value.status.id === 1 ? 9 : 6)

                      return setAgencyOfferStatu(agency_id, value.id, params).then((res) => {
                        if (res.success) {
                          loadDetails(value.campaignId)
                          Swal.fire('Statü değiştirildi!', '', 'success')
                        } else {
                          Swal.fire('Bir sorun çıktı', res.data.data.errors[0].message, 'warning')
                        }
                      })
                    },
                    allowOutsideClick: () => !Swal.isLoading(),
                  })
                }
              }}
            >
              {status.messageKey}
            </div>
            <Dropdown className='mt-2'>
              <Dropdown.Toggle variant='success' id='dropdown-basic' size='sm'>
                İşlemler
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href={`/agencies/${agency_id}/offers/${value.id}/edit`}>
                  Teklifi Düzenle
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setSsCreatives(value.creatives)
                    setSsOffer(value.id)
                    setShowScreenshotModal(true)
                  }}
                >
                  Ekran Görüntüsü Al
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )
      },
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          style={{cursor: 'pointer'}}
          // onClick={sortColumn}
        >
          Kreatif
        </th>
      ),
      accessor: 'creatives[0].name',
      Cell: ({...props}) => {
        let value = props.row.original
        return value.creatives.map((creative) => {
          return (
            <div>
              <span
                style={{cursor: 'pointer', textDecoration: 'underline'}}
                onClick={() => {
                  setSelectedCreative(creative)
                }}
              >
                {decodeEntities(creative.name)}
              </span>
            </div>
          )
        })
      },
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          // onClick={sortColumn}
        >
          Teklif Süresi
        </th>
      ),
      id: 'time',
      Cell: ({...props}) => {
        let value = props.row.original
        const percent = parseInt(
          ((moment().unix() - moment(value.start).unix()) /
            (moment(value.end).unix() - moment(value.start).unix())) *
            100
        )
        return (
          <div className='d-flex flex-column'>
            <div>
              <div className='progress'>
                <span
                  style={{width: `${percent}%`}}
                  className='progress-bar bg-success green-sharp'
                ></span>
              </div>
            </div>
            <span>{value.startDate}</span>

            <span>{value.endDate}</span>
          </div>
        )
      },
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          // onClick={sortColumn}
        >
          İzleme Maliyeti
        </th>
      ),
      id: 'pssNs',
      Cell: ({...props}) => {
        let value = props.row.original
        let nf = new Intl.NumberFormat('tr-TR', {style: 'currency', currency: 'TRY'})
        return (
          <div className='d-flex flex-column'>
            {value.type === 1 ? (
              <>
                <div className='row'>
                  <span className='col'>PSS:</span>
                  <span className='col'>
                    {value.pssPrice > -1 ? (
                      nf.format(value.pssPrice)
                    ) : (
                      <KTSVG
                        path='/media/icons/duotune/general/gen040.svg'
                        className='svg-icon-3'
                      />
                    )}
                  </span>
                </div>
                <div className='row'>
                  <span className='col'>NS:</span>
                  <span className='col d-flex align-items-center'>
                    {value.nsPrice > -1 ? (
                      nf.format(value.nsPrice)
                    ) : (
                      <KTSVG
                        path='/media/icons/duotune/general/gen040.svg'
                        className='svg-icon-3'
                      />
                    )}
                  </span>
                </div>
              </>
            ) : (
              <div className='row'>
                <span className='col'>CPM:</span>
                <span className='col d-flex align-items-center'>{nf.format(value.cpm)}</span>
              </div>
            )}
          </div>
        )
      },
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          // onClick={sortColumn}
        >
          Bütçe
        </th>
      ),
      id: 'donem',
      Cell: ({...props}) => {
        let value = props.row.original
        let nf = new Intl.NumberFormat('tr-TR', {
          style: 'currency',
          currency: 'TRY',
          minimumFractionDigits: 0,
        })
        return (
          <div className='d-flex flex-column'>
            <span>{nf.format(value.rawBudget)}</span>
            <span>{value.budgetDistributionString}</span>
            {value.budgetDistribution === 'CUSTOM' && <span>{nf.format(value.dailyBudget)}</span>}
            {value.type === 1 && (
              <>
                <span>PSS: {nf.format(value.pssBudget)}</span>
                <span>NS: {nf.format(value.nsBudget)}</span>
              </>
            )}
          </div>
        )
      },
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          // onClick={sortColumn}
        >
          Aşım Payı
        </th>
      ),
      id: 'overload',
      Cell: ({...props}) => {
        let value = props.row.original
        return <span>%{value.overload}</span>
      },
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          // onClick={sortColumn}
        >
          Gerçekleşen
        </th>
      ),
      id: 'spenBudget',
      Cell: ({...props}) => {
        let value = props.row.original
        let nf = new Intl.NumberFormat('tr-TR', {style: 'currency', currency: 'TRY'})
        return <span>{nf.format(value.spentBudget)}</span>
      },
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          // onClick={sortColumn}
        >
          İzlenme
        </th>
      ),
      id: 'imp',
      Cell: ({...props}) => {
        let value = props.row.original
        return <span>{value.type === 1 ? value.impression : value.viewCount}</span>
      },
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          // onClick={sortColumn}
        >
          Görünür İzlenme
        </th>
      ),
      id: 'realimp',
      Cell: ({...props}) => {
        let value = props.row.original
        return (
          <span>
            {value.viewableImpression} / {value.viewabilityRate}%
          </span>
        )
      },
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          // onClick={sortColumn}
        >
          Tekil Erişim
        </th>
      ),
      id: 'uniqueReach',
      Cell: ({...props}) => {
        let value = props.row.original
        return <span>{value.uniqueReach}</span>
      },
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          // onClick={sortColumn}
        >
          Ad-blocker Gösterimi
        </th>
      ),
      id: 'blocked',
      Cell: ({...props}) => {
        let value = props.row.original
        return (
          <span>
            {value.type === 1 ? value.blocked + ' / ' + value.adBlockPercent + '%' : 'N/A'}
          </span>
        )
      },
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          // onClick={sortColumn}
        >
          Tıklanma
        </th>
      ),
      id: 'clickCount',
      Cell: ({...props}) => {
        let value = props.row.original
        return <span>{value.clickCount}</span>
      },
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          // onClick={sortColumn}
        >
          Tıklanma Oranı
        </th>
      ),
      id: 'clickRate',
      Cell: ({...props}) => {
        let value = props.row.original
        return <span>{value.clickRate}%</span>
      },
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          // onClick={sortColumn}
        >
          Ortalama CPV
        </th>
      ),
      id: 'averageCPV',
      Cell: ({...props}) => {
        let value = props.row.original
        let nf = new Intl.NumberFormat('tr-TR', {style: 'currency', currency: 'TRY'})
        return <span>{nf.format(value.averageCPV)}</span>
      },
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          // onClick={sortColumn}
        >
          %25
        </th>
      ),
      id: 'firstQuartile',
      Cell: ({...props}) => {
        let value = props.row.original
        return (
          <span>
            {value.type === 1 ? value.firstQuartile + ' / ' + value.firstPercent + '%' : 'N/A'}
          </span>
        )
      },
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          // onClick={sortColumn}
        >
          %50
        </th>
      ),
      id: 'secondQuartile',
      Cell: ({...props}) => {
        let value = props.row.original
        return (
          <span>
            {value.type === 1 ? value.midpoint + ' / ' + value.secondPercent + '%' : 'N/A'}
          </span>
        )
      },
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          // onClick={sortColumn}
        >
          %75
        </th>
      ),
      id: 'thirdQuartile',
      Cell: ({...props}) => {
        let value = props.row.original
        return (
          <span>
            {value.type === 1 ? value.thirdQuartile + ' / ' + value.thirdPercent + '%' : 'N/A'}
          </span>
        )
      },
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px'
          // onClick={sortColumn}
        >
          Tamamlanan
        </th>
      ),
      id: 'completedCount',
      Cell: ({...props}) => {
        let value = props.row.original
        return (
          <span>
            {value.type === 1 ? value.completedCount + ' / ' + value.completionRate + '%' : 'N/A'}
          </span>
        )
      },
    },
  ]

  async function fetchData() {
    const reportOffers = await getReportOffers(agency_id)
    if (reportOffers.success) {
      setAdvertisers(reportOffers.data.advertisers)
    }
  }

  const loadCampaigns = async (id) => {
    setSelectedAdv(id)
    const campaigns = await getAdvertiserCampaigns(agency_id, id)
    if (campaigns.success) {
      setCampaigns(campaigns.data)
      setData([])
    }
  }

  const loadDetails = async (campaignId) => {
    const detail = await getCampaignDetail(agency_id, selectedAdv, campaignId)
    if (detail.success) {
      setData(detail.data.campaign.offers)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <KTCard className='p-4'>
        <div className='mb-4 row'>
          <div className='col-6'>
            <select
              className='form-select'
              aria-label='Select example'
              onChange={(e) => {
                loadCampaigns(e.target.value)
              }}
            >
              <option value={''}>Müşteri Seç</option>
              {advertisers.length > 0 &&
                advertisers.map((v, i) => {
                  return (
                    <option key={i} value={v.id}>
                      {decodeEntities(v.name)}
                    </option>
                  )
                })}
            </select>
          </div>

          <div className='col-6'>
            <select
              className='form-select'
              aria-label='Select example'
              onChange={(e) => {
                setSelectedCampaign(e.target.value)
                loadDetails(e.target.value)
              }}
            >
              <option value={''}>Kampanya Seç</option>
              {campaigns.length > 0 &&
                campaigns.map((v, i) => {
                  return (
                    <option key={v.id} value={v.id}>
                      {decodeEntities(v.name)}
                    </option>
                  )
                })}
            </select>
          </div>
        </div>
        {selectedCampaign && <Table data={data} columns={customColumns} />}
        {selectedCreative && (
          <CreativeModal
            type={selectedCreative.creativeTypeId}
            url={selectedCreative.url}
            name={decodeEntities(selectedCreative.name)}
            showModal={() => setSelectedCreative(null)}
          />
        )}
      </KTCard>
      {showScreenshotModal && (
        <ScreenShotModal creatives={ssCreatives} showModal={setShowScreenshotModal} offer={ssOffer} />
      )}
    </>
  )
}

export {ReportOffers}
