import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {DateRange} from 'react-date-range'
import {addCampaign, getSecondaryAdvertisers} from '../../../../services/campaigns.service'
import {useParams} from 'react-router-dom'
import moment from 'moment'
import {addDays} from 'date-fns'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import {decodeEntities} from '../../../../utils/format'

const locale = require('date-fns/locale/tr/index.js')

const editCampaignSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
  advertiserId: Yup.number().required('Advertiser is required'),
  secondaryAdvertiserId: Yup.number().nullable(),
  start: Yup.date().required('Start date is required'),
  end: Yup.date().required('End date is required'),
})

const AddModalForm = ({advertisers, showAddModal, fetchData}) => {
  let {agency_id} = useParams()

  const [secondaryAdvertisers, setSecondaryAdvertisers] = useState([])

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection',
    },
  ])

  const [campaign] = useState({
    name: '',
    advertiserId: null,
    secondaryAdvertiserId: null,
    start: '',
    end: '',
  })

  const formik = useFormik({
    initialValues: campaign,
    validationSchema: editCampaignSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const response = await addCampaign(agency_id, values)
        if (response.success) {
          fetchData()
          showAddModal(false)
        } else {
          Swal.fire('Error!', response.data.data.errors[0].message, 'error')
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })

  const getSecondary = async (id) => {
    const data = await getSecondaryAdvertisers(agency_id, id)
    if (data.success) {
      setSecondaryAdvertisers(data.data)
    }
  }

  useEffect(() => {
    if (!formik.errors.advertiserId && formik.values.advertiserId) {
      getSecondary(formik.values.advertiserId)
    } else {
      setSecondaryAdvertisers([])
    }
  }, [formik.values])

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Kampanya Adı</label>
            <input
              placeholder='Full name'
              {...formik.getFieldProps('name')}
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.name && formik.errors.name},
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Müşteri</label>
            <select
              className='form-select'
              aria-label='Select example'
              onChange={(e) => {
                formik.setFieldValue('advertiserId', parseInt(e.target.value))
              }}
            >
              <option value={''}>Müşteri Seç</option>
              {advertisers.length > 0 &&
                advertisers.map((v, i) => {
                  return (
                    <option key={i} value={v.id}>
                      {decodeEntities(v.name)}
                    </option>
                  )
                })}
            </select>
            {formik.touched.advertiserId && formik.errors.advertiserId && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.advertiserId}</span>
              </div>
            )}
          </div>
          {/* end::Input group */}

          {secondaryAdvertisers.length > 0 && (
            <div className='fv-row mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Marka</label>
              <select
                className='form-select'
                aria-label='Select example'
                onChange={(e) =>
                  formik.setFieldValue('secondaryAdvertiserId', parseInt(e.target.value))
                }
              >
                <option value={''}>Marka Seç</option>
                {secondaryAdvertisers.map((v, i) => {
                  return (
                    <option key={i} value={v.id}>
                      {decodeEntities(v.name)}
                    </option>
                  )
                })}
              </select>
              {formik.touched.secondaryAdvertiserId && formik.errors.secondaryAdvertiserId && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.secondaryAdvertiserId}</span>
                </div>
              )}
            </div>
          )}

          {/* begin::Input group */}
          <div className='mb-7'>
            <label className='required fw-bold fs-6 mb-5'>Kampanya Dönemi</label>
            <div className='row'>
              <DateRange
                locale={locale}
                editableDateInputs={true}
                onChange={(item) => {
                  setState([item.selection])
                  formik.setFieldValue(
                    'start',
                    moment(item.selection.startDate).format('YYYY-MM-DD')
                  )
                  formik.setFieldValue('end', moment(item.selection.endDate).format('YYYY-MM-DD'))
                }}
                moveRangeOnFirstSelection={false}
                ranges={state}
                minDate={new Date()}
              />
            </div>
          </div>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Gönder</span>
            {formik.isSubmitting && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {/* {(formik.isSubmitting || isUserLoading) && <UsersListLoading />} */}
    </>
  )
}

export default AddModalForm
