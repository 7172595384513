import {TextField} from '@mui/material'
import React, {useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import {KTCard} from '../../../_metronic/helpers'
import {changePassword} from '../../services/auth'

function ChangePassword() {
  const [oldPass, setOld] = useState('')
  const [newPass, setNew] = useState('')
  const [newCheck, setNewCheck] = useState('')

  const [newPassErr, setNewErr] = useState(false)
  const [newCheckErr, setNewCheckErr] = useState(false)

  const handleSubmit = async () => {
    const res = await changePassword({oldPass, newPass, newCheck})
    if (res.success) {
      Swal.fire('Başarılı', 'Şifreniz başarıyla değiştirildi.', 'success')
      setOld('')
      setNew('')
      setNewCheck('')
    } else {
      Swal.fire('Hata', res.data.data.errors[0].message, 'error')
    }
  }

  useEffect(() => {
    if (newPass.length > 0 && newPass.length < 6) {
      setNewErr(true)
    } else {
      setNewErr(false)
    }
  }, [newPass])

  useEffect(() => {
    if (newCheck.length > 0 && newCheck.length < 6) {
      setNewCheckErr(true)
    } else {
      setNewCheckErr(false)
    }
  }, [newCheck])

  return (
    <KTCard className='p-4'>
      <div className='row'>
        <div className='col-md-12 mb-4'>
          <h5>Eski Şifre: </h5>
          <input
            type='password'
            className='form-control'
            placeholder='Eski Şifre'
            aria-label='şifre'
            aria-describedby='basic-addon2'
            value={oldPass}
            onChange={(e) => setOld(e.target.value)}
          />
        </div>
        <div className='col-md-12 mb-4'>
          <h5>Yeni Şifre: </h5>
          <TextField
            error={newPassErr}
            type='password'
            className='form-control'
            placeholder='Yeni Şifre'
            aria-label='şifre'
            aria-describedby='basic-addon2'
            value={newPass}
            onChange={(e) => setNew(e.target.value)}
          />
          {newPassErr && (
            <span style={{color: 'rgb(203 45 42)'}}>
              Lütfen en az 6 karakter uzunluğunda bir değer giriniz.
            </span>
          )}
        </div>
        <div className='col-md-12 mb-4'>
          <h5>Yeni Şifre Tekrar: </h5>
          <TextField
            error={newCheckErr}
            type='password'
            className='form-control'
            placeholder='Yeni Şifre Tekrar'
            aria-label='şifre'
            aria-describedby='basic-addon2'
            value={newCheck}
            onChange={(e) => setNewCheck(e.target.value)}
          />
          {newCheckErr && (
            <span style={{color: 'rgb(203 45 42)'}}>
              Lütfen en az 6 karakter uzunluğunda bir değer giriniz.
            </span>
          )}
        </div>
        <div className='col-12 mt-4'>
          <button className='btn btn-success w-100' onClick={() => handleSubmit()}>
            Güncelle
          </button>
        </div>
      </div>
    </KTCard>
  )
}

export default ChangePassword
