import React, {useEffect} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'

function DetailModal({showModal, detail}) {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>Rapor Detayı</h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => showModal(false)}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <div className='d-flex flex-column'>
                <div className='row'>
                  <div className='col-3 fw-bold'>Filtreler:</div>
                  <div className='col-9' style={{lineHeight: '22px'}}>
                    {detail.filters}
                  </div>
                </div>

                <div className='row mt-4'>
                  <div className='col-3 fw-bold'>Kırılımlar:</div>
                  <div className='col-9' style={{lineHeight: '22px'}}>
                    {detail.breaks}
                  </div>
                </div>

                <button className='btn btn-secondary mt-4' onClick={(e) => showModal(false)}>
                  Kapat
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default DetailModal
