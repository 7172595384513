import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import Time from '../../../agency/offers/Time/Time'
import {decodeEntities} from '../../../../utils/format'

function ProgramDetailModal({showModal, program}) {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>Programı Görüntüle</h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => showModal(false)}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='modal-body scroll-y mx-5 my-7'>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-md-12 mb-4'>
                    <h5>Program Adı: </h5>
                    <span>{decodeEntities(program.title)}</span>
                  </div>
                  <div className='col-md-12 mb-4'>
                    <h5>Açıklama: </h5>
                    <span>{program.description}</span>
                  </div>
                  <div className='col-md-12 mb-4'>
                    <h5>Overlay Delay (sn): </h5>
                    <span>{program.overlayDelay}</span>
                  </div>
                  <div className='col-md-12 mb-4'>
                    <div className='form-check form-check-custom form-check-solid mt-2'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value=''
                        id='flexCheckDefault1'
                        disabled
                        checked={program.pssEnabled}
                      />
                      <label className='form-check-label' for='flexCheckDefault'>
                        PSS gösterimine onay veriyorum.
                      </label>
                    </div>
                  </div>
                  <div className='col-md-6 mb-4'>
                    <h5>PSS Delay (sn): </h5>
                    <span>{program.pssDelay}</span>
                  </div>
                  <div className='col-md-6 mb-4'>
                    <h5>PSS Drift (sn): </h5>
                    <span>{program.pssDrift}</span>
                  </div>

                  {/* <div className='row'>
                    <div className='col-md-6 mb-4'>
                      <h5>Program: </h5>
                      <span>{data.program.title}</span>
                    </div>

                    <div className='col-md-6 mb-4'>
                      <div className='form-group'>
                        <h5>
                          <label htmlFor='programId' className='control-label'>
                            Yayın Tipi
                          </label>
                        </h5>
                        <div className='mt-radio-inline'>
                          <label className='mt-radio'>
                            <input
                              type='radio'
                              name='isReplay'
                              value='0'
                              checked
                              disabled='disabled'
                            />{' '}
                            Canlı
                            <span></span>
                          </label>
                          <label className='mt-radio ms-4'>
                            <input type='radio' name='isReplay' value='1' disabled='disabled' />
                            Tekrar
                            <span></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default ProgramDetailModal
