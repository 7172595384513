import {Divider} from 'antd'
import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import ReactSelect from 'react-select'
import Swal from 'sweetalert2'
import {DatePicker} from 'antd'
import moment from 'moment-timezone/builds/moment-timezone-with-data'
import 'moment/locale/tr'
import locale from 'antd/es/date-picker/locale/tr_TR'
import {KTCard, KTSVG} from '../../../../_metronic/helpers'
import ConfirmModal from './confirmModal'
import Table from './components/Table'
import DetailModal from './detailModal'
import {
  deletePublisherReport,
  downloadPublisherReport,
  getPublisherReport,
} from '../../../services/publishers.service'
import {useThemeMode} from '../../../../_metronic/partials'
import {decodeEntities} from '../../../utils/format'

const {RangePicker} = DatePicker

const customStyles = {
  multiValue: (base, state) => {
    return {...base, backgroundColor: '#0e0e15'}
  },
  multiValueLabel: (base, state) => {
    return {...base, fontWeight: 'bold', color: '#92929F', paddingRight: 6}
  },
  option: (base) => {
    return {...base, color: '#92929F', backgroundColor: '#1e1e2d'}
  },
  menu: (base) => {
    return {...base, color: '#92929F', backgroundColor: '#1e1e2d'}
  },
  control: (base) => {
    return {
      ...base,
      color: '#92929F',
      backgroundColor: '#1e1e2d',
      borderColor: '#323248',
      maxHeight: 150,
    }
  },
  valueContainer: (provided, state) => ({
    ...provided,
    maxHeight: '150px',
    overflow: 'scroll',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#fff',
  }),
}

function PublisherReports() {
  let {publisher_id} = useParams()
  const {mode} = useThemeMode()

  const [modal, showModal] = useState(false)
  const [selectedDetail, setSelectedDetail] = useState(false)

  const [initData, setInitData] = useState({})

  const [reportsData, setReportsData] = useState([])

  const [channels, setChannels] = useState([])
  const [selectedChannels, setSelectedChannels] = useState([])

  const [advertisers, setAdvertisers] = useState([])
  const [selectedAdvertisers, setSelectedAdvertisers] = useState([])

  const [operators, setOperators] = useState([])
  const [selectedOperators, setSelectedOperators] = useState([])

  const [devices, setDevices] = useState([])
  const [selectedDevices, setSelectedDevices] = useState([])

  const [ages, setAges] = useState([])
  const [selectedAges, setSelectedAges] = useState([])

  const [genders, setGenders] = useState([])
  const [selectedGenders, setSelectedGenders] = useState([])

  const [periodParams, setPeriodParams] = useState([])

  const [selectedDate, setSelectedDate] = useState({
    start: moment().startOf('month').unix(),
    end: moment().endOf('month').unix(),
  })

  const [channelGroup, setChannelGroup] = useState(false)
  const [periodCheck, setPeriodCheck] = useState(false)
  const [agencyGroup, setAgencyGroup] = useState(false)
  const [advertiserGroup, setAdvertiserGroup] = useState(false)
  const [periodGroup, setPeriodGroup] = useState(null)
  const [operatorGroup, setOperatorGroup] = useState(false)
  const [deviceGroup, setDeviceGroup] = useState(false)
  const [ageGroup, setAgeGroup] = useState(false)
  const [genderGroup, setGenderGroup] = useState(false)

  const [isMaestro, setIsMaestro] = useState(false)
  const [dataParams, setDataParams] = useState('')

  const loadInit = async () => {
    const res = await getPublisherReport(publisher_id)
    if (!res.success) {
      Swal.fire('Bir sorun çıktı.', res.data.data.errors[0].message, 'error')
    } else {
      setInitData(res.data)

      setSelectedDate({
        start: moment().tz(res.data.publisher.timezone).startOf('month').unix(),
        end: moment().tz(res.data.publisher.timezone).endOf('month').unix(),
      })
      let channelsArr = []
      res.data.channelsJSON.forEach((v) => {
        channelsArr.push({label: v.name, value: v.id})
      })
      setChannels(channelsArr)

      let advertisersArr = []
      res.data.advertisersJSON.forEach((v) => {
        advertisersArr.push({label: decodeEntities(v.name), value: v.id})
      })
      setAdvertisers(advertisersArr)

      let operatorArr = []
      res.data.operatorJSON.forEach((v) => {
        operatorArr.push({label: v.name, value: v.id})
      })
      setOperators(operatorArr)

      let deviceArr = []
      res.data.deviceJSON.forEach((v) => {
        deviceArr.push({label: v.name, value: v.id})
      })
      setDevices(deviceArr)

      let ageArr = []
      res.data.ageJSON.forEach((v) => {
        ageArr.push({label: v.name, value: v.id})
      })
      setAges(ageArr)

      let genderArr = []
      res.data.genderJSON.forEach((v) => {
        genderArr.push({label: v.name, value: v.id})
      })
      setGenders(genderArr)

      let periodArr = []
      Object.keys(res.data.periodParams).forEach((v) => {
        periodArr.push({label: res.data.periodParams[v], value: v})
      })
      setPeriodParams(periodArr)
    }
  }

  const loadReports = async () => {
    const res = await getPublisherReport(publisher_id)
    if (!res.success) {
      Swal.fire('Bir sorun çıktı.', res.data.data.errors[0].message, 'error')
    } else {
      setReportsData(res.data.reportsJSON)
    }
  }

  useEffect(() => {
    loadInit()
    loadReports()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      loadReports()
    }, 15000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const createReport = () => {
    let params = ''
    params += 'start=' + selectedDate.start + '&end=' + selectedDate.end

    selectedChannels.forEach((v) => {
      params += '&channelWhere[]=' + v.value
    })

    selectedAdvertisers.forEach((v) => {
      params += '&advertiserWhere[]=' + v.value
    })

    selectedAges.forEach((v) => {
      params += '&ageWhere[]=' + v.value
    })

    selectedGenders.forEach((v) => {
      params += '&genderWhere[]=' + v.value
    })

    selectedDevices.forEach((v) => {
      params += '&deviceWhere[]=' + v.value
    })

    selectedOperators.forEach((v) => {
      params += '&operatorWhere[]=' + v.value
    })

    if (periodCheck) params += '&periodCheck=' + periodCheck + '&periodGroup=' + periodGroup.value
    if (channelGroup) params += '&channelGroup=' + channelGroup
    if (agencyGroup) params += '&agencyGroup=' + agencyGroup
    if (advertiserGroup) params += '&advertiserGroup=' + advertiserGroup
    if (operatorGroup) params += '&operatorGroup=' + operatorGroup
    if (deviceGroup) params += '&deviceGroup=' + deviceGroup
    if (genderGroup) params += '&genderGroup=' + genderGroup
    if (ageGroup) params += '&ageGroup=' + ageGroup

    setIsMaestro(false)
    setDataParams(params)
    showModal(true)
  }

  const createMaestroReport = () => {
    let params = ''
    params += 'start=' + selectedDate.start + '&end=' + selectedDate.end

    setDataParams(params)
    setIsMaestro(true)
    showModal(true)
  }

  const customColumns = [
    {
      Header: (props) => (
        <th {...props.column.getHeaderProps()} className='min-w-125px' style={{cursor: 'pointer'}}>
          Rapor Adı
        </th>
      ),
      accessor: 'name',
      Cell: ({...props}) => {
        let value = props.row.original
        return (
          <div className='d-flex flex-column'>
            <span>{decodeEntities(value.name)}</span>
          </div>
        )
      },
    },
    {
      Header: (props) => (
        <th {...props.column.getHeaderProps()} className='min-w-125px' style={{cursor: 'pointer'}}>
          Oluşturulma Tarihi
        </th>
      ),
      accessor: 'createdAt',
      Cell: ({...props}) => {
        let value = props.row.original
        return (
          <div className='d-flex flex-column'>
            <span>
              {moment(value.createdAt).tz(initData.publisher.timezone).format('DD-MM-YYYY HH:mm')}
            </span>
          </div>
        )
      },
    },
    {
      Header: (props) => (
        <th {...props.column.getHeaderProps()} className='min-w-125px' style={{cursor: 'pointer'}}>
          Oluşturan Kullanıcı
        </th>
      ),
      accessor: 'user',
      Cell: ({...props}) => {
        let value = props.row.original
        return (
          <div className='d-flex flex-column'>
            <span>
              {decodeEntities(value.creator.name)} {decodeEntities(value.creator.surname)}
            </span>
          </div>
        )
      },
    },
    {
      Header: (props) => (
        <th
          {...props.column.getHeaderProps()}
          className='min-w-125px text-end'
          style={{cursor: 'pointer'}}
        >
          İNDİR
        </th>
      ),
      accessor: 'download',
      Cell: ({...props}) => {
        let value = props.row.original
        return (
          <div className='d-flex justify-content-end flex-shrink-0'>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => {
                setSelectedDetail(value)
              }}
            >
              <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-3' />
            </button>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={async () => {
                const res = await downloadPublisherReport(publisher_id, value.id, value.name)
                if (!res.success) {
                  Swal.fire('Bir sorun çıktı.', res.data.data.errors[0].message, 'error')
                } else {
                  Swal.fire('İndiriliyor', '', 'success')
                }
              }}
            >
              <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-3' />
            </button>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => {
                Swal.fire({
                  title: 'Silmek istediğine emin misin?',
                  text: 'Sildiğinde geri dönüşü olmayacak!',
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  cancelButtonText: 'İptal',
                  confirmButtonText: 'Evet, sil!',
                  preConfirm: async () => {
                    const res = await deletePublisherReport(publisher_id, value.id)
                    if (res.success) {
                      Swal.fire('Silindi!', 'Rapor başarıyla silindi.', 'success')
                      loadReports()
                    } else {
                      Swal.fire(
                        'Hata!',
                        res.data.data
                          ? res.data.data.errors[0].message
                          : 'Bir sorunla karşılaştık.',
                        'error'
                      )
                    }
                  },
                  allowOutsideClick: () => !Swal.isLoading(),
                })
              }}
            >
              <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
            </button>
          </div>
        )
      },
    },
  ]

  return (
    <>
      <KTCard className='p-4'>
        <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
          <li className='nav-item'>
            <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_1'>
              Rapor Oluştur
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_2'>
              Maestro Rapor
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_3'>
              Raporlarım
            </a>
          </li>
        </ul>
        <div className='tab-content' id='myTabContent'>
          <div className='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
            <div className='row'>
              <div className='col-12'>
                <label className='w-100'>Rapor Tarihi</label>
                {initData.publisher && (
                  <RangePicker
                    locale={locale}
                    defaultValue={[
                      moment().tz(initData.publisher.timezone).startOf('month'),
                      moment().tz(initData.publisher.timezone).endOf('month'),
                    ]}
                    format={'DD.MM.YYYY'}
                    onChange={(e) =>
                      setSelectedDate({
                        start: e[0].tz(initData.publisher.timezone).unix(),
                        end: e[1].tz(initData.publisher.timezone).endOf('day').unix(),
                      })
                    }
                    className='mt-2'
                  />
                )}
              </div>
              <div className='col-4 mt-4'>
                <label className='mb-2'>Kanallar</label>
                <ReactSelect
                  isMulti
                  options={channels}
                  styles={mode === 'light' ? {} : customStyles}
                  closeMenuOnSelect={false}
                  onChange={(e) => setSelectedChannels(e)}
                  classNamePrefix='select'
                />
              </div>
              <div className='col-4 mt-4'>
                <label className='mb-2'>Müşteriler</label>
                <ReactSelect
                  isMulti
                  options={advertisers}
                  styles={mode === 'light' ? {} : customStyles}
                  closeMenuOnSelect={false}
                  onChange={(e) => setSelectedAdvertisers(e)}
                  classNamePrefix='select'
                />
              </div>
              <div className='col-4 mt-4'>
                <label className='mb-2'>Yaş Aralığı</label>
                <ReactSelect
                  isMulti
                  options={ages}
                  styles={mode === 'light' ? {} : customStyles}
                  closeMenuOnSelect={false}
                  onChange={(e) => setSelectedAges(e)}
                  classNamePrefix='select'
                />
              </div>
              <div className='col-4 mt-4'>
                <label className='mb-2'>Cinsiyet</label>
                <ReactSelect
                  isMulti
                  options={genders}
                  styles={mode === 'light' ? {} : customStyles}
                  closeMenuOnSelect={false}
                  onChange={(e) => setSelectedGenders(e)}
                  classNamePrefix='select'
                />
              </div>
              <div className='col-4 mt-4'>
                <label className='mb-2'>Cihazlar</label>
                <ReactSelect
                  isMulti
                  options={devices}
                  styles={mode === 'light' ? {} : customStyles}
                  closeMenuOnSelect={false}
                  onChange={(e) => setSelectedDevices(e)}
                  classNamePrefix='select'
                />
              </div>
              <div className='col-4 mt-4'>
                <label className='mb-2'>Operatörler</label>
                <ReactSelect
                  isMulti
                  options={operators}
                  styles={mode === 'light' ? {} : customStyles}
                  closeMenuOnSelect={false}
                  onChange={(e) => setSelectedOperators(e)}
                  classNamePrefix='select'
                />
              </div>
            </div>

            <label className='mt-6 fs-6 fw-bold'>Rapor Kırılımları</label>
            <Divider />
            <div className='row'>
              <div className='col-3'>
                <div className='form-check form-check-custom form-check-solid mt-2'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value=''
                    id='flexCheckDefault1'
                    onChange={(e) => {
                      setPeriodCheck(e.target.checked)
                    }}
                  />
                  <label className='form-check-label' for='flexCheckDefault'>
                    Period
                  </label>
                </div>
                {periodCheck && (
                  <ReactSelect
                    options={periodParams}
                    styles={mode === 'light' ? {} : customStyles}
                    closeMenuOnSelect={false}
                    onChange={(e) => setPeriodGroup(e)}
                    classNamePrefix='select'
                    className={'mt-2'}
                  />
                )}
              </div>

              <div className='col-3'>
                <div className='form-check form-check-custom form-check-solid mt-2'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value=''
                    id='flexCheckDefault1'
                    onChange={(e) => {
                      setChannelGroup(e.target.checked)
                    }}
                  />
                  <label className='form-check-label' for='flexCheckDefault'>
                    Kanal
                  </label>
                </div>
              </div>

              <div className='col-3'>
                <div className='form-check form-check-custom form-check-solid mt-2'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value=''
                    id='flexCheckDefault1'
                    onChange={(e) => {
                      setAgencyGroup(e.target.checked)
                    }}
                  />
                  <label className='form-check-label' for='flexCheckDefault'>
                    Ajans
                  </label>
                </div>
              </div>

              <div className='col-3'>
                <div className='form-check form-check-custom form-check-solid mt-2'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value=''
                    id='flexCheckDefault1'
                    onChange={(e) => {
                      setAdvertiserGroup(e.target.checked)
                    }}
                  />
                  <label className='form-check-label' for='flexCheckDefault'>
                    Müşteri
                  </label>
                </div>
              </div>

              <div className='col-3 mt-4'>
                <div className='form-check form-check-custom form-check-solid mt-2'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value=''
                    id='flexCheckDefault1'
                    onChange={(e) => {
                      setOperatorGroup(e.target.checked)
                    }}
                  />
                  <label className='form-check-label' for='flexCheckDefault'>
                    Operatör
                  </label>
                </div>
              </div>

              <div className='col-3 mt-4'>
                <div className='form-check form-check-custom form-check-solid mt-2'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value=''
                    id='flexCheckDefault1'
                    onChange={(e) => {
                      setAgeGroup(e.target.checked)
                    }}
                  />
                  <label className='form-check-label' for='flexCheckDefault'>
                    Yaş Aralığı
                  </label>
                </div>
              </div>

              <div className='col-3 mt-4'>
                <div className='form-check form-check-custom form-check-solid mt-2'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value=''
                    id='flexCheckDefault1'
                    onChange={(e) => {
                      setGenderGroup(e.target.checked)
                    }}
                  />
                  <label className='form-check-label' for='flexCheckDefault'>
                    Cinsiyet
                  </label>
                </div>
              </div>

              <div className='col-3 mt-4'>
                <div className='form-check form-check-custom form-check-solid mt-2'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value=''
                    id='flexCheckDefault1'
                    onChange={(e) => {
                      setDeviceGroup(e.target.checked)
                    }}
                  />
                  <label className='form-check-label' for='flexCheckDefault'>
                    Cihaz
                  </label>
                </div>
              </div>
            </div>
            <Divider />
            <div className='d-flex justify-content-center mt-4'>
              <button className='btn btn-primary' onClick={createReport}>
                Rapor Oluştur
              </button>
            </div>
          </div>
          <div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>
            <div className='row'>
              <div className='col-12'>
                <label className='w-100'>Rapor Tarihi</label>
                {initData.publisher && (
                  <RangePicker
                    locale={locale}
                    defaultValue={[
                      moment().tz(initData.publisher.timezone).startOf('month'),
                      moment().tz(initData.publisher.timezone).endOf('month'),
                    ]}
                    format={'DD.MM.YYYY'}
                    onChange={(e) =>
                      setSelectedDate({
                        start: e[0].tz(initData.publisher.timezone).unix(),
                        end: e[1].tz(initData.publisher.timezone).endOf('day').unix(),
                      })
                    }
                    className='mt-2'
                  />
                )}
              </div>
            </div>

            <div className='d-flex justify-content-center mt-4'>
              <button className='btn btn-primary' onClick={createMaestroReport}>
                Rapor Oluştur
              </button>
            </div>
          </div>
          <div className='tab-pane fade' id='kt_tab_pane_3' role='tabpanel'>
            {reportsData && initData.publisher && (
              <Table data={reportsData} columns={customColumns} />
            )}
          </div>
        </div>
      </KTCard>
      {modal && (
        <ConfirmModal
          showModal={showModal}
          dataParams={dataParams}
          id={publisher_id}
          loadReports={loadReports}
          isMaestro={isMaestro}
        />
      )}
      {selectedDetail && <DetailModal showModal={setSelectedDetail} detail={selectedDetail} />}
    </>
  )
}

export default PublisherReports
