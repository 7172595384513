import {instance} from './http'

export const getScreenshotUrl = (agency, offer, data) => {
  data.ssUrl = data.url
  return instance
    .post(`/agencies/${agency}/offers/${offer}/screenshot`, data)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}
