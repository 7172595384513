import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {DatePicker} from 'antd'
import {useParams} from 'react-router-dom'
import clsx from 'clsx'
import moment from 'moment'
import ReactSelect from 'react-select'
import locale from 'antd/es/date-picker/locale/tr_TR'
import './style.css'
import Time from '../../../agency/offers/Time/Time'
import {updateTrailer} from '../../../../services/publishers.service'
import Swal from 'sweetalert2'
import {useThemeMode} from '../../../../../_metronic/partials'
import {decodeEntities} from '../../../../utils/format'

const {RangePicker} = DatePicker

const customStyles = {
  multiValue: (base, state) => {
    return {...base, backgroundColor: '#0e0e15'}
  },
  multiValueLabel: (base, state) => {
    return {...base, fontWeight: 'bold', color: '#92929F', paddingRight: 6}
  },
  option: (base) => {
    return {...base, color: '#92929F', backgroundColor: '#1e1e2d'}
  },
  menu: (base) => {
    return {...base, color: '#92929F', backgroundColor: '#1e1e2d'}
  },
  control: (base) => {
    return {
      ...base,
      color: '#92929F',
      backgroundColor: '#1e1e2d',
      borderColor: '#323248',
      maxHeight: 200,
    }
  },
  valueContainer: (provided, state) => ({
    ...provided,
    maxHeight: '200px',
    overflow: 'scroll',
  }),
}

function UpdateModal({showUpdateModal, channels, fetchData, data}) {
  let {publisher_id} = useParams()
  const {mode} = useThemeMode()

  const [channelsOptions] = useState(() => {
    let arr = []
    channels.forEach((v) => {
      arr.push({label: v.name, value: v.id})
    })
    return arr
  })

  const [selectedChannels, setSelectedChannels] = useState([])
  const [datePicker, setDatePicker] = useState({start: data.startDate, end: data.endDate})
  const [time, setTime] = useState(data.endHour ?? '12:00')
  const [logo, setLogo] = useState(data.hasLogo)
  const [name, setName] = useState(decodeEntities(data.creative.name))

  useEffect(() => {
    if (data.channels.length > 0) {
      let arr = []
      data.channels.forEach((v) => {
        arr.push({label: v.name, value: v.id})
      })
      setSelectedChannels(arr)
    }
  }, [])

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const disabledDate = (current, start, end) => {
    if (start && end) {
      return current < moment(start).add(-1, 'day') || current >= moment(end)
    } else if (start && !end) {
      return current && current < moment(start)
    } else return false
  }

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>Tanıtımı Güncelle</h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => showUpdateModal(null)}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <div
                className='d-flex flex-column scroll-y me-n7 pe-7'
                id='kt_modal_add_user_scroll'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: false, lg: true}'
                data-kt-scroll-max-height='auto'
                data-kt-scroll-dependencies='#kt_modal_add_user_header'
                data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                data-kt-scroll-offset='300px'
              >
                <div className='row'>
                  <div className='col-12 col-md-6'>
                    <label className='fw-bold fs-6 mb-2'>Tanıtım Adı</label>
                    <input
                      placeholder='Name'
                      type='text'
                      name='name'
                      className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                      autoComplete='off'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className='col-12 col-md-6'>
                    <label className='fw-bold fs-6 mb-2'>Kanallar</label>
                    <ReactSelect
                      styles={mode === 'light' ? {} : customStyles}
                      isMulti
                      closeMenuOnSelect={false}
                      options={channelsOptions}
                      value={selectedChannels}
                      onChange={(e) => setSelectedChannels(e)}
                    />
                  </div>
                  <div className='col-12 mt-4'>
                    <label className='fw-bold fs-6 mb-2'>Başlangıç - Bitiş</label>
                    <RangePicker
                      locale={locale}
                      className='w-100'
                      disabledDate={(e) =>
                        disabledDate(
                          e,
                          data.startDate && data.startDate < moment() ? data.startDate : moment()
                        )
                      }
                      defaultValue={[moment(data.startDate), moment(data.endDate)]}
                      format={'DD.MM.YYYY'}
                      onChange={(e) => setDatePicker({start: e[0], end: e[1]})}
                    />
                  </div>
                  <div className='col-12 col-md-6 mt-4'>
                    <label className='fw-bold fs-6 mb-2'>Bitiş Saati</label>
                    <div className='w-100'>
                      <Time
                        time={time}
                        onChange={(e) => {
                          setTime(e)
                        }}
                      />
                    </div>
                  </div>
                  <div className='col-12 col-md-6 mt-4'>
                    <label className='fw-bold fs-6 mb-5'>Logo</label>
                    <div className='d-flex flex-row'>
                      <div className=''>
                        <input
                          className='form-check-input'
                          type='radio'
                          checked={logo}
                          value={true}
                          name='logo'
                          id='flexRadioSm'
                          onChange={(e) => {
                            setLogo(e.target.value === 'true' ? true : false)
                          }}
                        />
                        <label className='form-check-label ms-2' for='flexRadioSm'>
                          Var
                        </label>
                      </div>

                      <div className='ms-4'>
                        <input
                          className='form-check-input'
                          type='radio'
                          checked={!logo}
                          value={false}
                          name='logo'
                          id='flexRadioSm'
                          onChange={(e) => {
                            setLogo(e.target.value === 'true' ? true : false)
                          }}
                        />
                        <label className='form-check-label ms-2' for='flexRadioSm'>
                          Yok
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 mt-4'>
                    <button
                      className='btn btn-success'
                      onClick={async () => {
                        let params = {}
                        params.id = data.id
                        params.name = name
                        params.channels = selectedChannels.map((chn) => chn.value)
                        params.start = moment(datePicker.start).format('YYYY-MM-DD')
                        params.end = moment(datePicker.end).format('YYYY-MM-DD') + ' ' + time
                        params.startInput = moment(datePicker.start).format('DD MMM YYYY')
                        params.endInput = moment(datePicker.end).format('DD MMM YYYY')
                        params.endTime = time
                        params.logo = logo

                        const res = await updateTrailer(publisher_id, data.id, params)
                        if (!res.success) {
                          Swal.fire(
                            'Hata!',
                            res.data.data.errors[0].message ?? 'Daha sonra tekrar deneyiniz.',
                            'error'
                          )
                        } else {
                          fetchData()
                          Swal.fire('Başarılı!', 'Tanıtım başarıyla güncellendi.', 'success')
                        }
                      }}
                    >
                      Güncelle
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default UpdateModal
