import {instance} from './http'

export const getOffers = (agency) => {
  return instance
    .get(`/agencies/${agency}/offers`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const getOffer = (agency, id) => {
  return instance
    .get(`/agencies/${agency}/offers/${id}/edit`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const getOfferDetail = (agency, id) => {
  return instance
    .get(`/agencies/${agency}/offers/${id}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const createOffer = (agency, data) => {
  return instance
    .post(`/agencies/${agency}/offers`, data)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const updateOffer = (agency, id, data) => {
  return instance
    .put(`/agencies/${agency}/offers/${id}`, data)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const getEvents = (agency, query) => {
  return instance
    .get(`/agencies/${agency}/events?${query}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const getReportOffers = (agency) => {
  return instance
    .get(`/agencies/${agency}/report/offers`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const getCampaignDetail = (agency, customerId, campaignId) => {
  return instance
    .get(`/agencies/${agency}/customers/${customerId}/campaigns/${campaignId}/detail`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const setAgencyOfferStatu = (agency, offerId, bodyFormData) => {
  return instance
    .post(`/agencies/${agency}/offers/${offerId}/status`, bodyFormData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    })
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const getAgencyReports = (agency, queryData) => {
  let query = ''
  if (queryData) query = `?time[part]=${queryData.value.part}&time[month]=${queryData.value.month}`
  return instance
    .get(`/agencies/${agency}/reports${query}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const getAgencyReportsCampaigns = (agency, adverId) => {
  return instance
    .get(`/agencies/${agency}/reports/${adverId}/campaigns`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const getAgencyReportsCampaignsOffers = (agency, adverId, campaignId) => {
  return instance
    .get(`/agencies/${agency}/reports/${adverId}/campaigns/${campaignId}/offers`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const getAgencyReportsCampaignsOffersFilter = (
  agency,
  adverId,
  campaignId,
  offerId,
  params
) => {
  return instance
    .get(
      `/agencies/${agency}/reports/${adverId}/campaigns/${campaignId}/offers/${offerId}?${params}`,
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      }
    )
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const createAgencyReport = (agency, params) => {
  params = new URLSearchParams(params).toString()
  return instance
    .post(`/agencies/${agency}/reports/downloadReport`, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    })
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const deleteAgencyReport = (agency, id) => {
  return instance
    .delete(`/agencies/${agency}/reports/${id}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const downloadReport = (agency, id, name) => {
  return instance
    .post(
      `/agencies/${agency}/reports/download`,
      {
        reportId: id,
      },
      {
        responseType: 'blob',
      }
    )
    .then((res) => {
      const href = URL.createObjectURL(res.data)

      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', `${name}.xlsx`) //or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}
