import moment from 'moment'
import React, {useContext, useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import {KTSVG} from '../../../../../_metronic/helpers'
import {UserContext} from '../../../../contexts/auth'
import {updateChannelsProgram} from '../../../../services/publishers.service'
import Time from '../../../agency/offers/Time/Time'
import {decodeEntities} from '../../../../utils/format'

function ProgramUpdateModal({showModal, program, fetchData}) {
  const userDetails = useContext(UserContext)

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const [updateData, setUpdateData] = useState({
    id: program.id,
    title: decodeEntities(program.title),
    description: program.description,
    genres: [],
    tags: [],
    overlay: program.overlayDelay,
    pss: program.pssEnabled ? 1 : 0,
    pssDelay: program.pssDelay,
    pssDrift: program.pssDrift,
    hbb: program.hbbEnabled ? 1 : 0,
    hbbDelay: program.hbbDelay ? program.hbbDelay : 0,
    tvStationId: program.tvStationId,
  })

  const handleSubmit = async () => {
    var form_data = new FormData()

    let temp = updateData
    if (temp.pss === 0) {
      delete temp.pss
    }

    for (var key in updateData) {
      form_data.append(key, updateData[key] ?? '')
    }

    const res = await updateChannelsProgram(program.tvStationId, program.id, form_data)
    if (!res.success) {
      Swal.fire('Hata!', res.data.data.errors[0].message ?? 'Daha sonra tekrar deneyiniz.', 'error')
    } else {
      fetchData()
      Swal.fire('Başarılı!', 'Başarıyla güncellendi.', 'success')
      showModal(false)
    }
  }

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>Programı Görüntüle</h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => showModal(false)}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='modal-body scroll-y mx-5 my-7'>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-md-12 mb-4'>
                    <h5>Program Adı: </h5>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='başlık'
                      aria-label='başlık'
                      aria-describedby='basic-addon2'
                      value={decodeEntities(updateData.title)}
                      onChange={(e) => setUpdateData({...updateData, title: e.target.value})}
                    />
                  </div>
                  <div className='col-md-12 mb-4'>
                    <h5>Açıklama: </h5>
                    <textarea
                      type='text'
                      className='form-control'
                      placeholder='açıklama'
                      aria-label='açıklama'
                      aria-describedby='basic-addon2'
                      value={updateData.description}
                      onChange={(e) => setUpdateData({...updateData, description: e.target.value})}
                      rows={3}
                    />
                  </div>
                  <div className='col-md-12 mb-4'>
                    <h5>Overlay Delay (sn): </h5>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='0'
                      aria-label='overlay delay'
                      aria-describedby='basic-addon2'
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault()
                        }
                      }}
                      value={updateData.overlay}
                      onChange={(e) =>
                        setUpdateData({...updateData, overlay: parseInt(e.target.value)})
                      }
                    />
                  </div>
                  <div className='col-md-12 mb-4'>
                    <div className='form-check form-check-custom form-check-solid mt-2'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value=''
                        id='flexCheckDefault1'
                        checked={updateData.pss === 1 ? true : false}
                        onChange={(e) => {
                          setUpdateData({...updateData, pss: updateData.pss === 1 ? 0 : 1})
                        }}
                      />
                      <label className='form-check-label' for='flexCheckDefault'>
                        PSS gösterimine onay veriyorum.
                      </label>
                    </div>
                  </div>
                  {updateData.pss === 1 && (
                    <>
                      <div className='col-md-6 mb-4'>
                        <h5>PSS Delay (sn): </h5>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='0'
                          aria-label='pss delay'
                          aria-describedby='basic-addon2'
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault()
                            }
                          }}
                          value={updateData.pssDelay}
                          onChange={(e) =>
                            setUpdateData({...updateData, pssDelay: parseInt(e.target.value)})
                          }
                        />
                      </div>
                      <div className='col-md-6 mb-4'>
                        <h5>PSS Drift (sn): </h5>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='0'
                          aria-label='pss drift'
                          aria-describedby='basic-addon2'
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault()
                            }
                          }}
                          value={updateData.pssDrift}
                          onChange={(e) =>
                            setUpdateData({...updateData, pssDrift: parseInt(e.target.value)})
                          }
                        />
                      </div>
                    </>
                  )}

                  <div className='col-12 mt-4'>
                    <button className='btn btn-success w-100' onClick={() => handleSubmit()}>
                      Güncelle
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default ProgramUpdateModal
