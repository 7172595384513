import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import ReactSelect from 'react-select'
import {KTSVG} from '../../../../_metronic/helpers'

function SummaryModal({showModal, data, offer}) {
  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content' style={{minHeight: '80vh'}}>
            <div className='modal-header'>
              <h2 className='fw-bolder'>Reklam Teklifi Özeti</h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => showModal(false)}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <div className='row'>
                <div className='col-6'>
                  <div className='col-6'>Reklam Teklifi Adı</div>
                  <div className='col-6'>{offer.name}</div>
                </div>
                <div className='col-6'>
                  <div className='col-6'>Müşteri</div>
                  <div className='col-6'></div>
                </div>
                <div className='col-12'>
                  <div className='col-3'>Kampanya</div>
                  <div className='col-9'></div>
                </div>
                <div className='col-12'>
                  <div className='col-3'>Kreatif</div>
                  <div className='col-9'></div>
                </div>
              </div>

              <div className='row mt-4'>
                <div className='col-12'>
                  <div className='col-3 text-end'>Teklif Tarihi</div>
                  <div className='col-9'></div>
                </div>
                <div className='col-6'>
                  <div className='col-6 text-end'>Toplam Video Bütçesi</div>
                  <div className='col-6'></div>
                </div>
                <div className='col-6'>
                  <div className='col-6 text-end'>Aşım Payı</div>
                  <div className='col-6'></div>
                </div>
                <div className='col-12'>
                  <div className='col-3 text-end'>Bütçe Dağılımı</div>
                  <div className='col-9'></div>
                </div>
                <div className='col-6'>
                  <div className='col-6 text-end'>NS</div>
                  <div className='col-6'></div>
                </div>
                <div className='col-6'>
                  <div className='col-6 text-end'>PSS</div>
                  <div className='col-6'></div>
                </div>
                <div className='col-6'>
                  <div className='col-6 text-end'>NS Bütçesi</div>
                  <div className='col-6'></div>
                </div>
                <div className='col-6'>
                  <div className='col-6 text-end'>PSS Bütçesi</div>
                  <div className='col-6'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default SummaryModal
