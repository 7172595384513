import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import 'shaka-player/dist/controls.css'
import {updateCreative} from '../../../../services/creatives.service'
import {useParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import {decodeEntities} from '../../../../utils/format'

function EditModal({toggleModal, data, fetchData}) {
  let {agency_id} = useParams()
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const [form, setForm] = useState({
    id: data.id,
    name: decodeEntities(data.name),
    creativeTargetUrl: decodeEntities(data.targetUrl),
    creativeTrackingUrl: decodeEntities(data.impressionTracking),
    creativeCompletionUrl: decodeEntities(data.completionTracking),
  })
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    setLoading(true)
    const res = await updateCreative(agency_id, data.id, form)
    if (!res.success) {
      Swal.fire('Hata!', res.data.data.errors[0].message, 'error')
    } else {
      Swal.fire('Başarılı!', 'Kreatif güncellendi.', 'success')
      fetchData()
      toggleModal(null)
    }
    setLoading(false)
  }

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>Kreatifi Görüntüle</h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => toggleModal(null)}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <div className='d-flex flex-column'>
                <div className='d-flex flex-row'>
                  <span className='fw-bold fs-6'>Müşteri:</span>
                  <span className='ms-2'>{decodeEntities(data.advertiser.name)}</span>
                </div>
                <div className='fv-row mb-7 mt-4'>
                  <label className='fw-bold fs-6 mb-2'>Kreatif Adı</label>
                  <input
                    placeholder='Kreatif Adı'
                    value={form.name}
                    onChange={(e) => setForm({...form, name: e.target.value})}
                    type='text'
                    name='name'
                    className={'form-control form-control-solid mb-3 mb-lg-0'}
                    autoComplete='off'
                  />
                </div>
                <div className='fv-row mb-7'>
                  <span className='fw-bold fs-6'>Vast:</span>
                  {data.isVast ? (
                    <KTSVG
                      path='/media/icons/duotune/general/gen043.svg'
                      className='svg-icon-2 me-0 svg-icon-success'
                    />
                  ) : (
                    <KTSVG
                      path='/media/icons/duotune/general/gen040.svg'
                      className='svg-icon-2 me-0 svg-icon-danger'
                    />
                  )}
                </div>
                {data.isVast && (
                  <div className='fv-row mb-7 mt-4'>
                    <label className='fw-bold fs-6 mb-2 w-100'>Vast URL</label>
                    <span style={{wordBreak: 'break-all'}}>{data.vastUrl}</span>
                  </div>
                )}
                {data.isVast && (
                  <div className='fv-row mb-7 mt-4'>
                    <label className='fw-bold fs-6 mb-2 w-100'>Tıkama URL</label>
                    <span style={{wordBreak: 'break-all'}}>{data.targetUrl}</span>
                  </div>
                )}
                {!data.isVast && (
                  <div className='fv-row mb-7 mt-4'>
                    <label className='fw-bold fs-6 mb-2 w-100'>Tıkama URL</label>
                    <input
                      placeholder='İzleme Kodu'
                      value={form.creativeTargetUrl}
                      onChange={(e) => setForm({...form, creativeTargetUrl: e.target.value})}
                      type='text'
                      name='name'
                      className={'form-control form-control-solid mb-3 mb-lg-0'}
                      autoComplete='off'
                    />
                  </div>
                )}
                <div className='fv-row mb-7'>
                  <label className=' fw-bold fs-6 mb-2'>İzleme Kodu</label>
                  <input
                    placeholder='İzleme Kodu'
                    value={form.creativeTrackingUrl}
                    onChange={(e) => setForm({...form, creativeTrackingUrl: e.target.value})}
                    type='text'
                    name='name'
                    className={'form-control form-control-solid mb-3 mb-lg-0'}
                    autoComplete='off'
                  />
                </div>

                {!data.isVast && (
                  <div className='fv-row mb-7 mt-4'>
                    <label className='fw-bold fs-6 mb-2 w-100'>Tamamlama URL</label>
                    <input
                      placeholder='İzleme Kodu'
                      value={form.creativeCompletionUrl}
                      onChange={(e) => setForm({...form, creativeCompletionUrl: e.target.value})}
                      type='text'
                      name='name'
                      className={'form-control form-control-solid mb-3 mb-lg-0'}
                      autoComplete='off'
                    />
                  </div>
                )}
                <button
                  className='btn btn-success w-100'
                  disabled={loading}
                  onClick={() => handleSubmit()}
                >
                  Güncelle
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default React.memo(EditModal)
