import React, {useEffect, useMemo, useState} from 'react'
import ReactPaginate from 'react-paginate'
import {useTable, usePagination} from 'react-table'
import {KTCardBody} from '../../../../../_metronic/helpers'
import {CustomHeaderColumn} from '../../../../modules/apps/user-management/users-list/table/columns/CustomHeaderColumn'
import {CustomRow} from '../../../../modules/apps/user-management/users-list/table/columns/CustomRow'

function CampaignsTable({data, columns}) {
  const {
    getTableProps,
    getTableBodyProps,
    headers,
    prepareRow,
    page,
    gotoPage,
    state: {pageIndex, pageSize},
  } = useTable(
    {
      columns,
      data,
    },
    usePagination
  )

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {page.length > 0 ? (
              page.map((row, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className='row'>
        <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
          <ReactPaginate
            previousLabel='Önceki'
            nextLabel='Sonraki'
            breakLabel='...'
            breakClassName='page-item'
            breakLinkClassName='page-link'
            pageCount={Math.ceil(data.length / pageSize)}
            pageRangeDisplayed={4}
            marginPagesDisplayed={2}
            // onPageChange={this.handlePageClick}
            containerClassName='pagination justify-content-center mt-4'
            pageClassName='page-item'
            pageLinkClassName='page-link'
            previousClassName='page-item'
            previousLinkClassName='page-link me-5'
            nextClassName='page-item'
            nextLinkClassName='page-link ms-5'
            activeClassName='active'
            // eslint-disable-next-line no-unused-vars
            // hrefBuilder={(page, pageCount, selected) =>
            //   page >= 1 && page <= pageCount ? `/page/${page}` : '#'
            // }
            // hrefAllControls
            forcePage={pageIndex}
            onClick={(clickEvent) => {
              gotoPage(clickEvent.nextSelectedPage)
              // Return false to prevent standard page change,
              // return false; // --> Will do nothing.
              // return a number to choose the next page,
              // return 4; --> Will go to page 5 (index 4)
              // return nothing (undefined) to let standard behavior take place.
            }}
          />
        </div>
      </div>
      {/* <UsersListPagination /> */}
      {/* {isLoading && <UsersListLoading />} */}
    </KTCardBody>
  )
}

export default CampaignsTable
