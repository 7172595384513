import React from 'react'
import './antd.css' // or 'antd/dist/antd.less'
import {TimePicker} from 'antd'
import moment from 'moment'

const format = 'HH:mm'

function Time({onChange, time}) {
  return (
    <TimePicker
      showNow={false}
      onChange={(e) => {
        onChange(moment(e).format('HH:mm'))
      }}
      defaultValue={moment(time, format)}
      format={format}
    />
  )
}

export default Time
