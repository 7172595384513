import React, {useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import {KTSVG} from '../../../../_metronic/helpers'
import {createPublisherReport, createMaestroReport} from '../../../services/publishers.service'

function ConfirmModal({showModal, dataParams, id, loadReports, isMaestro}) {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const [reportName, setReportName] = useState('')
  const [isPublicCheckbox, setIsPublicCheckbox] = useState(false)

  const download = async () => {
    let tempDataParams = dataParams
    tempDataParams += '&reportName=' + reportName

    let res
    if (isMaestro) {
      res = await createMaestroReport(id, tempDataParams)
    } else {
      res = await createPublisherReport(id, tempDataParams)
    }
    if (!res.success) {
      Swal.fire(
        'Bir sorunla karşılaştık.',
        res.data.data.errors ? res.data.data.errors[0].message : 'Lütfen tekrar deneyiniz.',
        'error'
      )
    } else {
      loadReports()
      Swal.fire({title: 'Başarılı', text: 'Rapor oluşturuluyor.', icon: 'success'}).then(
        (result) => {
          if (result.isConfirmed || result.isDismissed) {
            showModal(false)
          }
        }
      )
    }
  }

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>Rapor Oluştur</h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => showModal(false)}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <div className='d-flex flex-column'>
                <div>
                  <label className='required fw-bold fs-6 mb-2'>Rapor Adı</label>
                  <input
                    placeholder='Rapor Adı'
                    type='text'
                    name='name'
                    className={'form-control form-control-solid mb-3 mb-lg-0'}
                    autoComplete='off'
                    value={reportName}
                    onChange={(e) => setReportName(e.target.value)}
                  />
                </div>

                <button className='btn btn-primary mt-4' onClick={(e) => download()}>
                  Oluştur
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default ConfirmModal
