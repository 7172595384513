import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Swal from 'sweetalert2'
import {instance} from '../../../../services/http'
import {useParams} from 'react-router-dom'
import Upload from './Upload'

function AddModal({showAddModal, advertisers, fetchData}) {
  let {publisher_id} = useParams()
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const [formValues, setFormValues] = useState({
    title: '',
    file: null,
    logo: false,
  })

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>Tanıtım Oluştur</h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => showAddModal(false)}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <div className='row'>
                <div className='col-12'>
                  <label className='fw-bold fs-6 mb-2'>Tanıtım Adı</label>
                  <input
                    placeholder='Name'
                    type='text'
                    name='name'
                    className={'form-control form-control-solid mb-3 mb-lg-0'}
                    autoComplete='off'
                    value={formValues.title}
                    onChange={(e) => {
                      setFormValues({...formValues, title: e.target.value})
                    }}
                  />
                </div>
                <div className='col-12 col-md-6 mt-4'>
                  <label className='fw-bold fs-6 mb-5'>Logo</label>
                  <div className='d-flex flex-row'>
                    <div className=''>
                      <input
                        className='form-check-input'
                        type='radio'
                        checked={formValues.logo}
                        value={true}
                        name='logo'
                        id='flexRadioSm'
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            logo: e.target.value === 'true' ? true : false,
                          })
                        }}
                      />
                      <label className='form-check-label ms-2' for='flexRadioSm'>
                        Var
                      </label>
                    </div>

                    <div className='ms-4'>
                      <input
                        className='form-check-input'
                        type='radio'
                        checked={!formValues.logo}
                        value={false}
                        name='logo'
                        id='flexRadioSm'
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            logo: e.target.value === 'true' ? true : false,
                          })
                        }}
                      />
                      <label className='form-check-label ms-2' for='flexRadioSm'>
                        Yok
                      </label>
                    </div>
                  </div>
                </div>
                <div className='col-12 mt-4 d-flex flex-column'>
                  <label className='fw-bold fs-6 mb-2'>Tanıtım Yükle</label>
                  <label className='fs-8 mb-2'>(MP4 ve 100MB'den küçük olmalı.)</label>
                  <Button variant='contained' component='label' className='mt-2'>
                    {formValues.file?.name ?? 'Video Yükle'}
                    {/* Bind the handler to the input */}
                    <input
                      onChange={(e) => {
                        let file = e.target.files ? e.target.files[0] : null
                        if (file && file.size / 1024 / 1024 > 100) {
                          Swal.fire('Hata!', "Video boyutu 100MB'dan fazla olamaz.", 'error')
                          setFormValues({...formValues, file: null})
                          return
                        }

                        setFormValues({...formValues, file: file})
                      }}
                      type='file'
                      accept='.mp4'
                      hidden
                    />
                  </Button>
                  {formValues.file && (
                    <video src={URL.createObjectURL(formValues.file)} className='w-100' controls />
                  )}
                </div>
                <Upload fetchData={fetchData} publisher_id={publisher_id} formValues={formValues} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default AddModal
