import {Divider} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import {useParams} from 'react-router'
import Swal from 'sweetalert2'
import {KTCard, KTSVG} from '../../../../_metronic/helpers'
import {getOfferDetail} from '../../../services/agencies.service'
import offerStatus from '../../../utils/offerStatus'
import Table from './components/Table'
import {decodeEntities} from '../../../utils/format'
import ScreenShotModal from './components/ScreenshotModal'

function OfferDetail() {
  let {agency_id, offer_id} = useParams()

  const [data, setData] = useState()
  const [showScreenshotModal, setShowScreenshotModal] = useState(false)

  useEffect(() => {
    getOfferDetail(agency_id, offer_id).then((res) => {
      if (!res.success) {
        Swal.fire('Hata', res.data.data.errors[0].message, 'error')
      } else {
        console.log(res.data)
        setData(res.data)
      }
    })
  }, [])

  const customColumns = [
    {
      Header: (props) => (
        <th {...props.column.getHeaderProps()} className='min-w-125px' style={{cursor: 'pointer'}}>
          Kanal
        </th>
      ),
      accessor: 'name',
      Cell: ({...props}) => {
        let value = props.row.original
        let status = offerStatus(value.status.id)
        return (
          <div className='d-flex flex-column'>
            {decodeEntities(value.channel.name)}
            <div className={`btn btn-${status.color} btn-sm`}>{status.messageKey}</div>
          </div>
        )
      },
    },
    {
      Header: (props) => (
        <th {...props.column.getHeaderProps()} className='min-w-125px' style={{cursor: 'pointer'}}>
          CPV
        </th>
      ),
      accessor: 'cpv',
      Cell: ({...props}) => {
        let value = props.row.original
        return (
          <div className='d-flex flex-column'>
            {value.type === 1 && (
              <>
                <span>
                  PSS:{' '}
                  {value.pssPrice > -1 ? (
                    nf.format(value.pssPrice)
                  ) : (
                    <KTSVG path='/media/icons/duotune/general/gen040.svg' className='svg-icon-3' />
                  )}
                </span>
                <span>
                  NS:{' '}
                  {value.nsPrice > -1 ? (
                    nf.format(value.nsPrice)
                  ) : (
                    <KTSVG path='/media/icons/duotune/general/gen040.svg' className='svg-icon-3' />
                  )}
                </span>
              </>
            )}
          </div>
        )
      },
    },
    {
      Header: (props) => (
        <th {...props.column.getHeaderProps()} className='min-w-125px' style={{cursor: 'pointer'}}>
          Bütçe
        </th>
      ),
      accessor: 'budget',
      Cell: ({...props}) => {
        let value = props.row.original
        return <span>{value.budget > -1 ? nf.format(value.budget) : 'AUTO'}</span>
      },
    },
    {
      Header: (props) => (
        <th {...props.column.getHeaderProps()} className='min-w-125px' style={{cursor: 'pointer'}}>
          Gerçekleşen
        </th>
      ),
      accessor: 'impression',
      Cell: ({...props}) => {
        let value = props.row.original
        return <span>{nf.format(value.spentBudget)}</span>
      },
    },
    {
      Header: (props) => (
        <th {...props.column.getHeaderProps()} className='min-w-125px' style={{cursor: 'pointer'}}>
          İzlenme
        </th>
      ),
      accessor: 'view',
      Cell: ({...props}) => {
        let value = props.row.original
        return <span>{value.impression}</span>
      },
    },
    {
      Header: (props) => (
        <th {...props.column.getHeaderProps()} className='min-w-125px' style={{cursor: 'pointer'}}>
          Tıklanma
        </th>
      ),
      accessor: 'click',
      Cell: ({...props}) => {
        let value = props.row.original
        return <span>{value.clickCount}</span>
      },
    },
    {
      Header: (props) => (
        <th {...props.column.getHeaderProps()} className='min-w-125px' style={{cursor: 'pointer'}}>
          Tıklanma Oranı
        </th>
      ),
      accessor: 'clickRate',
      Cell: ({...props}) => {
        let value = props.row.original
        return <span>{value.clickRate}%</span>
      },
    },
    {
      Header: (props) => (
        <th {...props.column.getHeaderProps()} className='min-w-125px' style={{cursor: 'pointer'}}>
          Ortalama CPV
        </th>
      ),
      accessor: 'average',
      Cell: ({...props}) => {
        let value = props.row.original
        return <span>{value.averageCPV}</span>
      },
    },
  ]

  let nf = new Intl.NumberFormat('tr-TR', {style: 'currency', currency: 'TRY'})
  return (
    <>
      <KTCard className='p-4'>
        {data && (
          <div className='d-flex flex-column'>
            <div className='row'>
              <div className='col-12 col-sm-8 d-flex flex-column'>
                <span className='fs-4 w-100'>
                  {decodeEntities(data.campaign.name)} - {decodeEntities(data.offer.name)}
                </span>
                <span className='fs-4'>
                  {data.offer.startDate} - {data.offer.endDate}
                </span>
                <span className='fs-4'>
                  Toplam Bütçe: {nf.format(data.offer.rawBudget)}{' '}
                  {data.offer.overload > 0 ? ` - Aşım Payı: %${data.offer.overload}` : ''}
                </span>
                <span className='fs-4'>Bütçe Dağılımı: {data.offer.budgetDistributionString}</span>
                {data.offer.type === 1 ? (
                  <>
                    <span className='fs-4'>
                      PSS:{' '}
                      {data.offer.pssPrice > -1 ? (
                        nf.format(data.offer.pssPrice)
                      ) : (
                        <KTSVG
                          path='/media/icons/duotune/general/gen040.svg'
                          className='svg-icon-3'
                        />
                      )}{' '}
                      PSS Bütçesi:{' '}
                      {data.offer.pssPrice > -1 ? (
                        nf.format(data.offer.pssBudget)
                      ) : (
                        <KTSVG
                          path='/media/icons/duotune/general/gen040.svg'
                          className='svg-icon-3'
                        />
                      )}
                    </span>
                    <span className='fs-4'>
                      NS:{' '}
                      {data.offer.nsPrice > -1 ? (
                        nf.format(data.offer.nsPrice)
                      ) : (
                        <KTSVG
                          path='/media/icons/duotune/general/gen040.svg'
                          className='svg-icon-3'
                        />
                      )}{' '}
                      NS Bütçesi:{' '}
                      {data.offer.nsPrice > -1 ? (
                        nf.format(data.offer.nsBudget)
                      ) : (
                        <KTSVG
                          path='/media/icons/duotune/general/gen040.svg'
                          className='svg-icon-3'
                        />
                      )}
                    </span>
                  </>
                ) : (
                  <>
                    <span className='fs-4'>CPM: {nf.format(data.offer.cpm)}</span>
                  </>
                )}
                <span className='fs-4'>
                  Planlanan İzlenme:{' '}
                  {new Intl.NumberFormat('tr-TR').format(data.offer.targetedImpression)}
                </span>
                <span className='fs-4'>İzlenme: {data.offer.viewCount}</span>
                <span className='fs-4'>
                  Görünür İzlenme: {data.offer.viewableImpression}/{data.offer.viewabilityRate}%
                </span>
                <span className='fs-4'>Tekil Erişim: {data.offer.uniqueReach}</span>
                <span className='fs-4'>
                  Ad-blocker Gösterimi: {data.offer.blocked}/{data.offer.adBlockPercent}%
                </span>
              </div>
              <div className='col-12 col-sm-4 mt-4 mt-sm-0'>
                <div className='d-flex flex-sm-column justify-content-between justify-content-sm-start align-items-end'>
                  <div className={`btn btn-${offerStatus(data.offer.status.id).color}`}>
                    {offerStatus(data.offer.status.id).messageKey}
                  </div>
                  <Dropdown className='mt-2'>
                    <Dropdown.Toggle variant='success' id='dropdown-basic' size='sm'>
                      İşlemler
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href={`/agencies/${agency_id}/offers/${offer_id}/edit`}>
                        Teklifi Düzenle
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setShowScreenshotModal(true)
                        }}
                      >
                        Ekran Görüntüsü Al
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <Divider className='mt-4' />
              <div className='row justify-content-md-center mt-4'>
                <div className='col-12 col-md-2'>
                  <div className='d-flex flex-column align-items-center h-100'>
                    <span style={{fontSize: 24, color: '#2ab4c0'}}>{data.offer.viewCount}</span>
                    <span className='text-center'>İZLENME</span>
                  </div>
                </div>
                <div className='col-12 col-md-2'>
                  <div className='d-flex flex-column align-items-center'>
                    <span style={{fontSize: 24, color: '#2ab4c0'}}>
                      {data.offer.firstPercent} %
                    </span>
                    <span className='text-center'>%25 TAMAMLANMA</span>
                  </div>
                </div>
                <div className='col-12 col-md-2'>
                  <div className='d-flex flex-column align-items-center'>
                    <span style={{fontSize: 24, color: '#2ab4c0'}}>
                      {data.offer.secondPercent} %
                    </span>
                    <span className='text-center'>%50 TAMAMLANMA</span>
                  </div>
                </div>
                <div className='col-12 col-md-2'>
                  <div className='d-flex flex-column align-items-center'>
                    <span style={{fontSize: 24, color: '#2ab4c0'}}>
                      {data.offer.thirdPercent} %
                    </span>
                    <span className='text-center'>%75 TAMAMLANMA</span>
                  </div>
                </div>
                <div className='col-12 col-md-2'>
                  <div className='d-flex flex-column align-items-center'>
                    <span style={{fontSize: 24, color: '#2ab4c0'}}>
                      {data.offer.completionRate} %
                    </span>
                    <span className='text-center'>%100 TAMAMLANMA</span>
                  </div>
                </div>
              </div>

              <div className='row'>
                <Table data={data.offer.offerChannels} columns={customColumns} />
              </div>
            </div>
          </div>
        )}
      </KTCard>

      {showScreenshotModal && (
        <ScreenShotModal
          creatives={data.offer.creatives}
          showModal={setShowScreenshotModal}
        />
      )}
    </>
  )
}

export default OfferDetail
