import {instance} from './http'

export const getCreatives = (agency, part, month) => {
  let params = new URLSearchParams()
  params.append('time[part]', part.toString())
  params.append('time[month]', month.toString())
  return instance
    .get(`/agencies/${agency}/creatives/time?${params}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}
export const getCreativesWithoutTime = (agency) => {
  return instance
    .get(`/agencies/${agency}/creatives`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const addCreative = (agency, data) => {
  data = new URLSearchParams(data).toString()
  return instance
    .post(`/agencies/${agency}/creatives`, data, {
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    })
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const deleteCreative = (agency, id) => {
  return instance
    .delete(`/agencies/${agency}/creatives/${id}`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const statusCreative = (agency, id, status) => {
  return instance
    .put(`/agencies/${agency}/creatives/${id}/status`, {
      status,
    })
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const updateCreative = (agency, id, data) => {
  return instance
    .put(`/agencies/${agency}/creatives/${id}`, data)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const getCallToAction = (agency, id) => {
  return instance
    .get(`/agencies/${agency}/creatives/${id}/callToAction`)
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}

export const addCallToAction = (agency, id, data) => {
  data = new URLSearchParams(data).toString()
  return instance
    .post(`/agencies/${agency}/creatives/${id}/callToAction`, data, {
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    })
    .then((res) => {
      return {success: true, data: res.data}
    })
    .catch((err) => {
      return {success: false, data: err.response}
    })
}
